import {useUserStore} from "@/stores/userStore";
import {useProjectStore} from "@/stores/projectStore";
import {deleteResultResultAsync, getResultSetsAsync} from "@/api/resultSetApi";

export class ResultSetList {
    items = []
    _isLoading = false
    _refreshLock = 0
    _closed = false

    constructor() {
        console.log('ResultSetList created')
        this.startRefreshTimer()
    }

    close() {
        this._closed = true
    }

    get count() {
        return this.items.length
    }

    get empty() {
        return this.count === 0
    }

    get isLoading() {
        return this._isLoading
    }

    lockRefresh() {
        const j = this._refreshLock++
        return j === 0
    }

    unlockRefresh() {
        this._refreshLock = 0
    }

    async refreshAsync() {
        if (this._closed) {
            console.log('refreshAsync: call ignored because _closed is set (check 1)')
            return
        }

        if (!this.lockRefresh()) {
            console.log('refreshAsync: lock failed')
            return
        }

        const userStore = useUserStore();

        try {
            if (this._closed) {
                console.log('refreshAsync: call ignored because _closed is set (check 2)')
                return
            }
            console.log('Refreshing result sets')

            const projectStore = useProjectStore();

            const project = projectStore.current
            if (project == null)
                return

            const items = await getResultSetsAsync(userStore.token, project.projectId)
            console.log('new items', items)

            this.items = items
        }
        finally {
            this.unlockRefresh()
            console.log('RefreshDataSets unlocked')
        }
    }

    async deleteAsync(item) {
        if (this._closed)
            return

        const userStore = useUserStore();
        const refId = item.refId
        await deleteResultResultAsync(userStore.token, refId)
        if (typeof item === 'string')
            this.items = this.items.filter(i => i.refId !== item)
        else
            this.items = this.items.filter(i => i !== item)
    }

    startRefreshTimer() {
        // update ever 15s
        setTimeout(async () => {
            if (this._closed) {
                console.log('Refresh Timer prevented')
                return
            }

            try {
                await this.refreshAsync()
            }
            finally {
                this.startRefreshTimer()
            }
        }, 18000)
    }
}