<template>
  <div class="open-save-dialog dialog">
    <div class="dialog-background" @click="emit('cancel')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>

      <h4>{{viewModel.title}}</h4>

      <div class="item-list base-list-box">
        <div class="list-box-scroller">
          <div class="item" :class="{selected: selectedItem === item}" v-for="(item, index) in items" :key="index" @click="selectedItem = item">
            {{ viewModel.getItemText(item) }}
          </div>
        </div>
      </div>

      <div class="save-name" v-if="viewModel.canSave">
        <label for="save-name">Save as: </label>
        <input id="save-name" type="" v-model="saveName"/>
      </div>

      <div class="button-row">
        <div class="button-strip button-strip-left">
          <button v-if="viewModel.canDelete && selectedItem != null" class="btn btn-danger" @click="delete_Click">Delete</button>
        </div>

        <div class="button-strip button-strip-right">
          <button v-if="viewModel.canSave" class="btn btn-primary" @click="save_Click">Save</button>
          <button v-if="viewModel.canOpen" class="btn btn-primary" @click="open_Click">Open</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, onMounted, toRaw, watch} from "vue";
import BaseOpenSaveViewModel from "@/lib/viewModels/BaseOpenSaveViewModel";

const props = defineProps({
  viewModel: BaseOpenSaveViewModel,

  // a data item or function that returns a data item for saving
  saveData: {
    type: Object,
    required: false
  }
})

const items =  ref([])
const selectedItem = ref(null)
const saveName = ref('')

const emit = defineEmits(['cancel', 'saved', 'loaded'])

onMounted(async () => {
  const viewItems = await props.viewModel.getViewItemsAsync()
  items.value = viewItems
})

watch(selectedItem, (newVal) => {
  if (newVal != null)
    saveName.value = newVal.name
})

async function delete_Click() {
  await props.viewModel.deleteAsync(selectedItem.value)
  const viewItems = await props.viewModel.getViewItemsAsync()
  items.value = viewItems
}

async function save_Click() {
  if (saveName.value === '') {
    alert('Please enter a name')
    return
  }

  let saveData = props.saveData

  // this is set to true IF the specified name matches the name of the selected item (provided by getItemText)
  // when true, the selected item is passed into the provided saveData() function and important values (such as id's)
  // can be set to the new object
  // when false, the specified name will be passed to the function
  const nameEqSelected = selectedItem.value != null && props.viewModel.getItemText(selectedItem.value) === saveName.value
  if (typeof saveData === 'function')
    saveData = saveData()

  await props.viewModel.saveAsync(saveName.value, nameEqSelected ? selectedItem.value : null, saveData)

  emit('saved')
}

async function open_Click() {
  const assetSetRef = toRaw(selectedItem.value)
  if (assetSetRef == null)
    return

  const assetSet = await props.viewModel.loadAsync(assetSetRef)

  emit('loaded', assetSet)
}

</script>

<style scoped>
.open-save-dialog .dialog-content {
  height: 80%;
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 600px;
}

.item-list {
  flex: 1;
}

.save-name {
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
}

.save-name label {
  margin-right: 1rem;
}

.save-name input {
  flex: 1;
}

.button-row {
  display: flex;
  flex-direction: row;
}

.button-row .button-strip.button-strip-left {

}

.button-row .button-strip.button-strip-right {
  flex: 1;
  display: flex;
  justify-content: right;
}
</style>