<template>
<div class="flood-zone-map-image">
  <img :src="imageData" width="600"/>
</div>
</template>

<script setup>
import {defineProps, computed} from "vue";
const props = defineProps({
  data: {
    type: String,
    required: true
  }
})
const imageData = computed(() => {
  return 'data:image/png;base64, ' + props.data
})
</script>

<style scoped>

</style>