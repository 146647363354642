import config from "@/sys/config";
import StrUtils from "@/lib/utils/StrUtils";
import {getJsonAsync, updateHeaders, deleteAsync, postJsonAsync} from "@/api/apiUtils";
import BrandingProfile from "@/lib/entities/BrandingProfile";

/**
 * @param {string} token
 * @param {FormData} formData
 * **/
async function addBrandingDataAsync(token, formData) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('addBrandingDataAsync: token is null or empty')
    if (!(formData instanceof FormData)) throw new Error('addBrandingDataAsync: formData object is not instance of FormData')

    const headers = updateHeaders({}, token)

    const url = `${config.SERVICE_URL}branding`

    const resp = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: formData
    })

    if (resp.status !== 200)
        throw new Error('Unable to add Branding information')
}

/**
 * @param {string} token
 * @param {string} brandingProfileId
 * @param {FormData} formData
 * **/
async function updateBrandingDataAsync(token, brandingProfileId, formData) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('updateBrandingDataAsync: token is null or empty')
    if (StrUtils.isNullOrEmpty(brandingProfileId)) throw new Error('updateBrandingDataAsync: brandingProfileId is null or empty')
    if (!(formData instanceof FormData)) throw new Error('updateBrandingDataAsync: formData object is not instance of FormData')

    const headers = updateHeaders({}, token)

    const url = `${config.SERVICE_URL}branding/${brandingProfileId}`

    const resp = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: formData
    })

    if (resp.status !== 200)
        throw new Error('Unable to add Branding information')
}

async function getBrandingProfilesAsync(token) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('getBrandingProfilesAsync: token is null or empty')

    const url = `${config.SERVICE_URL}branding`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error getting list of branding profiles')

    const o = await resp.json()
    return BrandingProfile.fromArray(await o.brandingProfiles)
}

/**
 * @param token
 * @param brandingEntityId
 * @returns {ArrayBuffer|Blob}
 */
async function getBrandingImageAsync(token, brandingEntityId, asBlob = false) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('getBrandingImageAsync: token is null or empty')
    if (StrUtils.isNullOrEmpty(brandingEntityId)) throw new Error('getBrandingImageAsync: brandingEntityId is null or empty')

    const url = `${config.SERVICE_URL}branding/image/${brandingEntityId}`
    const headers = updateHeaders({}, token)

    const opts = {
        headers: headers,
        method: 'GET'
    }

    const resp = await fetch(url, opts)

    if (resp.status !== 200)
        throw new Error('Error getting branding image')

    if (asBlob) {
        const blob = await resp.blob()
        return blob
    }

    const buff = await resp.arrayBuffer()
    return buff
}

/**
 * @param {string} token
 * @param {string} brandingProfileId
 * @returns {Promise<void>}
 */
async function deleteBrandingProfileAsync(token, brandingProfileId) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('deleteBrandingProfileAsync: token is null')
    if (StrUtils.isNullOrEmpty(brandingProfileId)) throw new Error('deleteBrandingProfileAsync: brandingProfileId is null')

    const url = `${config.SERVICE_URL}branding/${brandingProfileId}`

    const resp = await deleteAsync(url, null, token)
    if (resp.status !== 200) throw new Error('Error deleting branding profile')
}

async function getThemeBrandingProfileAsync(token) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('getThemeBrandingProfile: token is null')

    const url = `${config.SERVICE_URL}branding/theme`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        return null

    const json = await resp.json()

    return BrandingProfile.fromObject(json)
}

async function setThemeBrandingProfileAsync(token, brandingProfileId) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('setThemeBrandingProfileAsync: token is null')

    if (brandingProfileId == null) {
        return deleteThemeBrandingProfileAsync(token)
    }

    const url = `${config.SERVICE_URL}branding/theme`

    const data = {
        id: brandingProfileId
    }

    const resp = await postJsonAsync(url, data, token)

    if (resp.status !== 200)
        throw new Error('Unable to set branding profile id')
}

async function deleteThemeBrandingProfileAsync(token) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('deleteThemeBrandingProfileAsync: token is null')

    const url = `${config.SERVICE_URL}branding/theme`

    const resp = await deleteAsync(url, {}, token)
}

export {
    addBrandingDataAsync,
    updateBrandingDataAsync,
    getBrandingProfilesAsync,
    getBrandingImageAsync,
    deleteBrandingProfileAsync,

    getThemeBrandingProfileAsync,
    setThemeBrandingProfileAsync,
    deleteThemeBrandingProfileAsync
}