<template>
    <div class="extend-weather-historical-var-item">
        <expand-toggle :text="varItem.name" @on-change="isOpen = !isOpen" />
        <div class="items" v-if="isOpen">
            <a v-for="(month, index) in months" :key="index" @click="$emit('month-clicked', varItem, month, index)">{{ MONTHS[month] }}</a>
        </div>        
    </div>
</template>

<script>

import { Forecast, ForecastVarItem } from "@/lib/extendWeather/ForecastObjects";
import ExpandToggle from "@/components/ExpandToggle";
import { MONTHS } from '@/lib/extendWeather/EWData'

export default {
    name: "ExtendWeatherHistoricalVarItem",
    components: { ExpandToggle },
    props: {
        varItem: ForecastVarItem,
        forecast: Forecast
    },
    data(){ return {
        MONTHS,
        isOpen: false
    }},    
    computed: {
        months() {
            let arr = []
            let month = 0
            for(let i=0; i < 12; i++)
            {
                arr.push(month)
                month++;
                if (month === 12)
                    month = 0;
            }
            return arr
        }
    }    
}
</script>

<style scoped>
.items {
    margin-left: 2.4rem;
    margin-bottom: 1rem;
}

.items a {
    display: block;
    color: #152128 !important;
}
</style>