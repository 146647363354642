class TimeHorizon {
    type = 'all'

    includes(value) {
        return true
    }

    toString() {
        return 'time_horizon:all'
    }

    contains(value) {
        return this.includes(value)
    }
}

class RangedTimeHorizon extends TimeHorizon {
    type = 'range'
    startYear = 0
    endYear = 0
    
    constructor(startYear, endYear) {
        super()
        this.startYear = startYear
        this.endYear = endYear
    }
    
    includes(value) {
        try {
            const intVal = Number.parseInt(value)
            return intVal >= this.startYear && intVal <= this.endYear
        }
        catch {
            return false
        }
    }

    toString() {
        return `ranged_time_horizon:${this.startYear}:${this.endYear}`
    }
}

class SpecificTimeHorizon extends TimeHorizon {
    type = 'specific'
    values = []
    
    constructor(values) {
        super()
        if (Array.isArray(values))
            this.values = values.map(x => x.toString())
    }
    
    includes(value) {
        if (value == null || this.values.length === 0)
            return false

        for(let i=0; i < this.values.length; i++)
            if (this.values[i] == value)
                return true

        return false
    }
    
    toString() {
        return `specific_horizon:${this.values.join('|')}`
    }
}

export { TimeHorizon, RangedTimeHorizon, SpecificTimeHorizon }