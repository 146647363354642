import {getBrandingImageAsync, getThemeBrandingProfileAsync} from "@/api/brandingApi";

class BrandingResponse {
    profile = null
    imageBlob = null
    objectUrl = null
}

async function loadBrandingAsync(token) {
    const profile = await getThemeBrandingProfileAsync(token)
    if (profile == null) return null

    const blob = await getBrandingImageAsync(token, profile.brandingEntityId, true)
    const objectUrl = URL.createObjectURL(blob)

    const resp = new BrandingResponse()

    resp.profile = profile
    resp.imageBlob = blob
    resp.objectUrl = objectUrl

    return resp
}


export { BrandingResponse, loadBrandingAsync }