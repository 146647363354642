<template>
<div class="branding-editor-dialog dialog">
  <div class="dialog-background" @click="emit('cancel')"/>
  <div class="dialog-content general-panel d-flex flex-column">
    <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>

    <h4>Branding profile</h4>

    <form enctype="multipart/form-data"
          action="/submit"
          @submit.prevent="save_Clicked"
          method="post"
          class="d-flex flex-column flex-fill"
          ref="brandingForm">
      <div class="form-group">
        <label>Branding profile name:</label>
        <input class="form-control" type="text" name="Name" :value="editSource.name"/>
      </div>

      <h5>Profile details:</h5>

      <p>Enter branding details below. All fields are optional.</p>

      <div class="form-content scrollable-host flex-fill">
        <div class="scrollable">
          <div class="form-group">
            <label>Company name:</label>
            <input class="form-control" type="text" name="CompanyName" :value="editSource.companyName">
          </div>

          <div class="form-group">
            <label>Company slogan:</label>
            <input class="form-control" type="text" name="Slogan" :value="editSource.slogan">
          </div>

          <div class="form-group">
            <label>Address 1:</label>
            <input class="form-control" type="text" name="Address1" :value="editSource.address1">
          </div>

          <div class="form-group">
            <label>Address 2:</label>
            <input class="form-control" type="text" name="Address2" :value="editSource.address2">
          </div>

          <div class="form-group">
            <label>State:</label>
            <input class="form-control" type="text" name="State" :value="editSource.state">
          </div>

          <div class="form-group">
            <label>Postcode:</label>
            <input class="form-control" type="text" name="Postcode" :value="editSource.postcode">
          </div>

          <div class="form-group">
            <label>Country:</label>
            <input class="form-control" type="text" name="Country" :value="editSource.country">
          </div>

          <div class="form-group">
            <label>Phone:</label>
            <input class="form-control" type="text" name="Phone" :value="editSource.phone">
          </div>

          <div class="form-group">
            <label>Web site:</label>
            <input class="form-control" type="text" name="CompanyWebsite" :value="editSource.companyWebsite">
          </div>

          <div class="form-group">
            <label>E-mail address::</label>
            <input class="form-control" type="text" name="CompanyEmail" :value="editSource.companyEmail">
          </div>

          <div class="form-group">
            <label>Company logo:</label>
            <p v-if="editSource.image != null">
              Current file: {{ editSource.image.fileName }} ({{editSource.image.width}}w x {{editSource.image.height}}h)
            </p>
            <input class="form-control" type="file" name="CompanyLogo" accept="image/png, image/jpeg"/>
          </div>
        </div>
      </div>

      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click.prevent="save_Clicked">Save</button>
          <button class="btn btn-secondary" @click="emit('completed')">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>
</template>

<script setup>
import {ref, defineEmits, defineProps, onMounted} from "vue";
import {useUserStore} from "@/stores/userStore";

const emit = defineEmits(['save', 'completed', 'cancel'])
const userStore = useUserStore()
const brandingForm = ref()
// const editSource = ref({})

const props = defineProps({
  editSource: {
    type: Object,
    required: false,
    // default: () => { return {} }
    default: () => { return {} }
  }
})

async function save_Clicked() {
  const form = brandingForm.value
  const formData = new FormData(brandingForm.value)

  emit('save', formData, props.editSource,
      () => emit('completed'),
      () => alert('There as an error saving changes'))
}

</script>

<style scoped>
.form-content {
  border: 1px solid #eee;
  flex: 1;
}

.form-content .scrollable {
  padding:  1rem;
}

.dialog-content {
  width: 90%;
  height: 90%;
  max-height: 800px;
  max-width: 900px;
}
</style>