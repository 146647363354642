import ResultSetCSVExporter from "@/lib/dataExport/resultSet/ResultSetCSVExporter";
import ResultSetTSVExporter from "@/lib/dataExport/resultSet/ResultSetTSVExporter";
import ResultSetCSVType2Exporter from "@/lib/dataExport/resultSet/ResultSetCSVType2Exporter";
import ResultSetXlsxBundleExport from "@/lib/dataExport/resultSet/ResultSetXlsxBundleExport";
import ResultSetDocxBundleExport from "@/lib/dataExport/resultSet/ResultSetDocxBundleExport";
import ResultSetXlsxDocxBundleExport from "@/lib/dataExport/resultSet/ResultSetXlsxDocxBundle";
import ResultSetUniqueUserDownloadExporter from "@/lib/dataExport/resultSet/ResultSetUniqueUserDownloadExporter";

class ExporterRef {
    id = ''
    name = ''
    factoryFunc
    supportsBranding = false

    constructor(id, name, factoryFunc, supportsBranding = false) {
        this.id = id
        this.name = name
        this.factoryFunc = factoryFunc
        this.supportsBranding = supportsBranding
    }

    create() {
        return this.factoryFunc()
    }
}

export default new class ResultSetExporters {
    /** @type ExporterRef */ exporters = []

    constructor() {
        this.add(new ExporterRef('csv1', 'Comma separated text file - Type 1 (csv)', () => new ResultSetCSVExporter()))
        this.add(new ExporterRef('csv2', 'Comma separated text file - Type 2 (csv)', () => new ResultSetCSVType2Exporter()))
        this.add(new ExporterRef('tsv1', 'Tab separated text file (tsv)', () => new ResultSetTSVExporter()))
        // this.add(new ExporterRef('xlsxbnd1', 'Excel XLSX bundle (zip)', () => new ResultSetXlsxBundleExport()))
        // this.add(new ExporterRef('docx2', 'Microsoft Word bundle (zip)', () => new ResultSetDocxBundleExport(), true))
        this.add(new ExporterRef('pdfbundle', 'PDF bundle (zip)', () => new ResultSetUniqueUserDownloadExporter(['pdf'])))
        this.add(new ExporterRef('xlsxbundle', 'Microsoft Excel bundle (zip)', () => new ResultSetUniqueUserDownloadExporter(['xlsx']), true))
        this.add(new ExporterRef('xlsxsummarybundle', 'Microsoft Excel Summary bundle (zip)', () => new ResultSetUniqueUserDownloadExporter(['xlsx.summary'])))
        this.add(new ExporterRef('docxbundle', 'Microsoft Word bundle (zip)', () => new ResultSetUniqueUserDownloadExporter(['docx']), true))
        this.add(new ExporterRef('xlsxdocxbundle', 'Microsoft Word + Excel bundle (zip)', () => new ResultSetUniqueUserDownloadExporter(['docx', 'xlsx', 'xlsx.summary']), true))
        this.add(new ExporterRef('complete', 'Complete bundle (Word, Excel and PDF) (zip)', () => new ResultSetUniqueUserDownloadExporter(['docx', 'xlsx', 'xlsx.summary', 'pdf']), true))
    }

    add(exporter) {
        this.exporters.push(exporter)
    }

    find(id) {
        return this.exporters.find(e => e.id === id)
    }
}