import {ResultsInfo} from "@/lib/entities/Results";

export class PeriodDate {
    year
    month

    constructor(year, month) {
        this.year = year;
        this.month = month;
    }

    static from(o) {
        return new PeriodDate(o.year, o.month)
    }

    static within(min, max, value) {
        return min.compareTo(value) <= 0 && max.compareTo(value) >= 0
    }

    compareTo(other) {
        if (this.year > other.year)
            return 1
        else if (this.year < other.year)
            return -1
        else if (this.month > other.month)
            return 1
        else if (this.month < other.month)
            return -1
        return 0
    }

    static min(o1, o2) {
        return o1.compareTo(o2) < 0 ? o1 : o2
    }

    toString() {
        return `${String(this.year).padStart(4, '0')}-${String(this.month).padStart(2, '0')}`
    }

    static max(o1, o2) {
        return o1.compareTo(o2) > 0 ? o1 : o2
    }

    static minMaxOf(periods) {
        const arr = Array.from(periods)
        if (arr.length === 0)
            return {min: new PeriodDate(0, 0), max: new PeriodDate(0, 0)}

        let result = {min: arr[0], max:arr[0]}

        for(let i=1; i < arr.length; i++) {
            result.min = PeriodDate.min(result.min, arr[i])
            result.max = PeriodDate.max(result.max, arr[i])
        }

        return result
    }

    toJSDate(endOfMonth = false) {
        let date = new Date(this.year, this.month-1, 1)
        if (endOfMonth) {
            date.setMonth(date.getMonth() + 1)
            date = new Date(date - 1)
        }
        return date
    }
}

export class PeriodDateRange {
    min
    max

    constructor(min, max) {
        this.min = PeriodDate.from(min)
        this.max = PeriodDate.from(max)
    }
}

function parseDateWithoutTime(s) {
    let date = new Date(s)
    date.setHours(0, 0, 0, 0)
    return date
}

export class DataEntry {
    date = Date.now()
    projectId = ''
    projectName = ''
    assets = 0
    uniqueAssets = 0
    curves = 0
    percentiles = 0
    variables = 0
    userId = ''

    constructor(o) {
        this.date = parseDateWithoutTime(o.date)
        this.projectId = o.projectId
        this.projectName = o.projectName
        this.assets = o.assets
        this.uniqueAssets = o.uniqueAssets
        this.curves = o.curves
        this.percentiles = o.percentiles
        this.variables = o.variables
        this.userId = o.userId
    }

    static fromArray(arr) {
        if (!Array.isArray(arr)) {
            return []
        }

        return arr.map(o => new DataEntry(o))
    }
}

export class FloodEntry {
    date = Date.now()
    projectId = ''
    projectName = ''
    assets = 0
    uniqueAssets = 0
    userId = ''

    constructor(o)  {
        this.date = parseDateWithoutTime(o.date)
        this.projectId = o.projectId
        this.assets = o.assets
        this.uniqueAssets = o.uniqueAssets
        this.userId = o.userId
        this.projectName = o.projectName
    }

    static fromArray(arr) {
        if (!Array.isArray(arr)) {
            return []
        }

        return arr.map(o => new FloodEntry(o))
    }
}

function sortByDate(a, b) {
    if (a.date > b.date)
        return -1
    if (a.date < b.date)
        return 1
    return 0
}

export class UsageData {
    period
    dataEntries = []
    floodEntries = []
    isEmpty = false

    constructor(o) {
        this.period = PeriodDate.from(o.period)
        this.dataEntries = DataEntry.fromArray(o.dataEntries).sort(sortByDate)
        this.floodEntries = FloodEntry.fromArray(o.floodEntries).sort(sortByDate)
    }

    static fromArray(arr) {
        if (!Array.isArray(arr))
            return []

        return arr.map(o => new UsageData(o))
    }
}