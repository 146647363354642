import {TimeHorizon} from "@/lib/utils/TimeHorizon";

export default class BaseResultSetExporter {
    /** @type BrandingProfile */ brandingProfile = null
    /** @type TimeHorizon */ timeHorizon = new TimeHorizon()

    /**
     * @param {ResultSetReader} reader
     */
    async exportAsync(reader) {
        return null
    }
}