/**
 * @typedef { {name: string, icon: string, group: string} } VarCategoryGroup
 * @typedef { Array<string> } VarCategoryMapping
 * @typedef { group: VarCategoryGroup, items: string[] } VarCategoryIdGroup
 */

/** @type VarCategoryMapping */
export default class VarCategories {
    /** @type Object<string,VarCategoryGroup> */
    categories

    /** @type Object<string,VarCategoryMapping> */
    mappings

    static fromObject(o) {
        let result = new VarCategories()
        result.categories = o.categories
        result.mappings = o.mappings
        return result
    }

    getCategoryKey(varId) {
        varId = varId.split('.')[0]
        const keys = Object.keys(this.mappings)
        for(let i=0; i < keys.length; i++) {
            const k = keys[i]
            const arr = this.mappings[k]
            if (arr == null) continue
            if (arr.includes(varId)) return k
        }
        return null
    }
}

const kNoneStr = '.none'

export function groupVarIds(categories, varIds) {
    let result = {}
    varIds.forEach((varId) => {
        let key = categories.getCategoryKey(varId)
        if (key == null) key = kNoneStr
        if (result[key] === undefined) result[key] = []
        result[key].push(varId)
    })
    return result
}

/** @return {VarCategoryIdGroup[]} */
export function groupVarIdsWithCategories(categories, varIds) {
    const grouped = groupVarIds(categories, varIds)
    const keys = Object.keys(grouped)
    let result = []
    keys.forEach(key => {
        let cat = categories.categories[key]
        if (cat === undefined) cat = null
        result.push({ group: cat, items: grouped[key] })
    })

    function sortKey(g) {
        if (g.group == null) return 999
        return g.group.index
    }

    return result.sort((a,b) => {
        const k1 = sortKey(a)
        const k2 = sortKey(b)
        if (k1<k2) return -1
        else if (k1>k2) return 1
        else return 0
    })
}