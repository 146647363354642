import UserSettings from "@/lib/entities/UserSettings";
import UserGroup from "@/lib/entities/UserGroup";
import {Subscription, UsageStats} from "@/lib/entities/Subscription";

class LoginResponseData {
    userId
    token
    username
    name
    settings
    groups
    subscription

    static fromResponse(resp) {
        let result = new LoginResponseData()
        result.userId = resp.userId
        result.token = resp.token
        result.username = resp.username
        result.name = resp.name
        result.settings = UserSettings.fromResponse(resp.settings)
        result.groups = UserGroup.fromArray(resp.groups)
        result.subscription = resp.subscription != null ? new Subscription(resp.subscription) : null;
        result.subscriptionUsageStats = resp.subscriptionUsageStats != null ? new UsageStats(resp.subscriptionUsageStats) : null;
        return result
    }
}

export default LoginResponseData