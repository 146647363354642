import { defineStore } from "pinia/dist/pinia";
import { useProjectStore} from "@/stores/projectStore";
import {getSubscriptionInfoAsync, logOutAsync} from "@/api/userApi";
import config from '@/sys/config'

export const useUserStore = defineStore({
    id: 'user',
    state: () => {
        return {
            /** @type String */ userId: '',
            /** @type String */ token: 'notoken',
            /** @type String */ name: '',
            /** @type String */ email: '',
            /** @type String */ username: '',
            /** @type UserGroup */ groups: [],
            /** @type UserSettings */ settings: null,
            /** @type BrandingResponse */ brandingTheme: null,
            /** @type Subscription|null */ subscription: null,
            /** @type UsageData|null */ subscriptionUsageStats: null
        }
    },
    getters: {
        isLoggedIn() {
            return this.token !== 'notoken'
        },
        hasBrandingThemeImage() {
            return (this.brandingTheme != null && this.brandingTheme.objectUrl !== null)
        }
    },
    actions: {
        isMemberOf(groupId) {
            /** @type {UserGroup[]} */
            const groups = this.groups
            return groups.some(group => group.id === groupId)
        },

        async logOut() {
            const token = this.token
            const projectStore = useProjectStore()
            projectStore.$reset()
            this.$reset()

            await logOutAsync(token)
        },

        async updateSubscriptionInfo() {
            let info
            try {
                info = await getSubscriptionInfoAsync(this.token)
            }
            catch(error) {
                console.log('updateSubscriptionInfo: Failed in call to getSubscriptionInfoAsync', error)
                return
            }
            this.subscription = info.info
            this.subscriptionUsageStats = info.stats
        },

        /** @param {LoginResponseData} loginDetails */
        updateUser(loginDetails) {
            if (config.IS_DEBUG) {
                console.log('LoginResponseData', loginDetails)
            }
            this.userId = loginDetails.userId
            this.token = loginDetails.token
            this.name = loginDetails.name
            this.groups = loginDetails.groups
            this.settings = loginDetails.settings
            this.username = loginDetails.username
            this.subscription = loginDetails.subscription
            this.subscriptionUsageStats = loginDetails.subscriptionUsageStats
        },

        updateBranding(branding) {
            this.brandingTheme = branding
        }
    }
})