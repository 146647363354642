<script setup>
import { defineProps } from 'vue'

const props = defineProps({
  summary: {
    type: Object,
    required: true
  },
  members: {
    type: Array,
    required: true
  }
})

function getMembersString(userIds) {
  const members = getMembers(userIds)
  if (members.length === 0)
    return ''
  return members.map(member => member.name).join(', ')
}

function getMembers(userIds) {
  return userIds.map(userId => props.members.find(member => member.userId === userId))
                .filter(member => member != null)
}

</script>

<template>

<table class="table table-light table-sm">
  <tbody>
    <tr>
      <td class="name-col">Total assets:</td>
      <td class="value-col">{{ summary.assets }}</td>
    </tr>
    <tr>
      <td class="name-col">Total unique assets:</td>
      <td class="value-col">{{ summary.uniqueAssets }}</td>
    </tr>
    <tr>
      <td class="name-col">Active members:</td>
      <td class="value-col">{{ getMembersString(summary.userIds) }}</td>
    </tr>
  </tbody>
</table>
</template>

<style lang="scss" scoped>
table {
  tr {
    td.name-col {
      width: 30%;
      min-width: 200px;
      max-width: 400px
    }

    td.value-col {
      width: 70%;
    }
  }
}
</style>