import config from "@/sys/config";
import {getJsonAsync, postJsonAsync, deleteAsync} from "@/api/apiUtils";
import {AssetSet, AssetSetReference} from "@/lib/entities/AssetSet";

async function getAssetSetsAsync(token, projectId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')

    const url = config.SERVICE_URL + `assetsets/${projectId}`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error loading AssetSet')

    const arr = await resp.json()
    return arr.map(o => AssetSetReference.fromObject(o))
}

async function deleteAssetSetsAsync(token, projectId, assetSetId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')

    const url = config.SERVICE_URL + `assetsets/${projectId}/${assetSetId}`

    const resp = await deleteAsync(url, {}, token)

    if (resp.status !== 200)
        throw new Error('Error deleting AssetSet')
}

async function getAssetSetAsync(token, projectId, assetSetId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')
    if (assetSetId === null) throw new Error('assetSetId is null')

    const url = config.SERVICE_URL + `assetsets/${projectId}/${assetSetId}`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error loading AssetSet')

    const obj = await resp.json()

    return AssetSet.fromObject(obj)
}

async function saveAssetSetAsync(token, projectId, assetSet) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')
    if (assetSet === null) throw new Error('assetSet is null')

    const url = config.SERVICE_URL + `assetsets/${projectId}`

    const resp = await postJsonAsync(url, assetSet, token)

    if (resp.status !== 200)
        throw new Error('Error saving AssetSet')
}

export {
    getAssetSetsAsync,
    getAssetSetAsync,
    saveAssetSetAsync,
    deleteAssetSetsAsync
}