import Strings from "@/lib/lang/Strings";

const ErrorTypes = {
    'GENERAL_ERROR': (data) => Strings.RESPERR_UNKNOWN_ERROR,
    'USER_NOT_FOUND': (data) => Strings.RESPERR_USER_NOT_FOUND,
    'NO_ASSETS_SPECIFIED': (data) => Strings.RESPERR_NO_ASSETS_SPECIFIED,
    'RESULT_SET_NAME_MISSING': (data) => Strings.RESPERR_RESULT_SET_NAME_MISSING,
    'INVALID_TOKEN': (data) => Strings.RESPERR_INVALID_TOKEN,
    'USER_ALREADY_EXISTS': (data) => Strings.RESPERR_USER_ALREADY_EXISTS,
    'USER_CREATE_ERROR': (data) => Strings.RESPERR_USER_CREATE_ERROR,
    'AVAILABLE_DATA_NOT_FOUND': (data) => Strings.RESPERR_AVAILABLE_DATA_NOT_FOUND,
    'NOT_PERMITTED': (data) => Strings.RESPERR_NOT_PERMITTED,
    'FORBIDDEN': (data) => Strings.RESPERR_FORBIDDEN,
    'SAVED_INSIGHT_NOT_FOUND': (data) => Strings.RESPERR_SAVED_INSIGHT_NOT_FOUND,
    'UNKNOWN_ERROR': (data) => Strings.RESPERR_UNKNOWN_ERROR,
    'INCORRECT_PASSWORD': (data) => Strings.RESPERR_INCORRECT_PASSWORD,
    'INVALID_PASSWORD_FORMAT': (data) => Strings.RESPERR_INVALID_PASSWORD_FORMAT
}

class ErrorResponse {
    constructor(resp, status) {
        this.type = resp.type || 'general.unknown'
        this.data = resp.data || 'nodata'
        this.status = status || -1
    }
    
    toString() {
        return ErrorResponse.formatError(this)
    }
    
    static isError(resp) {
        if (resp == null)
            return false
        
        return resp.respType === 'insights:error' &&
               typeof resp.type === 'string' &&
               resp.data !== undefined
    }
    
    static formatError(errorResponse) {
        if (errorResponse == null)
            return ErrorTypes['GENERAL_ERROR']()
        
        let f = ErrorTypes[errorResponse.type]
        if (f == null)
            return ErrorTypes['GENERAL_ERROR']()
        
        return `${f(errorResponse.data)}`
    }
}

export default ErrorResponse