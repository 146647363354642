<template>
  <h5>Select theme</h5>

  <p>Select the Branding Profile for the current theme.</p>

  <div class="branding-profiles-list base-list-box flex-fill">
    <div class="list-box-scroller">
      <template v-if="ready">
        <div v-for="(item, index) in items" :key="index" class="item" :class="{'selected': selected === item}" @click="selected = item">
          <template v-if="item === null">
            (None)
          </template>
          <template v-else>
            {{item.name }}
          </template>
          <template v-if="item == currentTheme">
            <div class="badge rounded-pill bg-secondary">Current</div>
          </template>
        </div>
      </template>
    </div>
  </div>

  <div class="button-row">
    <div class="button-strip">
      <button class="btn btn-primary" @click="select_Click">Select</button>
    </div>
  </div>
</template>

<script setup>

import {ref, onMounted} from "vue";
import {
  deleteThemeBrandingProfileAsync,
  getBrandingProfilesAsync,
  getThemeBrandingProfileAsync, setThemeBrandingProfileAsync
} from "@/api/brandingApi";
import {useUserStore} from "@/stores/userStore";

const userStore = useUserStore()

const ready = ref(false)
const items = ref([])
const selected = ref(null)
const currentTheme = ref(null)

onMounted(async () => {
  const profiles = await getBrandingProfilesAsync(userStore.token)
  items.value = [null, ...profiles]

  const currentProfile = await getThemeBrandingProfileAsync(userStore.token)
  if (currentProfile != null) {
    const profile = items.value.find(i => i != null && i.brandingEntityId === currentProfile.brandingEntityId)
    selected.value = profile
    currentTheme.value = profile
  }

  ready.value = true
})

async function select_Click() {
  /** @type {BrandingProfile} */
  const profile = selected.value
  if (profile == null) {
    await deleteThemeBrandingProfileAsync(userStore.token)
    currentTheme.value = null
  }
  else {
    await setThemeBrandingProfileAsync(userStore.token, profile.brandingEntityId)
    currentTheme.value = profile
  }
}

</script>

<style scoped>

</style>