<template>
  <h5>Change password</h5>

  <div v-if="message !== ''" class="alert" :class="{ 'alert-success': state === 'success', 'alert-danger': state === 'error', 'alert-info': 'loading' }">
    {{ message }}
  </div>

  <form>
    <div class="form-group">
      <label for="current-password">Enter your current password:</label>
      <input id="current-password" class="form-control" type="password" v-model="currentPassword" />
    </div>

    <div class="form-group">
      <label for="new-password">Enter your new password:</label>
      <input id="new-password" class="form-control" type="password" v-model="newPassword"/>
    </div>

    <div class="form-group">
      <label for="confirm-password">Confirm your new password:</label>
      <input id="confirm-password" class="form-control" type="password" v-model="confirmPassword"/>
    </div>

    <div class="button-row">
      <button class="btn btn-primary" @click.prevent="updatePassword">Change password</button>
    </div>
  </form>
</template>

<script setup>
import {ref} from "vue";
import {changePasswordAsync} from "@/api/userApi";
import {useUserStore} from "@/stores/userStore";

const state = ref('')
const message = ref('')
const currentPassword = ref('')
const newPassword = ref('')
const confirmPassword = ref('')

const userStore = useUserStore()

async function updatePassword() {
  if (newPassword.value.length < 8) {
    state.value = 'error'
    message.value = 'Passwords must be at least 8 characters in length'
    return
  }

  if (newPassword.value !== confirmPassword.value) {
    state.value = 'error'
    message.value = 'New Password and New Password Confirmation values don\t match'
    return
  }

  state.value = 'loading'
  message.value = 'Updating'

  try
  {
    await changePasswordAsync(userStore.token, currentPassword.value, newPassword.value)
    state.value = 'success'
    message.value = 'Password changed'

    currentPassword.value = ''
    newPassword.value = ''
    confirmPassword.value = ''
  }
  catch(error) {
    state.value = 'error'
    message.value = error.message
  }
}

</script>

<style scoped>

</style>