export default class LayerGroup {
    id = ''
    name = ''
    layerIds = []
    curves = []
    percentiles = []

    static fromObject(o) {
        let r = new LayerGroup()
        r.id = o.id
        r.name = o.name
        r.layerIds = Array.from(o.layerIds)
        r.curves = Array.from(o.curves)
        r.percentiles = Array.from(o.percentiles)
        return r
    }

    static fromArray(arr) {
        if (!Array.isArray(arr))
            return [];

        return arr.map(i => LayerGroup.fromObject(i))
    }
}