<template>
  <div id="app">
    <AppToolbar/>
    <RouterView/>
  </div>
</template>

<script setup>
import AppToolbar from "@/components/AppToolbar"
import {RouterView} from 'vue-router'
import config from "@/sys/config";

let title = `${document.title} (${config.VERSION})`
if (config.IS_DEBUG)
  title = `(dbg) ${title}`
document.title = title
</script>

<style lang="scss">
@import '@/assets/std-theme.scss';

html {
  font-size: 10pt;
}

a {
  color: $link-text-color !important;
}

@media screen {
  #app {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #f8f8f8;
  }
}
</style>

<style src="@/assets/css/leaflet-styles.css"></style>