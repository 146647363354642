<template>
  <h5>View settings</h5>

  <form action="/" method="dialog">
    <div class="settings-grid">
      <label>Decimal places (value):</label>
      <input type="number" v-model="dp" min="2"/>
      <label>Decimal places (lat/lng):</label>
      <input type="number" v-model="latLngDp" min="2"/>
      <div class="button-row">
        <button class="btn btn-primary" @click.prevent="apply_Click">Apply</button>
      </div>
    </div>
  </form>

</template>

<script setup>

import {ref} from "vue";
import {useViewSettingsStore} from "@/stores/settingsStore";

const settingsStore = useViewSettingsStore()

const dp = ref(settingsStore.dp)
const latLngDp = ref(settingsStore.latLngDp)

function apply_Click() {
  settingsStore.dp = dp.value
  settingsStore.latLngDp = latLngDp.value
}

</script>

<style scoped>
.settings-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-row-gap: 1rem;
}
</style>