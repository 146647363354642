<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { requestResetPasswordAsync, resetPasswordAsync } from "@/api/userApi";

const stage = ref('prep')

const email = ref('')

const passwordResetToken = ref(null)
const verifyEmail = ref('')
const newPassword = ref('')
const verifyPassword = ref('')

function hasValue(s) {
  return s != null && s !== ''
}

onMounted(() => {
  const route = useRoute();
  passwordResetToken.value = route.query.rpt
  stage.value = hasValue(passwordResetToken.value) ? 'reset' : 'request'
})

async function startPasswordRequestAsync() {
  if (!hasValue(email.value)) {
    alert('Enter your e-mail address and try again.')
    return
  }

  try {
    await requestResetPasswordAsync(email.value)
    stage.value = 'request_sent'
  }
  catch(error) {
    alert('Unable to make password reset request. Please contact support.')
  }
}

async function applyPasswordResetAsync() {
  if (!hasValue(verifyEmail.value)) {
    alert('Please enter your e-mail address')
    return
  }

  if (!hasValue(newPassword.value)) {
    alert('Please enter a new password')
    return
  }

  if (newPassword.value !== verifyPassword.value) {
    alert('New and verify password fields do not match')
    return
  }

  try {
    await resetPasswordAsync(verifyEmail.value, newPassword.value, passwordResetToken.value)
    stage.value = 'password_was_reset'
  }
  catch(error) {
    alert('There was an error resetting your password.')
  }
}

</script>

<template>
  <div class="reset-password-page">
    <h1>Reset password</h1>

    <template v-if="stage === 'request'">
      <p>
        Enter your e-mail below and click Reset Password.
      </p>

      <form>
        <div class="form-group">
          <label>
            E-mail address:
            <input class="form-control" type="text" v-model="email"/>
          </label>
        </div>

        <div class="form-group">
          <button class="btn btn-primary" @click.prevent="startPasswordRequestAsync">Reset Password</button>
        </div>
      </form>
    </template>
    <template v-else-if="stage === 'reset'">
      <form>
        <div class="form-group">
          <label>
            Confirm your e-mail address:
            <input class="form-control" type="text" v-model="verifyEmail"/>
          </label>
        </div>

        <div class="form-group">
          <label>
            New password:
            <input class="form-control" type="password" v-model="newPassword"/>
          </label>
        </div>

        <div class="form-group">
          <label>
            Verify new password:
            <input class="form-control" type="password" v-model="verifyPassword"/>
          </label>
        </div>

        <div class="form-group">
          <button class="btn btn-primary" @click.prevent="applyPasswordResetAsync">Change password</button>
        </div>
      </form>
    </template>
    <template v-else-if="stage === 'request_sent'">
      <p>Your password reset request has been sent. Please check your e-mail for further instructions.</p>
    </template>
    <template v-else-if="stage === 'password_was_reset'">
      <p>Your password has been reset. <a href="/">Return to the Log-in page.</a></p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.reset-password-page {
  margin: 2rem auto;
  min-width: 600px;

  label {
    width: 100%;
  }
}
</style>