import config from "@/sys/config";
import {postJsonAsync, getJsonAsync, deleteAsync} from "@/api/apiUtils";
import ResultSetItem from "@/lib/entities/ResultSetItem";
import Results from "@/lib/entities/Results";
import VarIndex from "@/lib/entities/VarIndex";
import {NewResultSet} from "@/lib/entities/NewResultSet";
import VarCategories from "@/lib/entities/VarCategories";
import ClassificationData from "@/lib/entities/ClassificationData";
import {DatasetSourceItemData} from "@/lib/entities/DatasetSourceItem";

export async function getResultSetsAsync(token, projectId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('project is null')

    const url = config.SERVICE_URL + 'data/resultsets?projectid=' + projectId
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200)
        throw new Error('Error getting ResultSets')
    const obj = await resp.json()
    return ResultSetItem.fromArray(obj)
}

export async function deleteResultResultAsync(token, refId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')

    const url = config.SERVICE_URL + 'data/resultset?refId=' + refId

    const resp = await deleteAsync(url, {}, token)

    if(resp.status !== 200) throw new Error('Failed to delete ResultSet')
}

export async function getClassificationsDataAsync(token, refId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')

    const url = config.SERVICE_URL + `data/classifications?refid=${refId}`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200) return null

    const json = await resp.json()

    return ClassificationData.fromObject(json)
}

export async function getResultsAsync(token, refId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')

    const url = config.SERVICE_URL + 'data/results?refId=' + refId
    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error getting results')

    const obj = await resp.json()
    return Results.fromObject(obj)
}

export async function getVarIndexAsync() {
    const url = config.SERVICE_URL + 'data/varindex'
    const resp = await getJsonAsync(url)

    if (resp.status !== 200)
        throw new Error('Error getting VarIndex')

    const obj = await resp.json()
    return VarIndex.fromObject(obj)
}

export async function getVarsUsingSubAsync(token) {
    const url = config.SERVICE_URL + 'data/var-access'
    const resp = await getJsonAsync(url, token)

    if (resp.status === 402) // payment required
        return new VarIndex()

    if (resp.status !== 200)
        throw new Error('Error getting VarIndex')

    const obj = await resp.json()

    console.log('getVarsUsingSubAsync', obj)

    return VarIndex.fromObject(obj)
}

export async function getVarCategoriesAsync() {
    const url = config.SERVICE_URL + 'data/varcategories'
    const resp = await getJsonAsync(url)

    if (resp.status !== 200)
        throw new Error('Error getting VarCategories')

    const obj = await resp.json()
    return VarCategories.fromObject(obj)
}

export async function createResultSetAsync(token, newResultSet) {
    const url = config.SERVICE_URL + 'data/resultset'
    const resp = await postJsonAsync(url, newResultSet, token)

    if (resp.status !== 200) {
        const o = await resp.json();
        switch(resp.status) {
            case 402: {
                throw new Error('payment.error:' + o.error)
            }
            default: {
                throw new Error('Failed to create Result Set')
            }
        }
    }
}

export async function getInfoAsync(token, refId) {
    const url = config.SERVICE_URL + `data/info/${refId}`
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200) {
        throw new Error('Error getting data request information')
    }
    return NewResultSet.fromObject(await resp.json())
}

/**
 * @returns {Promise<DatasetSourceItemData|*>}
 */
export async function getDatasetSourcesAsync(token, refId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')

    const url = config.SERVICE_URL + `data/sources/${refId}`

    const resp = await getJsonAsync(url, token)

    switch(resp.status) {
        case 200:
            return DatasetSourceItemData.from(await resp.json())
        case 404:
            return new DatasetSourceItemData()
        default:
            throw new Error('Error getting data sources info')
    }
}