const colors = [
    'crimson',
    'orangered',
    'yellow',
    'limegreen',
    'darkcyan',
    'blue',
    'darkmagenta',
    'mediumvioletred',
    'antiquewhite',
    'darkslategray',
    'lightcoral',
    'darkorange',
    'moccasin',
    'seagreen',
    'turquoise',
    'deepskyblue',
    'orchid',
    'aquamarine',
    'deeppink',
    'rosybrown',
    'darkslateblue',
    'brown',
    'pink',
]

export { colors }