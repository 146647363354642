<template>
  <div class="dialog result-set-export-dialog">
    <div class="dialog-background"/>
    <div class="dialog-content general-panel">
      <loading-indicator v-if="isLoading"/>
      <div class="window-controls">
        <a href="#" @click.prevent="emit('cancel')">&times;</a>
      </div>

      <h3>Download Reports</h3>

      <p>Select a report format:</p>

      <div class="base-list-box exporter-list">
        <div class="list-box-scroller">
          <div class="item"
               v-for="(exporterRef, index) in ResultSetExporters.exporters"
               :class="{selected: exporterRef.id === selected}"
               :key="index"
               @click.prevent="selected = exporterRef.id">
            {{ exporterRef.name }}
          </div>
        </div>
      </div>

      <div class="form-group">
        <label>Branding profile: (optional)</label>
        <select class="form-control" v-model="selectedBrandingProfile" :disabled="!selectedExporterSupportsBranding">
          <option value="" selected>None</option>
          <option v-for="(bp, index) in brandingProfiles" :key="index" :value="bp.brandingEntityId">{{ bp.name }}</option>
        </select>
      </div>

      <div class="button-strip-container">
        <div class="button-strip"></div>
        <div class="button-strip">
          <div class="button-row">
            <button class="btn btn-primary" @click="export_Clicked">Download</button>
            <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, onMounted, onUnmounted, computed, toRaw } from "vue";
import { getBrandingProfilesAsync } from "@/api/brandingApi";
import {useUserStore} from "@/stores/userStore";
import ResultSetExporters from "@/lib/dataExport/resultSet/ResultSetExporters";
import {TimeHorizon} from "@/lib/utils/TimeHorizon";
import LoadingIndicator from "@/components/LoadingIndicator";
import {ResultSetReader} from "@/lib/ResultSetReader";
import keyboard from "@/sys/keyboard";

const userStore = useUserStore()
const selected = ref(null)
const brandingProfiles = ref([])
const selectedBrandingProfile = ref('')
const isLoading = ref(false)

const emit = defineEmits(['exported', 'cancel'])
const props = defineProps({
  dataReader: ResultSetReader,
  timeHorizon: {
    type: TimeHorizon,
    default: new TimeHorizon()
  }
})

async function exportData(exporterRef, brandingProfile) {
  const exporter = exporterRef.create()
  try
  {
    exporter.brandingProfile = brandingProfile
    exporter.timeHorizon = props.timeHorizon
    await exporter.exportAsync(props.dataReader)
    emit('exported')
  }
  catch(error)
  {
    alert('There was an error exporting data')
  }
}

async function export_Clicked() {
  const exporterRef = ResultSetExporters.find(selected.value)
  const brandingProfile = toRaw(brandingProfiles.value.find(b => b.brandingEntityId === selectedBrandingProfile.value))

  if (exporterRef == null)
    return

  isLoading.value = true
  try {
    await exportData(exporterRef, brandingProfile)
  }
  finally {
    isLoading.value = false
  }
}

const selectedExporterSupportsBranding = computed(() => {
  const exporter = ResultSetExporters.find(selected.value)
  if (exporter == null)
    return false
  return exporter.supportsBranding
})

onMounted(async () => {
  keyboard.push('Escape', () => emit('cancel'))
  brandingProfiles.value = await getBrandingProfilesAsync(userStore.token)
})

onUnmounted(() => keyboard.pop('Escape'))

</script>

<style scoped>
.result-set-export-dialog .dialog-content {
  min-width: 500px;
}

.exporter-list {
  min-height: 280px;
}
</style>