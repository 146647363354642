import {getClassificationsDataAsync, getDatasetSourcesAsync, getResultsAsync} from "@/api/resultSetApi";
import {getMultiFileAsetResultsAsync, getMultiFileResultInfoAsync} from "@/api/multiFileResultSets";
import {TimeHorizon} from "@/lib/utils/TimeHorizon";

class ResultSetReader {
    /**
     * @description Loads the specified result set. Raises an exception on failure.
     */
    async loadAsync(token, refId) {
        throw new Error('Not implemented')
    }

    /** @return ResultsInfo */
    get info() {
        throw new Error('Not implemented')
    }

    get classificationData() {
        throw new Error('Not implemented')
    }

    /** @return AssetResults */
    async getAssetResultsAsync(assetId) {
        throw new Error('Not implemented')
    }

    get datasetSources() {
        throw new Error('Not implemented')
    }
}

/**
 * @param {string} storageType Storage type identifier, such as "MultiFile" or "SingleFile"
 * @return {ResultSetReader}
 */
function createResultSetReader(storageType) {
    switch(storageType) {
        case 'MultiFile': return new MultiFileResultSetReader()
        case 'SingleFile': return new SingleFileResultSetReader()
        default: throw new Error(`Unsupported StorageType: ${storageType}`)
    }
}

class SingleFileResultSetReader extends ResultSetReader {
    /** @type Results */
    results = null

    /** @type ClassificationData|null */
    _classificationData = null

    /** @type DatasetSourceItemData|null */
    _datasetSources = null

    async loadAsync(token, refId) {
        const resultsTask = getResultsAsync(token, refId)
        const classificationDataTask = getClassificationsDataAsync(token, refId)
        const sourcesTask = getDatasetSourcesAsync(token, refId)

        this.results = await resultsTask
        this._classificationData = await classificationDataTask
        this._datasetSources = await sourcesTask
    }

    get info() {
        return this.results.info
    }

    async getAssetResultsAsync(assetId) {
        return this.results.getAssetResults(assetId)
    }

    get classificationData() {
        return this._classificationData
    }

    get datasetSources() {
        return this._datasetSources
    }
}

class MultiFileResultSetReader extends ResultSetReader {
    /** @type ClassificationData|null */
    _classificationData = null
    /** @type string */
    token
    /** @type string */
    refId
    /** @type ResultsInfo */
    resultInfo = null
    /** @type Object.<string,AssetResults> */
    assetResults = {}
    /** @type DatasetSourceItemData|null */
    _datasetSources = null

    async loadAsync(token, refId) {
        this.token = token
        this.refId = refId

        const resultInfoTask = getMultiFileResultInfoAsync(token, refId)
        const classificationDataTask = getClassificationsDataAsync(token, refId)
        const sourcesTask = getDatasetSourcesAsync(token, refId)

        this.resultInfo = await resultInfoTask
        this._classificationData = await classificationDataTask
        this._datasetSources = await sourcesTask
    }

    get info() {
        return this.resultInfo
    }

    get classificationData() {
        return this._classificationData
    }

    async getAssetResultsAsync(assetId) {
        if (this.assetResults[assetId] != null)
            return this.assetResults[assetId]

        const results = this.assetResults[assetId] = await getMultiFileAsetResultsAsync(this.token, this.refId, assetId)

        return results
    }

    get datasetSources() {
        return this._datasetSources
    }
}

class ResultSetStringBuilder {
    sep = ','
    timeHorizon = new TimeHorizon()

    /** @param {ResultSetReader} reader */
    async buildStringAsync(reader) {
        let s = ''

        for(let i=0; i < reader.info.assets.length; i++) {
            const asset = reader.info.assets[i]
            const data = await reader.getAssetResultsAsync(asset.assetId)
            if (data == null) continue;
            s += `Asset: ${asset.name}\nLat: ${asset.latLng.lat} Lng: ${asset.latLng.lng}\n\n`
            s += data.toString(this.sep, this.timeHorizon)
        }

        return s
    }
}

export { createResultSetReader, ResultSetReader, SingleFileResultSetReader, MultiFileResultSetReader, ResultSetStringBuilder }