<template>
  <div class="result-set-item-view">

    <h4>{{ varIdToVarName(tableSet.varId) }}, {{ Strings.CURVE_LABEL }}: {{ formatCurveName(tableSet.curve) }}, {{ Strings.PERCENTILE_LABEL }}: {{ tableSet.percentile }}th</h4>

    <h5>Data ({{tableSet.units}})</h5>

    <table-view :table="tableSet.data" :time-horizon="timeHorizon" :dp="dp"/>

    <h5>Changes ({{tableSet.changeUnits}})</h5>

    <table-view :table="tableSet.changes" :time-horizon="timeHorizon" :dp="dp"/>

    <h5>Scores</h5>

    <scores-table :table="tableSet.scores"></scores-table>

    <div class="dataset-sources" v-if="tableSet.version != null">
      Data Source version {{ tableSet.version }} ({{tableSet.updatedDate}})
    </div>

    <div class="dataset-sources" v-if="datasetSource != null">
      Source: {{datasetSource.source}} ({{datasetSource.region}})
      <template v-if="datasetSource.infoSource != null">
        <a v-if="isWebLink(datasetSource.infoSource)" :href="datasetSource.infoSource" target="_blank">{{datasetSource.infoSource}}</a>
        <span v-else>{{datasetSource.infoSource}}</span>
      </template>
      <br/>
      <a href="#" @click.prevent="showMoreDetails = true">More details</a>
    </div>
    <dataset-source-item-dialog v-if="showMoreDetails" :dataset-source-item="datasetSource" @close="showMoreDetails = false"/>
  </div>
</template>

<script setup>
import { defineProps, computed, ref } from "vue";
import Strings from "@/lib/lang/Strings";
import {varIdToVarName} from "@/lib/utils/varNameUtils";
import {TableSet} from "@/lib/entities/Results";
import ScoresTable from './ScoresTable'
import TableView from './TableView'
import {TimeHorizon} from "@/lib/utils/TimeHorizon";
import {formatCurveName} from "@/lib/utils/curveName";
import {DatasetSourceItem} from "@/lib/entities/DatasetSourceItem";
import DatasetSourceItemDialog from "@/components/DataPage/DatasetSourceItemDialog.vue";

const props = defineProps({
  tableSet: TableSet,
  timeHorizon: TimeHorizon,
  dp: {
    type: Number,
    required: false,
    default: 2
  },
  datasetSource: {
    type: DatasetSourceItem,
    required: false
  }
})

function isWebLink(s) {
  if (typeof s !== 'string')
    return false
  s = s.toLowerCase()
  return s.startsWith('http://') || s.startsWith('https://') || s.startsWith('ftp://')
}

const showMoreDetails = ref(false)

</script>

<style scoped>
.result-set-item-view {
}

.dataset-sources {
  font-size: 8pt;
}

.dataset-sources,
.dataset-sources a {
  color: #555 !important;
}
</style>