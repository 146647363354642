<template>
  <div class="dialog">
    <div class="dialog-background" @click="emit('cancel')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>

      <h2>{{ strings.STR_TIME_HORIZON }}</h2>

      <p>
        {{ strings.TIME_HORIZON_DIALOG_INTRO }}
      </p>

      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a href="#" class="nav-link" :class="{ active: tab === 'all' }" @click.prevent="tab = 'all'">All years</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link" :class="{ active: tab === 'specific' }" @click.prevent="tab = 'specific'">Specific years</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link" :class="{ active: tab === 'range' }" @click.prevent="tab = 'range'">Year range</a>
        </li>
      </ul>

      <div class="tab-content">
        <template v-if="tab === 'all'">
          No options for "All years" to show.
        </template>
        <template v-if="tab === 'specific'">
          <div class="year-list">
            <button v-for="yearItem in years" :key="yearItem.year" class="btn" :class="{'btn-primary': yearItem.selected, 'btn-light': !yearItem.selected}" @click="yearItem.selected = !yearItem.selected">{{ yearItem.year }}</button>
          </div>
          <hr/>
          <div class="button-row">
            <div class="button-strip">
              <button class="btn btn-secondary btn-" @click="selectAll_Click">Select all</button>
              <button class="btn btn-secondary" @click="selectNone_Click">Select none</button>
            </div>
          </div>
        </template>
        <template v-if="tab === 'range'">
          <label>Start:<br/>
            <input type="text" v-model="startRange"/>
          </label>

          <label>End year:<br/>
            <input type="text" v-model="endRange"/>
          </label>
        </template>
      </div>

      <div class="button-strip-container">
        <div class="button-strip"/>
        <div class="button-strip">
          <div class="button-row">
            <button class="btn btn-primary" @click="ok_Clicked">OK</button>
            <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { ref, defineEmits, defineProps, onMounted, onUnmounted } from "vue";
import { TimeHorizon, SpecificTimeHorizon, RangedTimeHorizon } from "@/lib/utils/TimeHorizon";
import strings from "@/lib/lang/Strings";
import keyboard from "@/sys/keyboard";

let allYears = []
for(let i=2005; i <=2100; i+=5)
  allYears.push(i)

const props = defineProps({
  timeHorizon: {
    type:TimeHorizon,
    default: new TimeHorizon()
  }
})

const tab = ref('all')
const startRange = ref(2005)
const endRange = ref(2100)
const emit = defineEmits(['changed', 'cancel'])

const years = ref(allYears.map(year => {
  return {
    year: year,
    selected: year === 2005 || year % 10 === 0
  }
}))

onMounted(() => {
  keyboard.push('Escape', () => emit('cancel'))
  tab.value = props.timeHorizon.type
  if (props.timeHorizon.type === 'specific') {
    years.value.forEach(y => y.selected = props.timeHorizon.includes(y.year))
  } else if (props.timeHorizon.type === 'range') {
    startRange.value = props.timeHorizon.startYear
    endRange.value = props.timeHorizon.endYear
  }
})

onUnmounted(() => keyboard.pop('Escape'))

function selectAll_Click() {
  years.value.forEach(y => y.selected = true)
}

function selectNone_Click() {
  years.value.forEach(y => y.selected = false)
}

function confirm(timeHorizon) {
  emit('changed', timeHorizon)
}

function ok_Clicked() {
  switch(tab.value) {
    case 'all':
      confirm(new TimeHorizon())
      break
    case 'specific':
      confirm(new SpecificTimeHorizon(years.value.filter(y => y.selected).map(y => y.year)))
      break
    case 'range':
      confirm(new RangedTimeHorizon(startRange.value, endRange.value))
  }
}
</script>

<style scoped>

.dialog-content {
  min-width: 500px;
}

.tab-content {
  border: 1px solid #e0e0e0;
  border-top: none;
  padding: 1rem;
}

.year-list {
  max-width: 400px;
}

.year-list button {
  margin: 0.4rem;
}

</style>