import {defineStore} from "pinia/dist/pinia";
import StrUtils from "@/lib/utils/StrUtils";

export const useProjectStore = defineStore({
    id: 'projectStore',
    state: () => {
        return {
            all: [],
            current: null
        }
    },
    getters: {
        sorted() {
            let projects = Array.from(this.all)
            projects.sort((a, b) => {
                return StrUtils.compare(a.name, b.name)
            })
            return projects
        }
    },
    actions: {
        updateProjects(projects, clearCurrent = true) {
            if (clearCurrent)
                this.current = null
            this.all = projects
        }
    }
})