import ErrorResponse from "@/lib/utils/ErrorResponse";
import axios from "axios"

class ServiceApi {
    init(endpoint, logging) {
        this.baseEndpoint = endpoint
        this.logging = logging || false
    }

    get(logActionName, endpoint, params, onSuccess, onError) {
        this.logBeforeSend(logActionName, params)
        let config = {}
        if (params != null) config.params = params
        let p = axios.get(endpoint, config)
        this.handleResponse('[GET]' + logActionName, p, onSuccess, onError)
    }

    getUsingPromise(logActionName, endpoint, params) {
        let _t = this
        return new Promise((resolve, reject) => {
            _t.get(logActionName, endpoint, params, (data) => {
                resolve(data)
            }, (error) => {
                reject(error)
            })
        })
    }

    post(logActionName, endpoint, data, onSuccess, onError) {
        this.logBeforeSend(logActionName, data)
        let p = axios.post(endpoint, data)
        this.handleResponse('[POST]' + logActionName, p, onSuccess, onError)
    }

    put(logActionName, endpoint, data, onSuccess, onError) {
        this.logBeforeSend(logActionName, data)
        let p = axios.put(endpoint, data)
        this.handleResponse('[PUT]' + logActionName, p, onSuccess, onError)
    }

    postUsingPromise(logActionName, endpoint, data) {
        let _t = this
        return new Promise((resolve, reject) => {
            _t.post(logActionName, endpoint, data,
                (result) => {
                    resolve(result)
                }, (error) => {
                    reject(error)
                })
        })
    }

    putUsingPromise(logActionName, endpoint, data) {
        let _t = this
        return new Promise((resolve, reject) => {
            _t.put(logActionName, endpoint, data,
                (result) => {
                    resolve(result)
                }, (error) => {
                    reject(error)
                })
        })
    }

    deleteUsingPromise(logActionName, endpoint, params) {
        let _t = this
        return new Promise((resolve, reject) => {
            _t.delete(logActionName, endpoint, params, (data) => {
                resolve(data)
            }, (error) => {
                reject(error)
            })
        })
    }

    delete(logActionName, endpoint, params, onSuccess, onError) {
        this.logBeforeSend(logActionName, params)
        let p = axios.delete(endpoint, { params })
        this.handleResponse('[DELETE] ' + logActionName, p, onSuccess, onError)
    }

    handleResponse(cmd, p, onSuccess, onError, onComplete) {
        p.then((response) => {
            this.logSuccess(cmd, response)
            if (ErrorResponse.isError(response.data)) {
                if (onError != null)
                    onError(new ErrorResponse(response.data))
                return
            }
            if (onSuccess != null)
                onSuccess(response.data, response.status)
        })
        .catch((err) => {
            this.logError(cmd, err)
            if (onError != null) {
                let response = err.response || { status: -1, data: null, message: 'Unknown error' }
                if (ErrorResponse.isError(response.data)) {
                    if (onError != null) {
                        onError(new ErrorResponse(response.data))
                        return
                    }
                }

                if (onError != null)
                    onError(new ErrorResponse({ type: 'general.unknown', data: err.message + ', status: ' +  response.status}))
            }
        })
        .then(() => {
            if (onComplete != null)
                onComplete()
        })
    }
    
    logError(cmd, err) {
        if (this.logging) {
            let response = err.response || { status: -1, data: 'fake-response' }
        }
    }
    
    logSuccess(cmd, response) {
        if (this.logging) {
            let status = -1
            if (response != null)
                status = response.status
        }
    }
    
    logBeforeSend(cmd, data) {

    }    
}

export default ServiceApi