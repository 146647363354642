import ServiceApi from "@/lib/utils/ServiceApi";
import axios from "axios";
import { ReadValues, ForecastList, ForecastVarInventory } from '@/lib/extendWeather/ForecastObjects'
import config from "@/sys/config";

class ExtendWeatherServiceApi extends ServiceApi {
    createSession(token, onSuccess, onError) {
        let data = { token }
        this.logBeforeSend('CreateSession', data)

        this.handleResponse('CreateSession', axios.post(this.baseEndpoint + 'CreateSession', data), 
            (data) => {
                onSuccess(data.session)    
            }, 
            onError)
    }
    
    getForecastInventory(token, session, onSuccess, onError) {
        let options = { params: { token, session } }
        this.logBeforeSend('ForecastInventory', options)
        
        this.handleResponse('ForecastInventory', axios.get(this.baseEndpoint + 'ForecastInventory', options),
            (data) => {
                let result = new ForecastList(data)
                onSuccess(result)
            },
            onError)
    }

    getForecastVarInventory(token, session, cid, fid, onSuccess, onError) {
        let options = { params: { token, session, cid, fid } }
        this.logBeforeSend('ForecastVarInventory', options)

        this.handleResponse('getForecastVarInventory', axios.get(this.baseEndpoint + 'ForecastVarInventory', options), 
            (data) => {
                let result = new ForecastVarInventory(data)
                onSuccess(result)
            }, 
            onError)
    }

    getReadVarValues(token, session, cid, fid, varid, lat, lng, onSuccess, onError) {
        let options = { params: { token, session, cid, fid, varid, lat, lng } }
        this.logBeforeSend('getReadVarValues', options)

        this.handleResponse('getReadVarValues', axios.get(this.baseEndpoint + 'ReadVarValues', options), 
            (data) => {
                let result = new ReadValues(data)
                onSuccess(result)
            }, 
            onError)
    }
}

const service = new ExtendWeatherServiceApi() 
service.init(config.SERVICE_URL + 'extendweather/')
export default service