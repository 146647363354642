const knownVarNames = {
    "Apparent_Temperature_Cat": "Apparent Temperature",
    "Extreme_Precipitation.hrs_3": "Extreme Precipitation (3 hour)",
    "Extreme_Precipitation.hrs_6": "Extreme Precipitation (6 hour)",
    "Extreme_Precipitation.hrs_12": "Extreme Precipitation (12 hour)",
    "Extreme_Precipitation.hrs_24": "Extreme Precipitation (24 hour)",
    "Extreme_Precipitation.hrs_48": "Extreme Precipitation (48 hour)",
    "Extreme_Precipitation.hrs_72": "Extreme Precipitation (72 hour)",
    "Extreme_Precipitation.hrs_96": "Extreme Precipitation (96 hour)",
    "Extreme_Precipitation.hrs_120": "Extreme Precipitation (120 hour)",
    "Extreme_Temperature_Days_Higher_35degC": "Extreme Temperature Days Higher 35°C",
    "Extreme_Temperature_Days_Lower_2degC": "Extreme Temperature Days Lower 2°C",
    "Riverine_Flood.hrs_3": "Riverine Flood (3 hour)",
    "Riverine_Flood.hrs_6": "Riverine Flood (6 hour)",
    "Riverine_Flood.hrs_12": "Riverine Flood (12 hour)",
    "Riverine_Flood.hrs_24": "Riverine Flood (24 hour)",
    "Riverine_Flood.hrs_48": "Riverine Flood (48 hour)",
    "Riverine_Flood.hrs_72": "Riverine Flood (72 hour)",
    "Riverine_Flood.hrs_96": "Riverine Flood (96 hour)",
    "Riverine_Flood.hrs_120": "Riverine Flood (120 hour)",
    "Riverine_Flood.hrs_144": "Riverine Flood (144 hour)",
    "Riverine_Flood.hrs_168": "Riverine Flood (168 hour)",
    "Riverine_Flood.hrs_240": "Riverine Flood (240 hour)",
    "Extreme_Temperature_Days_Higher_38degC": "Extreme Temperature Days Higher 38°C",
    "Forest_Fire_Danger_Index_Cat": "Forest Fire Danger Index",
    "Extreme_Temperature_Days_Higher_45degC": "Extreme Temperature Days Higher 45°C",
    "Humidex_Cat": "Humidex",
    "Keetch_Byram_Drought_Index_Cat": "Keetch Byram Drought Index",
    "Mean_Temperature_Days_Higher_24degC": "Mean Temperature Days Higher 24°C",
    "Mean_Temperature_Days_Higher_30degC": "Mean Temperature Days Higher 30°C",
    "Mean_Temperature_Days_Lower_10degC": "Mean Temperature Days Lower 10°C",
    "Mean_Temperature_Days_Lower_18degC": "Mean Temperature Days Lower 18°C",
    "Mean_Temperature_Days_Lower_6degC": "Mean Temperature Days Lower 6°C",
    "Modified_Discomfort_Index_Cat": "Modified Discomfort Index",
    "Monthly_Soil_Moisture.depth_7": "Monthly Soil Moisture (7 cm)",
    "Monthly_Soil_Moisture.depth_28": "Monthly Soil Moisture (28 cm)",
    "Monthly_Soil_Moisture.depth_100": "Monthly Soil Moisture (100 cm)",
    "Monthly_Soil_Moisture.depth_289": "Monthly Soil Moisture (289 cm)",
    "Monthly_Soil_Temperature.depth_7": "Monthly Soil Temperature (7 cm)",
    "Monthly_Soil_Temperature.depth_28": "Monthly Soil Temperature (28 cm)",
    "Monthly_Soil_Temperature.depth_100": "Monthly Soil Temperature (100 cm)",
    "Monthly_Soil_Temperature.depth_289": "Monthly Soil Temperature (289 cm)",
    "Normal_Effective_Temperature_Cat": "Normal Effective Temperature",
    "Temperature_Humidity_Index_for_Cattle_Cat": "Temperature Humidity Index for Cattle",
    "Temperature_Humidity_Index_for_Comfort_Cat": "Temperature Humidity Index for Comfort",
    "Temperature_Humidity_Index_for_Physiology_Cat": "Temperature Humidity Index for Physiology",
    "Wind_Chill_Temperature_Cat": "Wind Chill Temperature",
}

function getKnownVarName(id) {
    return knownVarNames[id]
}

/** @param {String} id */
function formatVarName(id) {
    return id.replaceAll('_', ' ')
        .replaceAll('.', ' - ')
}

function varIdToVarName(id) {
    const knownVarName = getKnownVarName(id)
    if (knownVarName != null)
        return knownVarName

    return formatVarName(id)
}

export {
    getKnownVarName,
    formatVarName,
    varIdToVarName
}