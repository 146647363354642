async function elementCanvasToBlobAsync(el) {
    if (el == null)
        return null

    if (el.$el !== undefined)
        return await elementCanvasToBlobAsync(el.$el)

    if (el.tagName !== 'CANVAS')
        return await elementCanvasToBlobAsync(el.querySelector('canvas'))

    const canvas = el

    return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob == null)
                reject()

            resolve(blob)
        })
    })
}

export {elementCanvasToBlobAsync}