<template>
  <nav id="app-toolbar">
    <router-link :to="{ name: 'welcome' }" class="navbar-brand">
<!--      <img v-if="userStore.hasBrandingThemeImage" :src="userStore.brandingTheme.objectUrl" class="branding-image" :alt="userStore.brandingTheme.profile.companyName" />-->
      <img :src="Strings.RESOURCE_LOGO_URL" width="120" :alt="Strings.RESOURCE_LOGO_ALT"/>
    </router-link>

    <ul class="main-nav-items no-print">
      <li class="nav-item">
        <router-link class="toolbar-link" :to="{ name: 'welcome' }"  :class="{ active: route.name === 'welcome' }">{{ Strings.TOOLBAR_HOME }}</router-link>
      </li>
      <template v-if="userStore.isLoggedIn">
        <li class="nav-item" v-if="projectStore.current != null">
          <router-link class="toolbar-link" :to="{ name: 'data' }" :class="{ active: route.name === 'data' }" href="#">{{ Strings.TOOLBAR_CREATE_INSIGHTS }}</router-link>
        </li>

        <li v-if="projectStore.current != null && userStore.isMemberOf('floodmaps')" class="nav-item">
          <router-link class="toolbar-link" :to="{ name: 'flood' }" :class="{ active: route.name === 'flood' }" href="#">{{ Strings.TOOLBAR_FLOOD_TOOL }}</router-link>
        </li>

        <li class="nav-item">
          <router-link class="toolbar-link" :to="{ name: 'forecast' }"  :class="{ active: route.name === 'forecast' }">{{ Strings.TOOLBAR_EXTEND_WEATHER }}</router-link>
        </li>

        <li class="nav-item">
          <a class="toolbar-link" href="https://help.climsystems.com/docs/climate-insights-ar6/welcome.html" target="_blank" >{{ Strings.TOOLBAR_HELP }}</a>
        </li>

        <li class="nav-item" style="margin-left: 2rem">
          <div v-if="projectStore.current != null" style="font-size: 7pt; line-height: 0.1rem">Project:</div>
          <template v-if="projectStore.current != null">{{ projectStore.current.name }}</template>
        </li>

      </template>
    </ul>

    <user-menu class="no-print" v-if="userStore.isLoggedIn" />
  </nav>
</template>

<script setup>
import Strings from "@/lib/lang/Strings";
import { useRoute, RouterLink } from 'vue-router'

import { useProjectStore } from "@/stores/projectStore";
import { useUserStore } from "@/stores/userStore";
import UserMenu from "@/components/Toolbar/UserMenu";

const projectStore = useProjectStore()
const userStore = useUserStore()
const route = useRoute()

</script>

<style lang="scss" scoped>

@import '@/assets/std-theme';

#app-toolbar {
  display: flex;
  flex-direction: row;
  background-color: $toolbar-background-color !important;
  border-bottom: 1px solid $toolbar-border-color;
  padding: 0.5rem;
}

#app-toolbar ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#app-toolbar ul.main-nav-items {
  flex: 1;
}

#app-toolbar ul a {
  text-decoration: none;
  margin: 0.2rem;
}

#app-toolbar ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

#app-toolbar a.toolbar-link {
  color: $toolbar-toolbar-link !important;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

#app-toolbar a.toolbar-link:hover {
  background-color: $toolbar-toolbar-link-hover;
}

#app-toolbar a.toolbar-link.active {
  background-color: $toolbar-toolbar-link-active;
}
</style>