import DateTime from "@/lib/DateTime";

export class ApiKey {
    name = ''
    permissions = []
    expires = new Date(2000, 0, 1)
    ipFilters = []
    lookup = ''

    static fromObject(o) {
        let result = new ApiKey()
        result.name = o.name
        result.permissions = o.permissions
        result.expires = DateTime.parseDate(o.expires)
        result.ipFilters = o.ipFilters
        result.lookup = o.lookup
        return result
    }

    static fromArray(arr) {
        return arr.map(o => ApiKey.fromObject(o))
    }
}

export class CreatedApiKey {
    keyString = ''
    expiryDate = new Date(2000, 0, 1)

    static fromObject(o) {
        let result = new CreatedApiKey()
        result.keyString = o.keyString
        result.expiryDate = DateTime.parseDate(o.expiryDate)
        return result
    }
}