<template>
<div class="chart-view">
  <Line class="chart" :chart-data="chartData" :chart-options="{animations: false, maintainAspectRatio: false}" ref="chart"/>
</div>
</template>

<script setup>
import {ref, toRaw, computed, defineProps, defineExpose} from "vue";
import {Table} from "@/lib/entities/Results";
import {Line} from 'vue-chartjs'
import {Chart, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale} from 'chart.js'
import {TimeHorizon} from "@/lib/utils/TimeHorizon";

import { colors } from "@/lib/utils/colors";
import {elementCanvasToBlobAsync} from "@/lib/utils/canvasUtils";

const chart = ref()

Chart.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

const props = defineProps({
  table: Table,
  timeHorizon: TimeHorizon
})

const chartData = computed(() => {
  /** @type Table */ const t = props.table

  const th = props.timeHorizon
  const rows = t.rows.filter(row => th.contains(row.year))

  if (t.columns.length === 1) {
    return {
      labels: rows.map(row => row.year),
      datasets: [
        {
          label: t.tableInfo.type,
          data: rows.map(row => row.values[0]),
          borderColor: colors[0]
        }
      ]
    }
  }

  return {
    labels: t.columns,
    datasets: rows.map((row, index) => {
      return {
        label: row.year,
        data: row.values,
        borderColor: colors[index],
      }
    })
  }
})

async function getImageDataAsync() {
  return await elementCanvasToBlobAsync(chart.value)
}

defineExpose({getImageDataAsync})

</script>

<style scoped>

.chart-view {
  position: relative;
}

.chart {
  max-height: 600px;
  min-height: 100%;
}
</style>