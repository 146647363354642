<script setup>
import {ref, onMounted, onUnmounted, defineProps, defineEmits, computed} from "vue";
import UserGroup from "@/lib/entities/UserGroup";
import {getFullUsageDataAsync, getManageGroupDataAsync, getUserUsageDataAsync} from "@/api/groupsApi";
import {useUserStore} from "@/stores/userStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import keyboard from "@/sys/keyboard";
import GroupMemberUsageDialog from "@/components/SettingsPage/dialogs/GroupMemberUsageDialog.vue";

const kDialogMain = 'main'
const kDialogUserUsage = 'user-usage'
const kFullReport = 'full'
const kUserReport = 'user'

const userStore = useUserStore()

const groupData = ref(null)
const isLoading = ref(false)
const selectedMemberId = ref(null)
const currentUsageData = ref([])
const currentUsageDataType = ref(kUserReport)

const screen = ref(kDialogMain)

const props = defineProps({
  group: {
    type: UserGroup,
    required: true
  }
})

onMounted(async () => {
  keyboard.push('Escape', () => { emit('close') })
  groupData.value = null
  isLoading.value = true
  try {
    const data = await getManageGroupDataAsync(userStore.token, props.group.id)
    groupData.value = data
  }
  finally {
    isLoading.value = false
  }
})

onUnmounted(() => {
  keyboard.pop('Escape')
})

const emit = defineEmits(['close'])
const dataLoaded = computed(() => groupData.value != null)
const groupDescription = computed(() => {
  if (props.group == null || props.group.description == null || props.group.description === '')
    return 'No description provided'
  return props.group.description
})
const noRoles = computed(() => {
  const g = groupData.value
  return g == null || !Array.isArray(g.roles) || g.roles.length === 0
})

function mailtoLink(email) {
  if (email == null || email.length < 3 || email.indexOf('@') === -1)
    return '#'
  return `mailto:${email}`
}

async function runEventAsync(event) {
  isLoading.value = true
  try {
    await event()
  }
  finally {
    isLoading.value = false
  }
}

async function userUsageReport_Click() {
  await runEventAsync( async () => {
    if (selectedMemberId.value == null)
      return
    const data = await getUserUsageDataAsync(userStore.token, props.group.id, selectedMemberId.value)
    currentUsageData.value = data
    currentUsageDataType.value = kFullReport
    screen.value = kDialogUserUsage
  })
}

async function fullUsageReport_Click() {
  await runEventAsync( async () => {
    const data = await getFullUsageDataAsync(userStore.token, props.group.id)
    currentUsageData.value = data
    currentUsageDataType.value = kFullReport
    screen.value = kDialogUserUsage
  })
}

</script>

<template>
  <div class="dialog manage-group-dialog no-print">
    <div class="dialog-background"/>
    <div class="dialog-content general-panel no-print">
      <div class="window-controls"><a href="#" @click.prevent="emit('close')">&times;</a></div>
      <h3>Manage Group - {{group.name}}</h3>
      <div class="content-items" v-if="dataLoaded">
        <div class="content user-panel">
          <h5>Description</h5>
          <p>{{groupDescription}}</p>

          <h5>Group contacts</h5>
          <p v-if="noRoles">
            This group has no key contacts.
          </p>
          <ul v-else>
            <li v-for="role in groupData.roles" :key="role.id">
              {{role.name}} : {{role.role}}<br/>
              <a :href="mailtoLink(role.email)">{{role.email}}</a>
            </li>
          </ul>
        </div>
        <div class="content admin-panel">
          <h5>Members</h5>
          <div class="base-list-box flex-fill">
            <div class="list-box-scroller">
              <div v-for="member in groupData.members" :key="member.userId" class="item" :class="{selected: member.userId === selectedMemberId}" @click.prevent="selectedMemberId = member.userId">
                <label>{{member.name}}</label>
              </div>
            </div>
          </div>
          <div class="button-row">
            <button class="btn btn-primary" @click="userUsageReport_Click">User Usage Report</button>
            <button class="btn btn-primary" @click="fullUsageReport_Click">Full Usage Report</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <loading-indicator v-if="isLoading"/>
  <group-member-usage-dialog v-if="screen === kDialogUserUsage" :data="currentUsageData" :show-billable-data="currentUsageDataType === kFullReport" :members="groupData.members" @close="screen = kDialogMain"/>
</template>

<style lang="scss" scoped>
.manage-group-dialog {
  .dialog-content {
    width: 80%;
    min-width: 300px;
    max-width: 800px;
    height: 90%;

    display: flex;
    flex-direction: column;

    .content-items {
      flex: 1;
      display: flex;
      flex-direction: row;

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;

        &.admin-panel {
          flex: 2;
        }
      }
    }
  }
}
</style>