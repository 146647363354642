import {LatLng} from "@/lib/entities/Results";

class AddedAsset {
    name = ''
    latLng = new LatLng()
    attributes = {}
    marker = null

    constructor(source) {
        if (source != null) {
            this.assign(source)
        }
    }

    assign(o) {
        this.name = o.name
        this.latLng = LatLng.fromObject(o.latLng)
        this.attributes = {...o.attributes}
        if (this.marker != null) {
            this.marker.setLatLng({lat: this.latLng.lat, lng: this.latLng.lng})
        }
    }

    static fromObject(o) {
        let result = new AddedAsset()
        result.assign(o)
        return result
    }
}

export default AddedAsset