<template>
    <span class="expand-toggle" @click="expandToggle_Clicked">
        <img v-if="!isOpen" src="@/assets/images/ExpandToggle_Closed@2x.png" width="22" height="22" alt="Expand"/>
        <img v-if="isOpen" src="@/assets/images/ExpandToggle_Open@2x.png" width="22" height="22" alt="Collapse"/>
        {{ text }}
    </span>
</template>

<script>
export default {
    name: 'ExpandToggle',
    data() {
        return {
            isOpen: false
        }
    },
    props: {
        open: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: '',
            required: false
        }
    },
    methods: {
        expandToggle_Clicked() {            
            this.toggle()
        },
        toggle() {
            this.isOpen = !this.isOpen
            this.$emit('on-change', this.isOpen)
        }
    },
    watch: {
        open(val) {
            this.isOpen = val
        }
    }
}
</script>

<style lang="scss">

@import '@/assets/std-theme.scss';

.expand-toggle {
    cursor: default;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.expand-toggle:hover {
    color: $dark-gray;
}
</style>