class NewAsset {
    name = ''
    latLng = null
    attributes = {}
}

class NewResultSet {
    projectId = ''
    name = ''
    variables = []
    curves = []
    percentiles = []
    assets = []

    static fromObject(o) {
        let r = new NewResultSet()
        r.projectId = o.projectId
        r.name = o.name
        r.variables = Array.from(o.variables)
        r.curves = Array.from(o.curves)
        r.percentiles = Array.from(o.percentiles)
        r.assets = Array.from(o.assets)
        return r
    }
}

export {
    NewResultSet,
    NewAsset
}