<template>
  <div ref="mapElement"></div>
</template>

<script>
import Leaflet from 'leaflet'
import config from "@/sys/config";
import LeafletUtils from '@/lib/utils/LeafletUtils.js'
import TileLayers from "@/lib/utils/TileLayers";

export default {
  name: 'LeafletWrapper',
  data() {
    return {
      /** @type Leaflet */ map: null,
      layersControl: null,
      rasterLayer: null,
      basemap: null,
      currentRasterLayerOpacity: config.DEFAULT_LAYER_OPACITY,
      layers: [],
      overlays: [],
      defaultIcon: Leaflet.icon({
        //iconUrl: '/images/marker-icon-2x.png',
        iconUrl: '/images/pins/pin.svg',
        iconSize: [26, 42],
        iconAnchor: [13, 42]
      }),
      selIcon: Leaflet.icon({
        //iconUrl: '/images/marker-icon-2x.png',
        iconUrl: '/images/pins/sel-pin.svg',
        iconSize: [26, 42],
        iconAnchor: [13, 42]
      })
    }
  },
  props: {
    overlayOpacity: {
      required: false,
      default: '100'
    },
    /**
     * @description When true, longitude values reported by the click handler as always >= -180 <= 360. If the user clicks
     * a point outside this range, the map will be panned to a corrected location
     */
    autoFixClickedPoints: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  watch: {
    overlayOpacity: {
      handler(opacity) {
        //logger.logFunc('LeafletWrapper', 'overlayOpacity_watch', opacity)
        if (typeof opacity !== 'number')
          opacity = Number.parseInt(opacity) || 100
        this.overlays.forEach(o => o.setOpacity(opacity / 100))
      }
    }
  },
  methods: {
    getMap() { return this.map },
    resetAllMarkerIcons(markers) {
      if (!Array.isArray(markers))
        return

      Object.keys(markers).forEach(key => {
        markers[key].setIcon(this.defaultIcon)
      })
    },
    useSelectedMarkerIcon(marker) {
      if (typeof marker != "object")
        return

      marker.setIcon(this.selIcon)
    },
    refresh() {
      setTimeout(() => {
        this.map.invalidateSize(true)
      }, 1)
    },
    addMarker(lat, lng) {
      let options = { icon: this.defaultIcon }
      let marker = Leaflet.marker([lat, lng], options)
      marker.addTo(this.map)
      return marker
    },
    setZoomControlPosition(pos) {
      //logger.logFunc('LeafletWrapper', 'setZoomControlPosition', { pos: pos })
      this.map.zoomControl.setPosition(pos)
    },
    setLayersControlPosition(pos) {
      //logger.logFunc('LeafletWrapper', 'setLayersControlPosition', { pos: pos })
      this.layersControl.setPosition(pos)
    },
    panTo(latlng) {
      //logger.logFunc('LeafletWrapper', 'panTo', { latlon: latlng })

      if (this.map != null)
        this.map.panTo(latlng)
    },
    flyTo(latlng, zoom) {
      if (this.map != null)
        this.map.flyTo(latlng, zoom)
    },
    setZoom(level) {
      //logger.logFunc('LeafletWrapper', 'setZoom', { level })

      if (this.map != null)
        this.map.setZoom(level)
    },
    setRasterLayerOpacity(value) {
      //logger.logFunc('LeafletWrapper', 'setRasterLayerOpacity', { value: value })

      this.currentRasterLayerOpacity = value
      if (this.rasterLayer != null)
        this.rasterLayer.setOpacity(value)
    },
    setRasterLayer(url, bounds) {
      //logger.logFunc('LeafletWrapper', 'setRasterLayer', {url: url, bounds: bounds})

      if (typeof bounds == 'object') {
        bounds = LeafletUtils.getBoundsFromObject(bounds)
        //logger.log('Converted object to bounds array', bounds)
      }

      if (this.rasterLayer == null) {
        let layer = Leaflet.imageOverlay(url, bounds, { opacity: this.currentRasterLayerOpacity, className: 'raster-overlay-layer' })
        layer.addTo(this.map)
        this.rasterLayer = layer
      }
      else {
        this.rasterLayer.setUrl(url)
        this.rasterLayer.setBounds(bounds)
      }
    },
    addGeoJsonLayer(id, data, options, refObj) {
      //logger.logFunc('LeafletWrapper', 'addGeoJsonLayer', { id: id, data: data, options: options, refObj: refObj })

      let layer = Leaflet.geoJSON(data, options)
      layer.addTo(this.map)
      let layerItem = {id: id, layer: layer, refObj: refObj, type: 'geojson'}
      this.layers.push(layerItem)
    },
    getLayers() {
      return this.layers
    },
    findLayer(id, type) {
      //logger.logFunc('LeafletWrapper', 'findLayer', { id: id, type: type })

      return this.layers.find((layer) => {
        return layer.id === id && (type === undefined || layer.type === type)
      })
    },
    removeLayer(layerItem) {
      //logger.logFunc('LeafletWrapper', 'removeLayer', { layerItem: layerItem })

      let index = this.layers.indexOf(layerItem)
      if (index !== -1) {
        this.layers.splice(index, 1)
        layerItem.layer.remove()
      }
      layerItem.layer.remove()
    },
    removeAllLayers() {
      //logger.logFunc('LeafletWrapper', 'removeAllLayers')

      let layers = this.layers.slice()
      let errors = 0
      layers.forEach((layerItem) => {
        try { this.removeLayer(layerItem) }
        catch { errors = errors + 1 }
      })
      return errors === 0
    },
    addOverlay(url, bounds, type) {
      if (type === 'png' || type === undefined) {
        if (url == null || url === '' || bounds == null)
          return

        let llCorner = Leaflet.latLng(bounds[0][0], bounds[0][1])
        let trCorner = Leaflet.latLng(bounds[1][0], bounds[1][1])

        let layerBounds = Leaflet.latLngBounds(llCorner, trCorner)

        let opacity = this.overlayOpacity
        if (typeof opacity !== 'number')
          opacity = Number.parseInt(opacity)

        let options = {
          opacity: opacity / 100
        }

        let layer = Leaflet.imageOverlay(url, layerBounds, options)
        layer.addTo(this.map)

        this.overlays.push(layer)
      }
      else if (type === 'shp') {
        let shapefile = Leaflet.shapefile(url)
        shapefile.addTo(this.map)
      }
    },
    clearOverlays() {
      this.overlays.forEach(o => o.remove())
      this.overlays = []
    },
    setBasemapUrl(url) { this.basemap.setUrl(url)  },
    lngNeedsFixing(lng) {
      return this.autoFixClickedPoints && (lng < -180 || lng > 360)
    },
    getFixedLng(lng) {
      if (lng < -180) {
        while(lng < -180)
          lng += 360.0
      }
      else if (lng > 360) {
        while(lng > 360)
          lng -= 360.0
      }
      return lng
    }
  },
  mounted() {
    //logger.logFunc('LeafletWrapper', 'mounted')

    let layers = []
    let baseMaps = {}

    TileLayers.forEach(x => {
      let layer = Leaflet.tileLayer(x.url, {
        attribution: config.MAPBOX_BASEMAP_INFO,
        maxZoom: 18,
        id: x.id,
        accessToken: config.MAPBOX_ACCESS_TOKEN,
      })
      layers.push(layer)
      baseMaps[x.name] = layer
    })

    this.map = Leaflet.map(this.$refs.mapElement, {
      center: config.DEFAULT_MAP_CENTER,
      zoom: config.DEFAULT_MAP_ZOOM,
    })

    if (layers.length > 0)
      layers[0].addTo(this.map)

    this.setZoomControlPosition('bottomleft')

    this.layersControl = Leaflet.control.layers(baseMaps)
    this.layersControl.addTo(this.map)
    this.setLayersControlPosition('bottomleft')

    this.map.on('click', (mouseEvent) => {
      let lat = mouseEvent.latlng.lat, lng = mouseEvent.latlng.lng
      if (this.lngNeedsFixing(lng)) {
        let oldLng = lng
        lng = this.getFixedLng(lng)
        this.panTo([lat, lng])
      }
      this.$emit('map-clicked', {
        sender: this,
        map: this.map,
        lat, lng
      })
    })
  }
}
</script>

<style scoped>

</style>