export class DatasetSourceItem {
    region = ''
    regionName = ''
    variable = ''
    rawDataSource = ''
    source = ''
    description = ''
    reference = ''
    infoSource = ''

    static from(obj) {
        const result = new DatasetSourceItem()

        result.region = obj.region
        result.regionName = obj.regionName
        result.variable = obj.variable
        result.rawDataSource = obj.rawDataSource
        result.source = obj.source
        result.description = obj.description
        result.reference = obj.reference
        result.infoSource = obj.infoSource

        return result
    }
}

export class DatasetSourceItemData {
    dict = {}

    static createKey(region, source, varId)
    {
        return `${region}/${source}/${varId}`
    }

    static from(obj) {
        if (obj == null)
            return new DatasetSourceItemData();

        const dict = {}

        for(const key in obj)
        {
            const o = obj[key]
            if (o == null)
                continue

            const item = DatasetSourceItem.from(o)
            if (item == null)
                continue
            dict[key] = item
        }

        const result = new DatasetSourceItemData()
        result.dict = dict
        return result
    }

    contains(key)
    {
        return this.dict[key] !== undefined
    }

    find(region, source, varId)
    {
        const key = DatasetSourceItemData.createKey(region, source, varId)
        if (!this.contains(key))
            return null
        return this.dict[key]
    }
}