<template>
  <div class="import-assets-wizard dialog">
    <div class="dialog-background"/>

    <div class="dialog-content general-panel">
      <div class="window-controls">
        <a href="#" @click.prevent="emit('cancel')">&times;</a>
      </div>

      <div class="wizard-pages">
        <div class="import-text-page wizard-page" v-if="page === PageIDs.importTextPage">
          <h2>Specify assets to import</h2>
          <p>Assets can be imported from comma or tab delimited text data. Paste data in the text below, or press Upload File to upload a text file to import from your device.</p>
          <textarea class="stretch" v-model="textToImport"></textarea>
          <p class="mt-2">Click Next to select columns.</p>
          <div class="mt-2">
            <button class="btn btn-secondary" @click="page = PageIDs.importFilePage">Upload File...</button>
          </div>
        </div>
        <div class="import-file-page wizard-page" v-if="page === PageIDs.importFilePage">
          <h2>Choose a file to import</h2>
          <p>Specify a file to import below.</p>
          <div class="custom-file">
            <input type="file" class="custom-file-input" id="file-to-import-input" v-on:change="importFile_Changed"/>
            <label class="custom-file-label" for="file-to-import-input">Browse...</label>
          </div>
          <template v-if="importFile != null">
            <p v-if="importFile != null" class="mt-2">
              File to import: {{ importFile.name }}
            </p>
            <p>
              Click Next to select columns.
            </p>
          </template>
        </div>
        <div class="select-columns-page wizard-page" v-if="page === PageIDs.selectColumnsPage">
          <h2>Select columns</h2>
          <div class="base-list-box stretch">
            <div class="list-box-scroller">
              <table class="table">
                <thead>
                <tr>
                  <th>Column name</th>
                  <th>Column type</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="col in importDataSource.cols" :key="col.index">
                  <td>{{ col.name }}</td>
                  <td><column-type-list :initial-mapping="col.mapping" @change="m => col.mapping = m"></column-type-list></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" v-model="showPreview" id="show-preview" /><label class="form-check-label" for="show-preview"> Preview data before adding assets</label>
          </div>
        </div>
        <div class="summary-page wizard-page" v-if="page === PageIDs.summaryPage">
          <template v-if="showPreview">
            <h2>Review assets to import</h2>
            <p>The following assets will be added to the map. Click Finish to add assets to the map.</p>

            <div class="base-list-box stretch">
              <div class="list-box-scroller">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Attributes</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="importDataSource != null">
                    <tr v-for="(dataRow, index) in importDataSource.dataRows" v-bind:key="index">
                      <td>{{ dataRow.name }}</td>
                      <td>{{ dataRow.lat.toFixed(settingsStore.latLngDp) }}</td>
                      <td>{{ dataRow.lng.toFixed(settingsStore.latLngDp) }}</td>
                      <td>
                        <table class="table table-sm">
                          <tr v-for="(attr, index) in Object.keys(dataRow.attributes)" v-bind:key="index">
                            <td>{{ attr }}</td>
                            <td>{{ dataRow.attributes[attr] }}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-else>
            <h2>Click Finish to add assets</h2>
            <p>{{ importDataSource._dataRows.length }} assets will be added.</p>
          </template>
        </div>
      </div>

      <div class="button-row dialog-buttons">
        <button class="btn btn-secondary" v-on:click="back_Click" v-if="PageIDs !== PageIDs.importTextPage">&lt; Back</button>
        <button class="btn btn-primary" v-on:click="next_Click" v-if="page !== PageIDs.summaryPage">Next &gt;</button>
        <button class="btn btn-primary" v-on:click="finish_Click" v-if="page === PageIDs.summaryPage">Finish</button>
        <button class="btn btn-secondary" v-on:click="emit('cancel')">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, ref, defineProps} from "vue";
import ImportDataSource from "@/lib/importing/ImportDataSource";
import ColumnTypeList from './ColumnTypeList'
import {useViewSettingsStore} from "@/stores/settingsStore";

const settingsStore = useViewSettingsStore()

const PageIDs = {
  importTextPage: 'import-text-page',
  importFilePage: 'import-file-page',
  selectColumnsPage: 'select-columns-page',
  summaryPage: 'summary-page'
}

const importOptions = ref({ text: '', fileText: '', type: 'text' })
const page = ref(PageIDs.importTextPage)
const showPreview = ref(true)
const importDataSource = ref(/** @type ImportDataSource */ null)
const importFile = ref(/** @type File*/ null)
const textToImport = ref('Paste CSV or TSV data here')

const props = defineProps({
  checkLocationCallback: {
    type: Function,
    required: false,
    default: null
  }
})

function doCheckLocationCallback(lat, lng) {
  if (typeof(props.checkLocationCallback) !== 'function')
    return true

  return props.checkLocationCallback(lat, lng)
}

const emit = defineEmits(['cancel', 'add'])

function doNextFromTextPage() {
  try {
    const importer = new ImportDataSource(textToImport.value)
    importDataSource.value = importer
    page.value = PageIDs.selectColumnsPage
  }
  catch(error) {
    alert(error.message)
  }
}

/** @param {Function} onRead **/
async function readImportFileAsync() {
  if (importFile.value == null) {
    throw new Error('No file selected')
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function(e) {
      resolve(e.target.result)
    }
    reader.onerror = function(e) {
      reject('Failed to load file')
    }
    reader.readAsText(importFile.value)
  })
}

async function  doNextFromFilePage() {
  try {
    const value = await readImportFileAsync()
    const importer = new ImportDataSource(value)
    importDataSource.value = importer
    page.value = PageIDs.selectColumnsPage
  }
  catch(error) {
    alert(error.message)
  }
}

function doNextFromSelectColumnsPage() {
  importDataSource.value.invalidateDataRows()
  page.value = PageIDs.summaryPage
}

function back_Click() {
  switch(page.value) {
    case PageIDs.importFilePage:
      page.value = PageIDs.importTextPage
      break
    case PageIDs.selectColumnsPage:
      page.value = PageIDs.importTextPage
      break
    case PageIDs.summaryPage:
      page.value = PageIDs.selectColumnsPage
      break
  }
}
async function next_Click() {
  switch(page.value) {
    case PageIDs.importTextPage:
      doNextFromTextPage()
      break
    case PageIDs.importFilePage:
      await doNextFromFilePage()
      break
    case PageIDs.selectColumnsPage:
      doNextFromSelectColumnsPage()
      break
  }
}
function finish_Click() {
  const assetsToImport = importDataSource.value.dataRows
  assetsToImport.forEach(a => emit('add', a))
  emit('cancel')
}
function importFile_Changed(e) {
  importFile.value = null
  const files = e.target.files
  if (files.constructor.name === FileList.name && files.length === 1) {
    importFile.value = files[0]
  }
}
</script>

<style scoped>

.wizard-pages {
  flex: 1;
  display: flex;
}

.wizard-page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.wizard-page .stretch {
  flex: 1;
}

.dialog-content {
  height: 80%;
  width: 80%;

  display: flex;
  flex-direction: column;
}
</style>