const StrUtils = {
    /** @param {string} s */
    isNullOrEmpty(s) {
        return (!s || s === '')
    },

    /** @param {string} s */
    isWhiteSpaceChar(c) {
        if (!c || c.length !== 1)
            return false

        return c === ' ' || c === '\t' || c === '\n' || c === '\r'
    },

    /** @param {string} s */
    isNullOrWhiteSpace(s) {
        return !s || Array.from(s).every(c => StrUtils.isWhiteSpaceChar(c))
    },

    /**
     * @param {String} a
     * @param {String} b
     * @param {Boolean} ignoreCase
     */
    compare(a, b, ignoreCase = true) {
        if (ignoreCase) {
            a = a.toLowerCase()
            b = b.toLowerCase()
        }

        if (a < b) return -1
        if (a > b) return 1
        return 0
    },

    /**
     * @description Escape a string if is contains the specified sep. Escapes using CSV escaping format.
     * @param {string} s
     * @param {string} sep
     */
    csvEscape(s, sep) {
        if (typeof s !== 'string')
            return s
        if (!s.includes(sep))
            return s
        let result = '"'
        for(let i=0; i < s.length; i++) {
            let c = s[i]
            if (c === '"') result += '"'
            result += c
        }
        result += '"'
        return result
    },

    /**
     * @description Combines an array of values into a char seperated line, escaping as required
     * @param {string[]} arr
     * @param {string} sep
     */
    csvCombine(arr, sep) {
        return arr.map(s => StrUtils.csvEscape(s, sep)).join(sep)
    }
}

export default StrUtils