<template>
  <div class="asset-selector" @click="showSearchResults = false">
    <div class="content">
      <div id="asset-list">
        <div class="asset-list-box base-list-box">
          <div class="list-box-scroller">
            <div class="item" v-for="(asset, index) in assets" :key="index">
              <div class="details">
                <a href="#" class="asset-item-link" @click.prevent="panToAsset(asset)">
                <h5>{{ asset.name }}</h5>

                  Latitude: {{ asset.latLng.lat}}<br/>
                  Longitude: {{asset.latLng.lng}}
                </a>

              </div>
              <div class="asset-tools">
                <a href="#" class="edit-asset" @click.prevent="editAsset(asset)"><img src="@/assets/images/toolbar/Edit.png" alt="Edit" width="18"/></a>
                <a href="#" class="delete-asset" @click.prevent="deleteAsset(asset)"><img src="@/assets/images/toolbar/Delete.png" alt="Delete" width="18"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map-panel">
        <div class="toolbar">
          <div class="button-strip left-buttons">
            <button class="btn btn-secondary btn-sm strip" @click="addAsset_Click">Add</button>
            <button class="btn btn-secondary btn-sm strip" @click="showImportWizard = true">Import</button>
            <button class="btn btn-secondary btn-sm strip" @click="showOpenAssetSet = true">Load set</button>
            <button class="btn btn-secondary btn-sm strip" @click="showSaveAssetSet = true">Save set</button>
            <button class="btn btn-secondary btn-sm strip" @click="clear_Clicked">Clear</button>
          </div>
          <div class="button-strip right-buttons">
            <div class="search-tools" @click="searchTools_Clicked">
              <form class="no-border">
                <input type="text" placeholder="Address search" v-model="searchText" @submit.prevent="search_Click"/>
                <button class="btn btn-secondary btn-sm" style="margin-left: 0.4rem" @click.prevent="search_Click">Search</button>
                <div class="search-results" v-if="showSearchResults">
                  <h5>Search results</h5>
                  <div class="search-results-list base-list-box">
                    <div class="list-box-scroller">
                      <p v-if="searchResults.length === 0">
                        No search results found
                      </p>
                      <div class="item" v-for="(result, index) in searchResults" :key="index">
                        <a href="#" class="address" @click.prevent="searchResult_Clicked(result)">
                          {{ result.text }}
                        </a>
                        <a href="#" @click.prevent="showAddAssetDialog(result.text, {lat: result.lat, lng: result.lng})">Add</a>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <leaflet-wrapper class="map" ref="map" @map-clicked="map_Clicked" />
      </div>
    </div>
    <add-asset-dialog v-if="showAddAsset" @add="doAddAsset" @edit="doEditAsset" @cancel="showAddAsset = false" :check-location-callback="checkAssetLocation" :initial-values="addAssetValues"></add-asset-dialog>
    <import-assets-wizard-dialog v-if="showImportWizard" @cancel="showImportWizard = false" @add="importAsset" :check-location-callback="checkAssetLocation" />
    <open-save-dialog v-if="showOpenAssetSet" :view-model="openAssetSetViewModel" @cancel="showOpenAssetSet = false" @loaded="openAssetSet_Loaded"></open-save-dialog>
    <open-save-dialog v-if="showSaveAssetSet" :view-model="saveAssetSetViewModel" :save-data="createAssetSet" @cancel="showSaveAssetSet = false" @saved="showSaveAssetSet = false"></open-save-dialog>
  </div>
</template>

<script setup>
import LeafletWrapper from "@/components/Mapping/LeafletWrapper";
import {ref, defineProps, watch, onMounted, toRaw, defineExpose} from "vue";
import AddAssetDialog from "@/components/DataPage/Builder/AddAssetDialog";
import ImportAssetsWizardDialog from "@/components/DataPage/Builder/Importing/ImportAssetsWizardDialog";
import AddedAsset from "@/lib/entities/AddedAsset";
import {LatLng} from "@/lib/entities/Results";
import OpenSaveDialog from "@/dialogs/OpenSaveDialog";
import { OpenAssetSetViewModel, SaveAssetSetViewModel } from "@/lib/viewModels/assetSetsViewModels";
import {AssetSet, AssetSetItem} from "@/lib/entities/AssetSet";
import {lookupAddressesAsync} from "@/api/lookupApi";
import {useUserStore} from "@/stores/userStore";
import Leaflet from 'leaflet'

const showAddAsset = ref(false)
const showImportWizard = ref(false)
const showOpenAssetSet = ref(false)
const showSaveAssetSet = ref(false)
const searchResults = ref([])
const showSearchResults = ref(false)

const map = ref(null)
const assets = ref([])
const searchText = ref('')

const openAssetSetViewModel = ref(new OpenAssetSetViewModel())
const saveAssetSetViewModel = ref(new SaveAssetSetViewModel())

const addAssetValues = ref({
  assetName: '',
  lat: 0,
  lng: 0,
  attributes: [],
  isEditing: false,
  editingAsset: null
})

const props = defineProps({
  showing: Boolean,
  showRegionBounds: Boolean,
  regionBounds: {
    type: Array,
    required: false,
    default: null
  }
})

/**
 * This function returns true if an asset can be added based on a set of conditions, e.g. if the asset falls within
 * a fixed region in trial mode
 */
function checkAssetLocation(lat, lng) {
  // make sure lat,lng are within acceptable range
  if (lat > 90 || lat < -90 || lng < -180 || lng > 360)
    return false

  return true
}

function isValidBoundsArray(arr) { return Array.isArray(arr) && arr.length === 4 }

function buildPolygonArray(bounds) {
  if (!isValidBoundsArray(bounds))
    return null

  const left = bounds[0]
  const top = bounds[1]
  const right = bounds[2]
  const bottom = bounds[3]

  const bgLeft = -360*3
  const bgRight = 360*4
  let background = [[90, bgLeft], [90, bgRight], [-90, bgRight], [-90, bgLeft]]

  let arr
  if (right > 180)
    arr = [
        // [[180, 0], [180, 360], [-180, 360], [-180, 0]],
        background,
        [[top, left], [top, right], [bottom, right], [bottom, left]] ]
  else
    arr = [
        // [[180, -180], [180, 180], [-180, 180], [-180, -180]],
        background,
        [[top, left], [top, right], [bottom, right], [bottom, left]] ]

  return arr
}

function setupRegionBounds() {
  const bounds = props.regionBounds
  const mapView = map.value.getMap()
  const polyArray = buildPolygonArray(bounds)
  if (polyArray == null)
    return
  const poly = Leaflet.polygon(polyArray, { color: '#000', weight: 1, fill: true, fillColor: '#ccc', fillOpacity: 0.5 })
  poly.addTo(mapView)
}

function panToRegionBoundsCenter() {
  const mapView = map.value.getMap()
  const bounds = props.regionBounds

  if (!isValidBoundsArray(bounds))
    return

  function cn(a, b) { return a + ((b-a) / 2) }

  const lngCenter = cn(bounds[0], bounds[2])
  const latCenter = cn(bounds[3], bounds[1])

  mapView.panTo([latCenter, lngCenter])
}

onMounted(() => {
  if (props.showRegionBounds) {
    setupRegionBounds()
    panToRegionBoundsCenter()
  }
})

function createAssetSet() {
  let assetSet = new AssetSet()
  assetSet.items = assets.value.map(a => AssetSetItem.fromObject(a))
  return assetSet
}

function searchTools_Clicked(e) {
  e.stopPropagation()
  if (searchResults.value.length > 0)
    showSearchResults.value = true
}

function invalidateMap() {
  map.value.refresh()
}

function panToAsset(asset) {
  const latLng = [asset.latLng.lat, asset.latLng.lng]
  map.value.panTo(latLng)
}

function openAssetSet_Loaded(assetSet) {
  showOpenAssetSet.value = false

  clear_Clicked()

  assetSet.items.forEach(i => {
    let asset = new AddedAsset()
    asset.assign(i)
    doAddAsset(asset)
  })
}

function showAddAssetDialog(name = '', latLng = { lat: 0, lng: 0 }, attribs = []) {
  addAssetValues.value.assetName = name
  addAssetValues.value.lat = latLng.lat
  addAssetValues.value.lng = latLng.lng
  addAssetValues.value.attributes = attribs
  addAssetValues.value.isEditing = false
  addAssetValues.value.editingAsset = null
  showAddAsset.value = true
}

function addAsset_Click() {
  showAddAssetDialog()
}

function map_Clicked(data) {
  showAddAssetDialog(`Asset at ${data.lat.toFixed(3)}, ${data.lng.toFixed(3)}`, {
    lat: data.lat.toFixed(6),
    lng: data.lng.toFixed(6)
  })
}

function editAsset(asset) {
  addAssetValues.value.assetName = toRaw(asset.name)
  addAssetValues.value.lat = toRaw(asset.latLng.lat)
  addAssetValues.value.lng = toRaw(asset.latLng.lng)
  addAssetValues.value.attributes = Object.keys(toRaw(asset.attributes)).map(key => {
    return {
      key: key,
      value: asset.attributes[key]
    }
  })
  addAssetValues.value.isEditing = true
  addAssetValues.value.editingAsset = asset
  showAddAsset.value = true
}

function marker_Clicked(event) {
  map.value.panTo(event.latlng)
  const asset = assets.value.find(a => {
    const aMarker = toRaw(a.marker)
    return aMarker === event.target
  })
  if (asset == null)
    return
  editAsset(asset)
}

function importAsset(assetData) {
  const asset = new AddedAsset()
  asset.name = assetData.name
  asset.latLng = LatLng.fromObject(assetData)
  asset.attributes = assetData.attributes
  return doAddAsset(asset)
}

function doAddAsset(asset) {
  if (!checkAssetLocation(asset.latLng.lat, asset.latLng.lng))
    return false

  const marker = map.value.addMarker(asset.latLng.lat, asset.latLng.lng)
  marker.on('click', marker_Clicked)
  asset.marker = marker
  assets.value.push(asset)
  showAddAsset.value = false

  return true
}

async function search_Click() {
  const userStore = useUserStore()
  const results = await lookupAddressesAsync(userStore.token, searchText.value)
  searchResults.value = results
  showSearchResults.value = true
}

function searchResult_Clicked(result) {
  map.value.flyTo([result.lat, result.lng], 16)
  showSearchResults.value = true
}

function doEditAsset(asset) {
  addAssetValues.value.editingAsset.assign(asset)
  showAddAsset.value = false
}

function deleteAsset(asset) {
  asset.marker.remove()
  assets.value = assets.value.filter(a => a !== asset)
}

function clear_Clicked() {
  const assetsToDelete = Array.from(assets.value)
  assetsToDelete.forEach(a => deleteAsset(a))
}

function getData() {
  return toRaw(assets.value)
}

function setData(assets) {
  assets.forEach(asset => doAddAsset(asset))
}

defineExpose({getData, setData})

watch(() => props.showing, (newVal) => {
  if (newVal === true) {
    invalidateMap()
  }
})

</script>

<style scoped>
.asset-selector {
  display: flex;
  flex-direction: column;
}

.map {
  flex: 1;
  border: 1px solid #aaa;
  border-radius: 0.5rem;
}

.toolbar {
  margin-bottom: 0.4rem;
  display: flex;
}

.toolbar .btn {
  border-radius: 0;
}

.toolbar .first {
  border-bottom-left-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}

.toolbar .last {
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.content {
  flex: 1;
  display: flex;
}

.left-buttons {
}

.right-buttons {
  display: flex;
  flex: 1;
  justify-content: right;
}

#asset-list {
  width: 300px;
  display: flex;
  margin-right: 1rem;
}

.asset-list-box {
  flex: 1;
  border-radius: 0.5rem;
}

.asset-list-box .item {
  position: relative;
  border-radius: 0.5rem;
}

.item-controls {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}

.asset-list-box .item {
  display: flex;
}

.asset-list-box .details {
  flex: 1;
}

.asset-tools .delete-asset {
  color: red !important;
  text-decoration: none;
  font-weight: bold;
  font-size: 14pt;
}

.asset-tools .edit-asset {
  color: black !important;
  text-decoration: none;
  font-weight: bold;
  font-size: 10pt;
}

.asset-item-link {
  text-decoration: none;
  color: black !important;
}

.map-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.search-tools,
.search-tools form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-results {
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  height: 400px;
  background-color: white;
  z-index: 500;
  padding: 1rem;
  border: 1px solid #aaa;
  display: flex;
  flex-direction: column;
}

.search-results-list {
  flex: 1;
}

.search-results-list a {
  border: none;
}

.search-results-list .item {
  display: flex;
}

.search-results-list .item .address {
  flex: 1;
}

.btn.strip {
  border-radius: 0 !important;
}

.btn.strip:first-child {
  border-bottom-left-radius: 0.4rem !important;
  border-top-left-radius: 0.4rem !important;
}

.btn.strip:last-child {
  border-bottom-right-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}



</style>