import config from "@/sys/config";

class BaseLogger {
    info(s) {}
    error(s) {}
    warn(s) {}
}

class NullLogger extends BaseLogger {

}

class ConsoleLogger extends BaseLogger {
    info(s) { console.info(...arguments) }
    error(s) { console.error(...arguments) }
    warn(s) { console.warn(...arguments) }
}

let logger = new NullLogger()
if (config.IS_DEBUG)
    logger = new ConsoleLogger()

export default logger