<template>
  <div class="score-views">
    <score-view class="score-view" v-for="(year, index) in years" :key="index" :year="year" :score="tableValue(year)"/>
  </div>
</template>

<script setup>
import {defineProps, ref} from "vue";
import {Table} from "@/lib/entities/Results";
import ScoreView from './ScoreView'

const props = defineProps({
  table: Table
})

function tableValue(year) {
  return props.table.rows.find(r => r.year === year).values[0]
}

const years = ref([2005, 2030, 2050, 2070, 2100])

</script>

<style scoped>
.score-views {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.score-view {
  margin: 1rem;
}
</style>