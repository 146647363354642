<template>
  <div class="var-selector">
    <h6>Select variables:</h6>

    <div class="var-selector-root">
      <div class="var-categories">
        <a href="#" :class="{'sel-category': selCategory === null}" @click.prevent="selCategory = null">
          Show all
        </a>
        <a href="#" :class="{'sel-category': selCategory === catKey}" v-for="(cat, catKey, index) in categories" :key="index" @click.prevent="selCategory = catKey">
          {{ cat.name }}
        </a>
      </div>

      <div class="var-selector-list-box base-list-box">
        <div class="list-box-scroller">
          <div class="item" v-for="(modelItem, index) in filteredModel" :key="index">
            <label><input type="checkbox" v-model="modelItem.checked"> {{ varIdToVarName(modelItem.variable.id) }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="button-row">
      <div class="button-strip left-buttons" v-if="allowSaving">
        <button class="btn btn-secondary" @click="emit('load-layer-group')">Load set</button>
        <button class="btn btn-secondary" @click="emit('save-layer-group')">Save set</button>
      </div>

      <div class="button-strip right-buttons">
        <button class="btn btn-secondary" @click="selectAll(true)">Select all</button>
        <button class="btn btn-secondary" @click="selectAll(false)">Select none</button>
      </div>
    </div>
  </div>


</template>

<script setup>
import {useVarItemsStore} from "@/stores/varItemStore";
import {reactive, defineEmits, defineExpose, ref, computed, defineProps} from "vue";
import {varIdToVarName} from "@/lib/utils/varNameUtils";

const varItemStore = useVarItemsStore()

const emit = defineEmits(['load-layer-group', 'save-layer-group'])

function createModel() {
  return varItemStore.index.variables.map(v => {
    return {
      checked: false,
      variable: v
    }
  })
}

const categories = varItemStore.categories.categories
const categoryMappings = varItemStore.categories.mappings
const model = reactive(createModel())
const selCategory = ref(null)
const props = defineProps({
  allowSaving: {
    type: Boolean,
    required: false,
    default: true
  }
})

const filteredModel = computed(() => {
  if (selCategory.value == null)
    return model

  const mappings = categoryMappings[selCategory.value]
  if (mappings == null)
    return model

  return model.filter(item => mappings.some(mapping => item.variable.id.includes(mapping)))
})

function selectVariables(layerIds) {
  model.forEach(v => {
    v.checked = layerIds.includes(v.variable.id)
  })
}

function doSelectAll(model, checked) {
  model.forEach((item) => {
    item.checked = checked
  })
}

function selectAll(checked) {
  doSelectAll(filteredModel.value, checked)
}

function getData() {
  return model.filter(v => v.checked).map(v => v.variable.id)
}

function setData(variables) {
  model.forEach(item => {
    item.checked = variables.includes(item.variable.id)
  })
}

defineExpose({
  getData,
  setData,
  selectVariables
})

</script>

<style scoped>
.var-selector-list-box {
  flex: 1;
}

.var-selector-root {
  display: flex;
  flex-direction: row;
  position: relative;
  flex: 1;
}

.var-categories {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 90px;
}

.var-categories a {
  text-decoration: none;
  margin-bottom: 0.5rem;
  color: #777 !important;
  font-weight: normal;
}

.var-categories a.sel-category {
  color: #000 !important;
  font-weight: bold;
}

.var-selector {
  display: flex;
  flex-direction: column;
  position: relative;
}

.button-row {
  display: flex;
}

.button-strip {
  flex: 1;
  display: flex;
}

.left-buttons {
  justify-content: left;
}

.right-buttons {
  justify-content: right;
}
</style>