import config from "@/sys/config";
import {getBinaryAsync, getJsonAsync, statusCodes} from "@/api/apiUtils";
import pako from "pako/dist/pako.esm.mjs";

import Results, {AssetResults, ResultsInfo} from "@/lib/entities/Results";

function inflateBuffToString(buff) {
    const arr = new Uint8Array(buff)
    const str = pako.inflateRaw(arr, { to: 'string' })
    const json = JSON.parse(str)
    return json
}

async function getMultiFileResultInfoAsync(token, refId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')

    const url = config.SERVICE_URL + 'data/results?refId=' + refId
    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error getting results')

    const obj = await resp.json()
    return ResultsInfo.fromObject(obj)
}

async function getMultiFileAsetResultsAsync(token, refId, assetId) {
    if (token == null) throw new Error('token is null')
    if (refId == null) throw new Error('refId is null')
    if (assetId == null) throw new Error('assetId is null')

    const url = config.SERVICE_URL + `data/results/${refId}/${assetId}`
    const resp = await getBinaryAsync(url, token)
    if (resp.status !== 200)
        throw new Error('Failed to read result set')
    const arrayBuff = await resp.arrayBuffer()
    const json = inflateBuffToString(arrayBuff)
    return AssetResults.fromObject(json)
}

export { getMultiFileAsetResultsAsync, getMultiFileResultInfoAsync }