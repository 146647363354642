import config from "@/sys/config";
import {getJsonAsync} from "@/api/apiUtils";
import HistoryItem from "@/lib/entities/HistoryItem";

async function getDataHistoryAsync(token) {
    const url = `${config.SERVICE_URL}data/history`
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200)
        throw new Error('Failed to download history')
    const data = await resp.json()
    return HistoryItem.fromArray(data)
}

export {getDataHistoryAsync}