<template>
  <div class="table-view">
    <div class="buttons">
      <a href="#" class="table-button" :class="{active: activeView === 'table'}" @click.prevent="activeView = 'table'" data-toggle="tooltip" data-placement="right" title="View Table" ref="buttonViewTable">
        <img src="@/assets/images/utils/Table.svg"/>
      </a>
      <a href="#" class="table-button" :class="{active: activeView === 'chart'}" @click.prevent="activeView = 'chart'" data-toggle="tooltip" data-placement="right" title="View Chart" ref="buttonViewChart">
        <img src="@/assets/images/utils/Chart.svg"/>
      </a>
      <a href="#" class="table-button" :class="{active: activeView === 'split'}" @click.prevent="activeView = 'split'" data-toggle="tooltip" data-placement="right" title="View Table and Chart" ref="buttonViewAndChart">
        <img src="@/assets/images/utils/Split.svg"/>
      </a>
      <a href="#" class="table-button" data-toggle="tooltip" data-placement="right" title="Copy table" ref="buttonCopyTable">
        <img src="@/assets/images/utils/Copy.svg" @click.prevent="copyTable"/>
      </a>
      <a href="#" class="table-button" data-toggle="tooltip" data-placement="right" title="Download table" ref="buttonDownloadTable">
        <img src="@/assets/images/utils/DownloadTable.svg" @click.prevent="saveTable"/>
      </a>
      <a v-show="chartSelected" href="#" class="table-button" data-toggle="tooltip" data-placement="right" title="Download chart" ref="buttonDownloadChart">
        <img src="@/assets/images/utils/DownloadChart.svg" @click.prevent="saveChart"/>
      </a>
    </div>
    <table class="table table-sm" v-if="activeView === 'table' || activeView === 'split'">
      <thead>
      <tr>
        <th>Year</th>
        <th v-for="(col, index) in table.columns" :key="index">{{ col }}</th>
      </tr>
      </thead>
      <tbody>
        <template v-for="(row, rowIndex) in table.rows">
          <tr v-if="timeHorizon.contains(row.year)" :key="rowIndex">
            <td>{{ row.year }}</td>
            <td v-for="(col, colIndex) in table.columns" :key="colIndex">{{ row.values[colIndex].toFixed(dp) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
    <template v-if="activeView === 'chart' || activeView === 'split'">
      <div class="chart-root">
        <div class="toolbar">
          <a href="#" @click.prevent="chartType = 'bar'">Bar chart</a>
          <a href="#" @click.prevent="chartType = 'line'">Line chart</a>
        </div>
        <BarChartView v-if="chartType === 'bar'" :table="table" class="chart-view" :time-horizon="timeHorizon" ref="barChartView"/>
        <LineChartView v-if="chartType === 'line'" :table="table" class="chart-view" :time-horizon="timeHorizon" ref="lineChartView"/>
      </div>
    </template>

  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, computed, unref, toRaw} from "vue";
import {Table} from "@/lib/entities/Results";
import BarChartView from "@/components/DataPage/Charts/BarChartView";
import LineChartView from "@/components/DataPage/Charts/LineChartView";
import { TimeHorizon } from "@/lib/utils/TimeHorizon";
import { saveTextToFile, saveBlobToFile } from "@/lib/utils/FileUtils";

import {Tooltip} from 'bootstrap'

const buttonViewTable = ref()
const buttonViewChart = ref()
const buttonViewAndChart = ref()
const buttonCopyTable = ref()
const buttonDownloadTable = ref()
const buttonDownloadChart = ref()

const lineChartView = ref()
const barChartView = ref()

let tooltips = []

const activeView = ref('table')
const chartType = ref('bar')

const chartSelected = computed(() => {
  return activeView.value === 'chart'
})

const props = defineProps({
  table: Table,
  timeHorizon: TimeHorizon,
  dp: {
    type: Number,
    required: false,
    default: 2
  }
})

function initTooltips() {
  tooltips = [buttonViewTable, buttonViewChart, buttonViewAndChart, buttonCopyTable, buttonDownloadTable, buttonDownloadChart].map(e => new Tooltip(e.value))
}

function copyTable() {
  const s = props.table.toString()
  navigator.clipboard.writeText(s)
}

function saveTable() {
  const s = props.table.toString()
  saveTextToFile(s, 'table.csv')
}

async function doSaveChart(chart) {
  const blob = await chart.getImageDataAsync()
  saveBlobToFile(blob, 'chart.png')
}

async function saveChart() {
  if (barChartView.value != null)
    await doSaveChart(barChartView.value)
  else if (lineChartView.value != null)
    await doSaveChart(lineChartView.value)
}

onMounted(() => {
  initTooltips()
})
</script>

<style scoped>
.table-view {
  display: flex;
  flex-direction: row;
}

.buttons {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
}

.table-button {
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 0.5rem;
}

.table-button:hover {
  border-color: #bbb;
}

.table-button img {
  width: 24px;
  height: 24px;
  margin: 0.2rem;
}

.table-button.active {
  border-color: #a3afd0;
}

.table {
  flex: 1;
}

.chart-view {
  flex: 1;
}

.chart-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>