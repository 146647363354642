import { createApp } from 'vue'
import { createPinia } from "pinia/dist/pinia";
import router from "@/sys/router";

import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import '@/assets/css/common-styles.scss';
import '@/assets/css/print.css';

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.mount('#app')
