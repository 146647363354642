export default class DataTemplate {
    name = ''
    curves = []
    percentiles = []
    variables = []

    static fromObject(o) {
        const result = new DataTemplate()
        result.name = o.name
        result.curves = o.curves
        result.percentiles = o.percentiles
        result.variables = o.variables
        return result
    }

    static fromArray(arr) {
        return arr.map(o => DataTemplate.fromObject(o))
    }
}