<template>
<div class="flood-map-result dialog">
  <div class="dialog-background" @click="emit('close')"/>
  <div class="dialog-content general-panel">
    <template v-if="queryResult != null">
      <div class="d-flex flex-row">
        <flood-zone-info class="flood-zone-info" zone="100" :data="queryResult.data100"/>
        <flood-zone-info class="flood-zone-info" zone="500" :data="queryResult.data500"/>
      </div>

      <flood-zone-map-image :data="queryResult.mapData"/>
    </template>
  </div>
</div>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";
import FloodMapQueryResult from "@/lib/entities/FloodMapQueryResult";
import FloodZoneInfo from "@/components/FloodMap/FloodZoneInfo";
import FloodZoneMapImage from "@/components/FloodMap/FloodZoneMapImage";

const emit = defineEmits(['close'])

const props = defineProps({
  queryResult: {
    type: FloodMapQueryResult,
    required: false,
    default: null
  }
})
</script>

<style scoped>
.flood-map-result .dialog-content {
}

.flood-zone-info {
  border-right: 1px solid #aaa;
  margin-right: 2rem;
  padding-right: 2rem;
}
</style>