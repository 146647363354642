import CSVParser from "@/lib/importing/CSVParser";

const ColMappingNames = {
    Lat: 'lat',
    Name: 'name',
    Lng: 'lng',
    Attr: 'attr',
    Ignore: 'ignore'
}

function getMappingSuggestion(name) {
    const latitudeSuggestions = ['lat', 'latitude']
    const longitudeSuggestions = ['lon', 'lng', 'longitude']
    const nameSuggestions = ['name']

    name = name.toLowerCase()

    function test(arr) { return arr.some(str => name.toLowerCase().includes(str))}

    if (test(latitudeSuggestions))
        return ColMappingNames.Lat
    if (test(longitudeSuggestions))
        return ColMappingNames.Lng
    if (test(nameSuggestions))
        return ColMappingNames.Name

    return ColMappingNames.Attr
}

class ImportDataSourceRow {
    /** @type number */ lat;
    /** @type number */ lng;
    /** @type string */ name;
    /** @type object */ attributes = {}

    /**
     * @param {ImportDataSource} source
     * @param {Array} rowValues
     */
    constructor(source, rowValues) {
        this.lat = source.getRowValue(rowValues, ColMappingNames.Lat, true, true)
        this.lng = source.getRowValue(rowValues, ColMappingNames.Lng, true, true)
        this.name = source.getRowValue(rowValues, ColMappingNames.Name, false)
        this.attributes = source.getRowAttributesObject(rowValues)
    }
}

class ImportDataSourceColumn {
    name
    index
    mapping

    static create(name, index) {
        let result = new ImportDataSourceColumn()
        result.name = name
        result.index = index
        result.mapping = getMappingSuggestion(name)
        return result
    }
}

class ImportDataSource {
    /** @type ImportDataSourceRow[] */ _dataRows = null
    /** @type ImportDataSourceColumn[] */ cols = []
    /** @type CSVParser */ parser

    constructor(text) {
        this.parser = new CSVParser()
        this.parser.parse(text)
        this.cols = this.parser.header.indexed.map((name, index) => ImportDataSourceColumn.create(name, index))
    }

    get rowCount() {
        return this._dataRows.length
    }

    get dataRows() {
        if (this._dataRows === null)
            this.buildDataRows()
        return this._dataRows
    }

    buildDataRows() {
        const rows = this.parser.rows.map(rowValues => new ImportDataSourceRow(this, rowValues))
        this._dataRows = rows
        return rows
    }

    invalidateDataRows() {
        this._dataRows = null
    }

    getColIndexFromMapping(mapping) {
        return this.cols.findIndex(col => col.mapping === mapping)
    }

    getRowValue(values, mappingName, required, isNumber = false) {
        const index = this.getColIndexFromMapping(mappingName)
        if(index === -1) {
            if (required)
                throw new Error(`Value not set for ${mappingName}.`)
            return null
        }

        const val = values[index]
        return isNumber ? Number.parseFloat(val) : val
    }

    getRowAttributesObject(values) {
        let o = {}
        const attrCols = this.cols.filter(col => col.mapping === ColMappingNames.Attr)
        attrCols.forEach(col => {
            o[col.name] = values[col.index]
        })
        return o
    }
}

export default ImportDataSource
export { ImportDataSourceColumn, ImportDataSourceRow, ColMappingNames }