<template>
<div class="flood-zone-info">
  <h5><span class="color-key" :style="{backgroundColor: data.colorCode}"></span>Zone {{ zone }}</h5>
  <template v-if="data.withinZone">
    <p>Is within zone</p>
  </template>
  <template v-else>
    <p>Nearest zone is {{ data.distanceInKm.toFixed(settingsStore.latLngDp) }}km away<br/>({{ data.latLng.toString(settingsStore.latLngDp) }})</p>
  </template>
</div>
</template>

<script setup>
import {defineProps} from "vue";
import {FloodZoneInfo} from "@/lib/entities/FloodMapQueryResult";
import config from "@/sys/config";
import {useViewSettingsStore} from "@/stores/settingsStore";

const settingsStore = useViewSettingsStore()

const props = defineProps({
  data: {
    type: FloodZoneInfo,
    required: true
  },
  zone: {
    type: String,
    required: true
  }
})
</script>

<style scoped>
.color-key {
  border: 1px solid #888;
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 1rem;
}
</style>