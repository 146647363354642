<template>
  <div class="flood-map-selector">
    <div class="toolbar">
      <div class="item">
        <strong>Flood Map Query</strong>
      </div>
      <span class="item">
        <label>Latitude:</label>
        <input type="text" v-model="lat">
      </span>
      <span class="item">
        <label>Longitude:</label>
        <input type="text" v-model="lng">
      </span>
      <span class="item">
        <label>
        <input type="checkbox" v-model="multiQuery">
        Multiple query
      </label>
      </span>
      <div class="button-row">
        <div class="button-strip">
          <button v-if="multiQuery" class="btn btn-primary" @click="add_Click">Add</button>
          <button v-if="multiQuery" class="btn btn-primary" @click="showMultiQueryImportDialog = true">Import</button>
          <button class="btn btn-primary" @click="query_Click">Query</button>
          <button v-if="multiQuery" class="btn btn-primary" @click="queryAndDownload_Click">Query and Download (zip)</button>
        </div>
      </div>
    </div>

    <leaflet-wrapper class="map" ref="map" @map-clicked="map_Clicked"></leaflet-wrapper>
    <loading-indicator v-if="isLoading"></loading-indicator>

    <div class="multi-site-import" v-if="showMultiQueryImportDialog">
      <div class="general-panel">
        <p>Add multiple locations by pasting comma-delimited (lat,lng) data in the space below and clicking the Import button.</p>
        <textarea v-model="importText"/>
        <p>
          Note 1: please do not include a header row<br/>
          Note 2: only the first 10 locations will be added
        </p>
        <div class="button-row">
          <div class="button-strip">
            <button class="btn btn-primary" @click="import_Click">Import</button>
            <button class="btn btn-primary" @click="showMultiQueryImportDialog = false">Cancel</button>
          </div>
        </div>
      </div>
    </div>

    <div class="multi-results-table" v-if="multiQuery">
      <table class="table table-bordered">
        <thead>
          <tr>
            <td>Latitude</td>
            <td>Longitude</td>
            <td>Zone 100</td>
            <td>Zone 500</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in multiQueryPoints" :key="index">
            <td>{{item.latLng.lat.toFixed(settingsStore.latLngDp)}}°</td>
            <td>{{item.latLng.lng.toFixed(settingsStore.latLngDp)}}°</td>
            <td>{{formatResult(item, 100)}}</td>
            <td>{{formatResult(item, 500)}}</td>
          </tr>
        </tbody>
      </table>
      <div class="table-actions">
        <a href="#" @click.prevent="downloadMultiTable_Click">
          <img src="@/assets/images/utils/Download.svg" height="24"/>
          Download table
        </a>

        <a href="#" @click.prevent="clearMultiTable_Click">
          <img src="@/assets/images/utils/Clear.svg" height="24"/>
          Clear table
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineEmits} from "vue";
import LeafletWrapper from "@/components/Mapping/LeafletWrapper";
import LoadingIndicator from "@/components/LoadingIndicator";
import {getFloodMapInfoAsync, getMultiFloodMapInfoAsync, getMultiFloodMapInfoZipAsync} from "@/api/floodMapApi";
import {useUserStore} from "@/stores/userStore";
import {LatLng} from "@/lib/entities/Results";
import CSVParser from "@/lib/importing/CSVParser";
import {saveBlobToFile, saveTextToFile} from "@/lib/utils/FileUtils";
import {useViewSettingsStore} from "@/stores/settingsStore";
import {useProjectStore} from "@/stores/projectStore";

const userStore = useUserStore()
const settingsStore = useViewSettingsStore()
const projectStore = useProjectStore()

const lat = ref(0)
const lng = ref(0)
const showMultiQueryImportDialog = ref(false)
const isLoading = ref(false)
const emit = defineEmits(['ondata'])
const multiQuery = ref(false)
const multiQueryPoints = ref([])
const importText = ref('')

const maxMultiSite = 10

function map_Clicked(data) {
  lat.value = data.lat
  lng.value = data.lng
}

function getMultiSiteResultText() {

  const noResultText = 'no_result'

  /** @param {FloodZoneInfo} d */
  function getResultText(d) {
    if (d == null)
      return noResultText
    if (d.withinZone)
      return 'within_zone'
    return `lat=${d.latLng.lat}; lng=${d.latLng.lng}; dist=${d.distanceInKm}km`
  }

  let s = 'lat,lng,100,500\n'
  multiQueryPoints.value.forEach((v) => {
    s = s + `${v.latLng.lat},${v.latLng.lng},`
    if (v.results == null || !v.results.hasData)
      s = s + `${noResultText},${noResultText}`
    else
      s = s + `${getResultText(v.results.data100)},${getResultText(v.results.data500)}`
    s = s + '\n'
  })
  return s
}

function downloadMultiTable_Click() {
  const text = getMultiSiteResultText()
  saveTextToFile(text, 'flood-zones.csv')
}

function clearMultiTable_Click() {
  multiQueryPoints.value = []
}

function addMultiPointItem(latLng) {
  if (multiQueryPoints.value.length > maxMultiSite)
    throw new Error(`The maximum number of locations that can be added is ${maxMultiSite}.`)

  multiQueryPoints.value.push({
    latLng: latLng,
    results: null
  })
}

function add_Click() {
  try {
    addMultiPointItem(new LatLng(lat.value, lng.value))
  }
  catch(error) {
    alert(error.message)
  }
}

function formatResult(item, zone) {
  if (item == null || item.results == null) return 'Click Query to get results'
  const zoneInfo = item.results.getZoneInfo(zone)
  if (zoneInfo == null) return '-'
  if (zoneInfo.withinZone) return 'In zone'
  return `Nearest point ${zoneInfo.distanceInKm.toFixed(3)}km away (${zoneInfo.latLng.toString()})`
}

async function doSingleQueryAsync() {
  const result = await getFloodMapInfoAsync(userStore.token, lat.value, lng.value, projectStore.current.projectId)
  emit('ondata', result)
}

function doImport(text) {

  const parser = new CSVParser(',')
  try {
    parser.parse(text, false)
  }
  catch {
    throw new Error('The provided CSV data is not in the correct format.')
  }

  multiQueryPoints.value = []
  for(let i=0; i < Math.min(10, parser.rows.length); i++) {
    const lat = Number.parseFloat(parser.rows[i][0])
    const lng = Number.parseFloat(parser.rows[i][1])
    addMultiPointItem(new LatLng(lat, lng))
  }
  showMultiQueryImportDialog.value = false
}

function import_Click() {
  try {
    doImport(importText.value)
  }
  catch(error) {
    alert(error.message)
  }
}

function getLatLngsString() {
  const points = multiQueryPoints.value.map(p => p.latLng.toString(5, false))
  return points.join(',')
}

async function doMultiQueryAsync() {
  const latLngsString = getLatLngsString()
  const results = await getMultiFloodMapInfoAsync(userStore.token, latLngsString, projectStore.current.projectId)
  for(var i=0; i < results.length; i++) {
    multiQueryPoints.value[i].results = results[i]
  }
}

async function queryAndDownload_Click() {
  if (!multiQuery.value) return

  isLoading.value = true

  try {
    const latLngsString = getLatLngsString()
    const blob = await getMultiFloodMapInfoZipAsync(userStore.token, latLngsString, projectStore.current.projectId)

    saveBlobToFile(blob, 'flood map results.zip')
  }
  finally {
    isLoading.value = false
  }
}

async function query_Click() {
  isLoading.value = true

  try {
    if (multiQuery.value)
      await doMultiQueryAsync()
    else
      await doSingleQueryAsync()
  }
  catch(error) {
    alert(error.message)
  }
  finally {
    isLoading.value = false
  }
}
</script>

<style scoped>
.flood-map-selector {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.toolbar {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.toolbar .item label {
  margin-right: 0.5rem;
}

.toolbar .item input[type="text"] {
  width: 70px;
}

.map {
  flex: 1;
}

.multi-results-table {
  background-color: #eee;
}

.multi-results-table .table-actions {
  margin: 1rem;
}

.multi-results-table .table-actions a {
  text-decoration: none;
  margin-right: 1rem;
}

.multi-site-import {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.multi-site-import .general-panel {
  max-width: 500px;
}

.multi-site-import textarea {
  display: block;
  width: 100%;
  height: 200px;
}

</style>