class UserSettings {
    chartType;
    customTimeHorizonYears;
    customTimeHorizonRangeFrom;
    customTimeHorizonRangeTo;
    timeHorizonType;
    autoSwitchToSavedPage;

    constructor() {
        this.restoreDefault()
    }

    restoreDefault() {
        this.chartType = 'bar'
        this.customTimeHorizonYears = '1995, 2000, 2025, 2050, 2075, 2100'
        this.customTimeHorizonRangeFrom = '1995'
        this.customTimeHorizonRangeTo = '2100'
        this.timeHorizonType = 'specific'
        this.autoSwitchToSavedPage = true
    }

    setSetting(o, name) {
        if (o[name] == null)
            return

        this[name] = o[name]
    }

    update(o) {
        if (o == null)
            return

        this.setSetting(o, 'chartType')
        this.setSetting(o, 'customTimeHorizonYears')
        this.setSetting(o, 'timeHorizonType')
        this.setSetting(o, 'autoSwitchToSavedPage')
        this.setSetting(o, 'customTimeHorizonRangeFrom')
        this.setSetting(o, 'customTimeHorizonRangeTo')
    }

    static fromResponse(resp) {
        let result = new UserSettings()
        result.update(resp)
        return result
    }
}

export default UserSettings