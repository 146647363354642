<template>
    <div class="extend-weather-historical-varlist">
        <expand-toggle text="Historical" @on-change="isOpen = !isOpen" />
        <div v-if="isOpen" class="items">
            <extend-weather-historical-var-item v-for="(varItem, index) in varInventory.historicalVars.items" :key="index" :var-item="varItem" :forecast="forecast"
                                                @month-clicked="month_Clicked"/>
        </div>
    </div>
</template>

<script>
import ExpandToggle from "@/components/ExpandToggle";
import { Forecast, ForecastVarInventory } from "@/lib/extendWeather/ForecastObjects";
import ExtendWeatherHistoricalVarItem from "@/components/ExtendWeatherPage/ExtendWeatherHistoricalVarItem";

export default {
    name: "ExtendWeatherHistoricalVarlist",
    components: {ExtendWeatherHistoricalVarItem, ExpandToggle },
    data() { return {
        isOpen: false
    }},
    props: {
        forecast: Forecast,
        varInventory: ForecastVarInventory
    },
    methods: {
        month_Clicked(varItem, month, index) {
            this.$emit('month-clicked', varItem, month, index)
        }
    }
}
</script>

<style scoped>
.items {
    margin-left: 1.2rem;
}
</style>