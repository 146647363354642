function splitLines(text) {
    return text.replace(/\r\n/g, '\n').split('\n')
}

function cleanValue(s) {
    if (s.length < 2 || (s[0] !== '"' && s[s.length-1] !== '"')) return s

    let result = ''
    let p = ''
    for(let i=1; i < s.length-1; i++) {
        let c = s[i]
        if (c !== '"' || (c === '"' && p !== '"')) result += c
        p = c;
    }
    return result
}

/**
* @param {string} delim
* @param {string} line
* */
function splitLine(delim, line) {
    //return line.split(delim)
    let quoteCount = 0
    let indexes = []

    for(let i=0; i < line.length; i++) {
        let c = line[i]
        if (c === '"') quoteCount++
        else if (c === delim && quoteCount % 2 === 0) {
            indexes.push(i)
            quoteCount = 0
        }
    }
    indexes.push(line.length)

    let start=0
    let result = []
    for(let i=0; i < indexes.length; i++) {
        let s = line.substring(start, indexes[i])
        s = cleanValue(s)
        result.push(s)
        start = indexes[i]+1
    }

    return result
}

/**
 * Creates a default header based on the number of token found in the specified line, e.g.
 * name,age,dob -> col1,col2,col3
 */
function createDefaultHeader(delim, line) {
    const sp = splitLine(delim, line)
    let header = {
        indexed: [],
        named: {}
    }
    for(let i=0; i < sp.length; i++) {
        const name = `col${i+1}`
        header.indexed.push(name)
        header.named[name] = i
    }
    return header
}

function createHeader(delim, line) {
    const sp = splitLine(delim, line)
    let header = {
        indexed: [],
        named: {}
    }
    for(let i=0; i < sp.length; i++) {
        let name = sp[i].toLowerCase()
        header.indexed.push(name.toString())
        header.named[name] = i
    }
    return header
}

function createRows(header, delim, lines) {
    let result = []
    for(let i=0; i < lines.length; i++) {
        if (lines[i] === '')
            continue;
        let row = splitLine(delim, lines[i])
        result.push(row)
    }
    return result
}

function findDelim(line) {
    for(let i=0; i < line.length; i++) {
        let c = line.charAt(i)

        if (c === ',')
            return ','
        else if (c === '\t')
            return '\t'
        else if (c === '|')
            return '|'
    }
    
    return ' '
}

class CSVParser {
    /** @type Object */ header;
    /** @type Array[] */ rows;

    constructor(delim) {
        this.delim = delim
        this.clear()
    }
    
    clear() {
        this.header = null
        this.rows = []
    }

    /** @param {string} text */
    parse(text, hasHeader = true) {
        this.clear()

        let lines = splitLines(text)
        
        if (lines.length < 2)
            throw new Error('Error. A header line and at least one data line must be provided.')
        
        let delim = this.delim
        if (delim === undefined)
            delim = findDelim(lines[0])

        if (hasHeader) {
            this.header = createHeader(delim, lines[0])
            this.rows = createRows(this.header, delim, lines.slice(1))
        }
        else {
            this.header = createDefaultHeader(delim, lines[0])
            this.rows = createRows(this.header, delim, lines)
        }
    }
    
    getValue(col, row) {
        if (typeof row == 'number')
            row = this.rows[row]
        
        if (typeof col == 'number')
            return row[col]
            
        if (this.header.named[col] === undefined)
            return undefined
   
        return row[this.header.named[col]]
    }

    /** @param {string[]} colNames
    *   @returns boolean  */
    hasColumns(colNames) {
        return colNames.every(x => this.header.named[x] !== undefined)
    }
}

export default CSVParser
export { splitLine }