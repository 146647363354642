<template>
  <div id="result-set-viewer" class="flex-page">
    <div class="window-controls">
      <a href="#" @click.prevent="emit('close')">&times;</a>
    </div>
    <template v-if="!userStore.isLoggedIn">Please log in</template>
    <template v-else-if="hasResults">
      <div class="result-set-name-section">
        <h3 class="result-set-name">{{ dataReader.info.name }}</h3>
      </div>

      <div id="results-root">
        <div id="filters">
          <div class="filter-items">
            <div class="filter-item">
              <h6>{{strings.CURVES_LABEL}}:</h6>
              <div class="item" :class="{selected: isCurveSelected(curve)}" v-for="(curve, index) in dataReader.info.curves" :key="index" @click="toggleCurve(curve)">
                {{ formatCurveName(curve) }}
              </div>
            </div>
            <div class="filter-item">
              <h6>Percentiles:</h6>
              <div class="item" :class="{selected: isPcntSelected(pcnt)}" v-for="(pcnt, index) in dataReader.info.percentiles" :key="index" @click="togglePcnt(pcnt)">
                {{ pcnt }}
              </div>
            </div>
            <div class="filter-item">
              <h6>{{ strings.STR_TIME_HORIZON }}:</h6>
              <button class="btn btn-secondary" @click="showTimeHorizonDialog = true">Modify</button>
            </div>
          </div>
          <hr/>
          <div class="d-flex flex-row justify-content-between">
            <h6>Select asset:</h6>
            <button class="btn btn-sm btn-secondary" @click="mapToggle_Click">Map</button>
          </div>
          <div id="filters-content" class="base-list-box" :style="{border: showMapContainer ? '1px solid #888' : '1px solid white' }">
            <div class="list-box-scroller">
              <div class="item" :class="{selected: selectedAsset === asset}" v-for="(asset, index) in sortedAssets" :key="index" @click="selectAsset(selectedAsset = asset)">
                <div class="asset-name">{{ asset.name }}</div>
                <div class="asset-details">Lat: {{ asset.latLng.lat.toFixed(settingsStore.latLngDp) }}°, Long: {{ asset.latLng.lng.toFixed(settingsStore.latLngDp) }}°</div>
              </div>
            </div>

            <div v-show="showMapContainer" class="map-container" ref="map-container">
              <leaflet-wrapper ref="map" class="map"/>
            </div>

          </div>
        </div>

        <div id="content">
          <template v-if="selectedAsset != null">
            <div class="result-set-heading">
              <div class="result-set-title">
                <h4>{{ selectedAsset.name }}</h4>
                <ul class="asset-title-meta-row">
                  <li><span class="label">Latitude:</span><span class="value">{{ selectedAsset.latLng.lat.toFixed(settingsStore.latLngDp) }}°</span></li>
                  <li><span class="label">Longitude:</span><span class="value">{{ selectedAsset.latLng.lng.toFixed(settingsStore.latLngDp) }}°</span></li>
                  <li v-if="selectedClassifications != null"><span class="label">Elevation:</span><span class="value">{{ selectedClassifications.elevation }}m</span></li>
                </ul>
              </div>
              <div class="result-set-tools">
                <a href="#" @click.prevent="copyTables_Click"><img alt="Copy" src="@/assets/images/utils/Copy.svg"/></a>
                <a href="#" @click.prevent="downloadTables_Click"><img alt="Download" src="@/assets/images/utils/Download.svg"/></a>
                <a href="#" v-if="selectedAsset.hasAttributes" @click.prevent="showAttributes = true"><img alt="View Attributes" src="@/assets/images/utils/Attributes.svg"/></a>
              </div>
            </div>
          </template>

          <div class="vars-menu">
            <div class="button-strip-container">
              <div class="button-strip var-buttons">
                <button class="btn btn-secondary" @click="nextVar(-1)">&lt;</button>
                <button class="btn btn-secondary" @click.prevent="showVariableList = !showVariableList">
                  <template v-if="showClassifications">
                    Classifications
                  </template>
                  <template v-else>
                    {{ selectedVariableButtonText }}
                  </template>
                </button>
                <button class="btn btn-secondary" @click="nextVar(1)">&gt;</button>
              </div>
              <div class="button-strip">
                <button class="btn btn-success" @click.prevent="downloadAllAssets_Click">Download Report(s)</button>
              </div>
            </div>

            <div id="var-list" v-if="showVariableList">
              <div class="grouped-items">
                <div>
                  <h6>Other</h6>
                  <div class="var-list-links">
                    <a v-if="dataReader.classificationData != null" class="var-item-link" :class="{ selected: showClassifications }" @click="showClassifications = true">Classifications</a>
                  </div>
                </div>
                <div v-for="(grouped, i) in groupVarIdsWithCategories(varItemStore.categories, uniqueVars)" :key="i">
                  <h6>{{getGroupName(grouped.group)}}</h6>
                  <div class="var-list-links">
                    <a class="var-item-link" :class="{ selected: !showClassifications && varId === selectedVarId }" href="#" v-for="(varId, index) in grouped.items" :key="index" @click.prevent="selectVar(varId)">
                      {{ varIdToVarName(varId) }}
                    </a>
                  </div>
                </div>
              </div>
              <div style="position: absolute; top: -0.5rem; right: 0.2rem; font-size: 18pt;">
                <a href="#" style="text-decoration: none" @click.prevent="showVariableList = false">&times;</a>
              </div>
            </div>
          </div>
          <div id="content-root">
            <div class="scrollable">
              <div v-if="showClassifications">
                <classifications-view :classifications="selectedClassifications" />
              </div>
              <div v-else v-for="(tableSet, index) in resultsToView" :key="index">
                <result-set-item-view :time-horizon="timeHorizon" :table-set="tableSet" :dp="settingsStore.dp" :dataset-source="getDatasetSourceItem(tableSet)"/>
                <hr/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-indicator v-if="dataLoading" :ignore-events="true"/>
    </template>
    <template v-else>
      <loading-indicator/>
    </template>
  </div>

  <time-horizon-dialog v-if="showTimeHorizonDialog" :time-horizon="timeHorizon" @changed="timeHorizon_Changed" @cancel="showTimeHorizonDialog = false"/>
  <result-set-export-dialog v-if="showExportDialog" :data-reader="dataReader" @cancel="showExportDialog = false"  @exported="showExportDialog = false" :time-horizon="timeHorizon" />
  <attributes-dialog v-if="showAttributes" :attributes="selectedAsset.attributes" @close="showAttributes = false" />
</template>

<script setup>
import ResultSetItemView from './ResultSetItemView'
import {defineProps, onMounted, onUnmounted, ref, computed, defineEmits} from "vue";
import ResultSetItem from "@/lib/entities/ResultSetItem";
import {varIdToVarName} from "@/lib/utils/varNameUtils";
import {SpecificTimeHorizon} from "@/lib/utils/TimeHorizon";
import TimeHorizonDialog from "@/dialogs/TimeHorizonDialog";
import { saveTextToFile } from "@/lib/utils/FileUtils";
import ResultSetExportDialog from "@/components/Exporting/ResultSetExportDialog";
import strings from "@/lib/lang/Strings";
import { formatCurveName } from "@/lib/utils/curveName";
import AttributesDialog from "@/components/DataPage/AttributesDialog";
import {createResultSetReader} from "@/lib/ResultSetReader";
import ClassificationsView from "@/components/DataPage/Charts/ClassificationsView.vue";
import LeafletWrapper from "@/components/Mapping/LeafletWrapper"
import {groupVarIdsWithCategories} from "@/lib/entities/VarCategories";
import {useUserStore} from "@/stores/userStore";
import {useViewSettingsStore} from "@/stores/settingsStore";
import {useVarItemsStore} from "@/stores/varItemStore";
import keyboard from "@/sys/keyboard";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const userStore = useUserStore()
const settingsStore = useViewSettingsStore()
const varItemStore = useVarItemsStore()

function getGroupName(group) {
  if (group == null)
    return 'Uncategorized'
  return group.name
}

const props = defineProps({
  resultSet: ResultSetItem
})

const dataReader = ref(null)
const selectedAsset = ref(null)
const selectedCurves = ref([])
const selectedPercentiles = ref([])
const selectedVarId = ref('')
const showTimeHorizonDialog = ref(false)
const showExportDialog = ref(false)
const showVariableList = ref(false)
const timeHorizon = ref(new SpecificTimeHorizon([2005, 2000, 2025, 2050, 2075, 2100]))
const showAttributes = ref(false)
const selectedAssetResults = ref(null)
const selectedClassifications = ref(null)
const showClassifications = ref(false)
const showMapContainer = ref(false)
const map = ref(null)
const dataLoading = ref(false)

const sortedAssets = computed(() => {
  return Array.from(dataReader.value.info.assets).sort((a, b) => a.name.localeCompare(b.name))
})

const emit = defineEmits(['close'])

function selectVar(varId) {
  showClassifications.value = false
  selectedVarId.value = varId
}

onMounted(async () => {
  keyboard.push('Escape', () => emit('close'))
  const resultSet = props.resultSet
  const reader = createResultSetReader(resultSet.storageType)
  if (reader == null) {
    alert('The selected result set is stored in a format not supported by this version of Climate Insights. Please contact support.')
    return
  }

  try {
    await reader.loadAsync(userStore.token, resultSet.refId)
    dataReader.value = reader

    selectedCurves.value = reader.info.curves.length > 0 ? [reader.info.curves[0]] : null
    selectedPercentiles.value = reader.info.percentiles.length ? (reader.info.percentiles.includes('50') ? ['50'] : [reader.info.percentiles[0]]) : null
    selectedVarId.value = reader.info.variables.length > 0 ? reader.info.variables[0] : null
    await selectAsset(reader.info.assets.length > 0 ? reader.info.assets[0] :  null)
  }
  catch(error) {
    console.log('error', error)
    alert('Failed to load result set. Please contact support.')
  }
})

onUnmounted(() => {
  keyboard.pop('Escape')
})

function toggleCurve(curve) {
  let arr = selectedCurves.value
  if (arr.includes(curve))
    arr = arr.filter(c => c !== curve)
  else
    arr = [...arr, curve]
  selectedCurves.value = arr
}

function timeHorizon_Changed(th) {
  timeHorizon.value = th
  showTimeHorizonDialog.value = false
}

function togglePcnt(pcnt) {
  let arr = selectedPercentiles.value
  if (arr.includes(pcnt))
    arr = arr.filter(c => c !== pcnt)
  else
    arr = [...arr, pcnt]
  selectedPercentiles.value = arr
}

function nextVar(offset) {
  const vars = uniqueVars.value
  const index = vars.indexOf(selectedVarId.value)
  if (index === -1) return
  const newIndex = index + offset
  if (newIndex < 0 || newIndex >= vars.length) return
  selectVar(vars[newIndex])
}

function isCurveSelected(curve) {
  return selectedCurves.value.includes(curve)
}

function isPcntSelected(pcnt) {
  return selectedPercentiles.value.includes(pcnt)
}

function getDatasetSourceItem(tableSet) {
  const reader = dataReader.value

  if (reader == null || reader.datasetSources == null)
    return null

  return reader.datasetSources.find(tableSet.region, tableSet.source, tableSet.jVarId)
}

function getExportText(sep) {
  const r = resultsToView.value

  let s = ''
  r.forEach(v => s += v.toString(sep) + '\r\r')

  return s
}

let hasInitMarkers = false

function mapToggle_Click(e) {
  e.preventDefault()
  showMapContainer.value = !showMapContainer.value
  if (showMapContainer.value) {
    map.value.refresh()
    if (!hasInitMarkers) {
      hasInitMarkers = true
      dataReader.value.info.assets.forEach(asset => {
        const marker = map.value.addMarker(asset.latLng.lat, asset.latLng.lng)
        marker.on('click', () => {
          selectAsset(asset)
          map.value.panTo(asset.latLng.toArray())
        })
      })
    }
    if (selectedAsset.value != null)
      map.value.panTo(selectedAsset.value.latLng.toArray())
  }
}

function copyTables_Click() {
  const text = getExportText('\t')

  navigator.clipboard.writeText(text)
}

function downloadTables_Click() {
  const text = getExportText(',')
  saveTextToFile(text, selectedAsset.value.name + ' - ' + selectedVarId.value + '.csv')
}

async function selectAsset(asset) {
  selectedAssetResults.value = null
  selectedClassifications.value = null

  selectedAsset.value = asset

  if (asset != null) {
    if (showMapContainer.value)
      map.value.panTo(asset.latLng.toArray())
    dataLoading.value = true
    try
    {
      selectedAssetResults.value = await dataReader.value.getAssetResultsAsync(asset.assetId)
    }
    finally
    {
      dataLoading.value = false
    }
    if (dataReader.value.classificationData != null) {
      selectedClassifications.value = dataReader.value.classificationData.find(asset.assetId)
    }
  }
}

function downloadAllAssets_Click() {
  showExportDialog.value = true
}

const hasResults = computed(() => {
  return dataReader.value != null
})

const selectedVariableButtonText = computed(() => {
  const id = selectedVarId.value
  if (id === '')
      return 'Select variable'
  return varIdToVarName(id)
})

const resultsToView = computed(() => {
  if (selectedAssetResults.value == null)
    return []

  /** @type Asset */
  const assetInfo = selectedAsset.value

  if (assetInfo == null)
    return null

  const assetResults = selectedAssetResults.value
  if (assetResults == null)
    return null

  const selCurves = Array.from(selectedCurves.value)
  const selPcnts = Array.from(selectedPercentiles.value)

  return assetResults.tables.filter(t => {
    return t.varId === selectedVarId.value && selCurves.includes(t.curve) && selPcnts.includes(t.percentile)
  })
})

const uniqueVars = computed(() => {
  return dataReader.value.info.variables
})

</script>

<style lang="scss" scoped>
#result-set-viewer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  box-shadow: black 0 0.5rem 1rem;
  margin: 0 !important;
  background-color: white;
}

#results-root {
  flex: 1;
  display: flex;
  flex-direction: row;
}

#filters {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin: 1rem;
  margin-left: 0;
}

#filters-content {
  flex: 1;
  border: none;
}

#content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

#content-root {
  flex: 1;
  position: relative;
}

.filters-list-box {
  min-height: 200px;
}

.filter-items {
  display: flex;
}

.filter-item {
  margin-right: 1rem;
  flex: 1;
}

.filter-item .item {
  cursor: default;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  padding: 0.2rem;
  border-radius: 0.2rem;
}

h6 {
  font-weight: bold;
}

.result-set-heading {
  display: flex;
  flex-direction: row;
}

.result-set-title {
  flex: 1;
}

.result-set-name-section {
  display: flex;
  flex-direction: row;
}

.result-set-name-section h3 {
  margin-bottom: 0;
}

.vars-menu {
  margin-bottom: 1rem;
}

#var-list {
  position: absolute;
  overflow: hidden;
  z-index: 1000;
  flex-wrap: wrap;
  border: 1px solid #bbb;
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  border-radius: 0.4rem;

  .grouped-items {
    position: relative;
    overflow-y: scroll;
    max-width: 1000px;
    max-height: 500px;
  }

  .var-list-links {
    display: flex;
    flex-wrap: wrap;

    .var-item-link {
      margin-right: 0.6rem;
      margin-bottom: 0.6rem;
      border: 1px solid #e0e0e0;
      text-decoration: none;
      padding: 0.2rem 0.6rem;
      border-radius: 0.2rem;
      background-color: white;
      color: black !important;
    }

    .var-item-link.selected {
      background-color: #336F76;
      color: white !important;
    }
  }
}

.result-set-tools img {
  width: 24px;
  height: 24px;
}

.asset-title-meta-row {
  list-style: none;
  padding: 0;
}

.asset-title-meta-row li {
  display: inline-block;
  margin-right: 1rem;
}

.asset-title-meta-row li span.label {
  color: #888;
}

.asset-title-meta-row li span.value {
  padding-left: 0.5rem;
}

.classification-items {
  display: flex;
  flex-direction: column;
}

.classification-items .item {
  border: 1px solid #aaa;
  margin: 1rem;
  padding: 1rem;
}

#filters-content .item {
  border-radius: 0.2rem;

  .asset-name {
    font-size: 11pt;
  }

  .asset-details {
    font-size: 9pt;
  }
}

.var-buttons {
  .btn {
    border-radius: 0 !important;
  }

  .btn:first-child {
    border-top-left-radius: 0.4rem !important;
    border-bottom-left-radius: 0.4rem !important;
    border-right: 1px solid #666 !important;
  }

  .btn:last-child {
    border-top-right-radius: 0.4rem !important;
    border-bottom-right-radius: 0.4rem !important;
    border-left: 1px solid #666 !important;
  }
}

.map-container {
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 600px;
  border: 1px solid #888;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 100;
  border-radius: 0.5rem;

  .toolbar {
    background-color: white;
  }

  .map {
    flex: 1;
  }
}

</style>