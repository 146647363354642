import BaseResultSetExporter from "@/lib/dataExport/resultSet/BaseResultSetExporter";
import {saveTextToFile} from "@/lib/utils/FileUtils";
import {ResultSetStringBuilder} from "@/lib/ResultSetReader";

export default class ResultSetTSVExporter extends BaseResultSetExporter {
    async exportAsync(reader) {
        const builder = new ResultSetStringBuilder()
        builder.timeHorizon = this.timeHorizon
        builder.sep = '\t'
        const str = await builder.buildStringAsync(reader)
        saveTextToFile(str, reader.info.name + ' results.tsv')
    }
}