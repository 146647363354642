import { getJsonAsync } from "@/api/apiUtils";
import config from "@/sys/config";
import AddressSearchResult from "@/lib/entities/AddressSearchResult";

async function lookupAddressesAsync(token, address) {
    const url = `${config.SERVICE_URL}lookup/search/?s=${address}`
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200)
        throw new Error('Error getting search results')
    const arr = await resp.json()
    return AddressSearchResult.fromArray(arr)
}

export { lookupAddressesAsync }