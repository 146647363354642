<template>
<div class="dialog">
  <div class="dialog-background"/>
  <div class="dialog-content general-panel">

    <div class="name-box">
      <label>Template name:</label>
      <input type="text" v-model="templateName"/>
    </div>

    <div class="content-boxes">
      <div class="vars-box">
        <var-selector :allow-saving="false" class="vars-selector" ref="vars"/>
      </div>
      <div class="options-box">
        <options-selector :show-select-assets-button="false" class="options-selector" ref="options"/>
      </div>
    </div>

    <div class="button-row">
      <div class="button-strip">
        <button class="btn btn-primary" @click="create_Click">Create</button>
        <button class="btn btn-primary" @click="cancel_Click">Cancel</button>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>

import VarSelector from "@/components/DataPage/Builder/VarSelector.vue";
import OptionsSelector from "@/components/DataPage/Builder/OptionsSelector.vue";
import { ref, defineEmits } from "vue";
import {createDataTemplateAsync} from "@/api/apiKeyService";
import {useUserStore} from "@/stores/userStore";

const vars = ref()
const options = ref()
const templateName = ref('')

const emit = defineEmits(['template-added', 'close'])
const userStore = useUserStore()

async function create_Click() {
  const name = templateName.value
  const varsData = vars.value.getData()
  const optionsData = options.value.getData()

  if (name === '' || name == null) {
    alert('Please enter a template name')
    return
  }

  if (varsData.length === 0) {
    alert('Please select one ore more variables')
    return
  }

  if (optionsData.curves.length === 0) {
    alert('Please select one or more scenario')
    return
  }

  if (optionsData.percentiles.length === 0) {
    alert('Please select one or more percentiles')
    return
  }

  await createDataTemplateAsync(userStore.token, name, varsData, optionsData.curves, optionsData.percentiles)

  emit('template-added')
  emit('close')
}

function cancel_Click() {
  emit('close')
}

</script>

<style scoped>

.dialog-content {
  width: 80%;
  max-width: 900px;
  height: 80%;
  display: flex;
  flex-direction: column;
}

.name-box {
  display: flex;
  flex-direction: row;
}

.name-box label {
  margin-right: 1rem;
}

.name-box input {
  flex: 1;
}

.content-boxes {
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 1rem 0;
}

.vars-box {
  flex: 1;
  display: flex;
}

.options-box {
  width: 30%;
  display: flex;
}

.vars-selector,
.options-selector {
  flex: 1;
}

.vars-selector {
  margin-right: 1rem;
}

</style>