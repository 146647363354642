class UserGroup {
    /** @type string */ name = ''
    /** @type string */ id = ''
    /** @type string */ description = ''
    roles = []

    constructor(o) {
        if (o != null)
            this.assign(o)
    }

    assign(o) {
        this.name = o.info.name
        this.id = o.info.id
        this.description = o.info.description
        this.roles = o.roles
    }

    static from(o) {
        return new UserGroup(o)
    }

    static fromArray(arr) {
        if (arr == null) return []
        return arr.map(data => new UserGroup(data))
    }
}

export default UserGroup