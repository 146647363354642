<script setup>
import {useUserStore} from "@/stores/userStore";
import {getDataHistoryAsync} from "@/api/dataHistoryApi";
import {onMounted, ref} from "vue";

const userStore = useUserStore()
const updates = ref(null)

async function getDataUpdatesAsync() {
  try {
    updates.value = await getDataHistoryAsync(userStore.token)
  }
  catch(error) {
    console.error('getDataUpdatesAsync', error)
  }
}

onMounted(async() => {
  await getDataUpdatesAsync()
})
</script>

<template>
  <div class="update-history">
    <h4>Data update history</h4>

    <div class="base-list-box flex-fill">
      <div class="list-box-scroller">
        <table class="history-table table table-sm">
          <thead>
          <tr>
            <th>Date</th>
            <th>Variable</th>
            <th>Region</th>
            <th>Info</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in updates" :key="index">
            <td>{{item.updated}}</td>
            <td>{{item.indicator.replace(/_/g, ' ')}}</td>
            <td>{{item.region}}</td>
            <td>
              {{item.description}}<br/>
              Version: {{item.version}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.update-history {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.history-table {
  &, thead, tbody, tr, td, th {
    background-color: initial;
  }
}
</style>