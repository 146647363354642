class BrandingProfileImage {
    fileName = ''
    userId = ''
    size = 0
    uploadedDate = ''
    width = 0
    height = 0

    static fromObject(o) {
        if (o == null) return null
        let result = new BrandingProfileImage()
        result.fileName = o.fileName
        result.userId = o.userId
        result.size = o.size
        result.uploadedDate = o.uploadedDate
        result.width = o.width
        result.height = o.height
        return result
    }
}

export default class BrandingProfile {
    brandingEntityId = ''
    userId = ''
    name = ''
    companyName = ''
    slogan = ''
    address1 = ''
    address2 = ''
    state = ''
    postcode = ''
    country = ''
    phone = ''
    companyWebsite = ''
    companyEmail = ''
    /** @type BrandingProfileImage */ image = null

    static fromObject(o) {
        let result = new BrandingProfile()
        result.brandingEntityId = o.brandingEntityId
        result.userId = o.userId
        result.name = o.name
        result.companyName = o.companyName
        result.slogan = o.slogan
        result.address1 = o.address1
        result.address2 = o.address2
        result.state = o.state
        result.postcode = o.postcode
        result.country = o.country
        result.phone = o.phone
        result.companyWebsite = o.companyWebsite
        result.companyEmail = o.companyEmail
        result.image = BrandingProfileImage.fromObject(o.image)
        return result
    }

    static fromArray(arr) {
        return arr.map(o => BrandingProfile.fromObject(o))
    }
}

export { BrandingProfileImage }