<template>
    <div class="extend-weather-forecast-list-item">
        <expand-toggle @on-change="isOpen = !isOpen" :text="listItem.country.name"/>
        <template v-if="isOpen">
            <div class="forecast-items">
                <extend-weather-forecast-item v-for="(item, index) in topItems" :key="index" :forecast="item" :session="session" @forecast-month-clicked="forecastMonth_Clicked" @historical-month-clicked="historicalMonth_Clicked"/>
                <a class="show-more-toggle" v-if="showMore === false" href="#" @click="showMore = true">Show more...</a>
                <a class="show-more-toggle" v-if="showMore === true" href="#" @click="showMore = false">Hide...</a>
                <template v-if="showMore">
                    <extend-weather-forecast-item v-for="(item, index) in moreItems" :key="index" :forecast="item" :session="session" @forecast-month-clicked="forecastMonth_Clicked" @historical-month-clicked="historicalMonth_Clicked"/>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import ExpandToggle from "@/components/ExpandToggle";
import { ForecastListItem } from "@/lib/extendWeather/ForecastObjects";
import ExtendWeatherForecastItem from "@/components/ExtendWeatherPage/ExtendWeatherForecastItem";
import EWUtils from "@/lib/extendWeather/EWUtils";

export default {
    name: "ExtendWeatherForecastListItem",
    components: {ExtendWeatherForecastItem, ExpandToggle },
    data() { return { 
        isOpen: false,
        showMore: false
    }},
    props: { 
        listItem: ForecastListItem,
        session: String 
    },
    computed: {
        topItems() { return EWUtils.getRange(this.listItem.forecasts, 0, 3) },
        moreItems() { return EWUtils.getRange(this.listItem.forecasts, 3) }
    },
    methods: {
        forecastMonth_Clicked(forecast, varItem, month, index) {
            this.$emit('forecast-month-clicked', forecast, varItem, month, index)
        },
        historicalMonth_Clicked(forecast, varItem, month, index) {
            this.$emit('historical-month-clicked', forecast, varItem, month, index)
        }    
    }
}
</script>

<style scoped>
.extend-weather-forecast-list-item {
    
}

.forecast-items {
    margin-left: 1.2rem;
    margin-bottom: 0.5rem;
}

a.show-more-toggle {
    color: #38383c;
    font-size: small;
    font-weight: bold;
}
</style>