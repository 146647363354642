class ResultSetItem {
    name = ''
    status = 'invalid'
    projectId = ''
    refId = ''
    date = ''
    storageType = 'notstored'

    static fromObject(o) {
        let result = new ResultSetItem()
        result.name = o.name
        result.status = o.status
        result.projectId = o.projectId
        result.refId = o.refId
        result.date = o.date
        result.storageType = o.storageType
        return result
    }

    static fromArray(arr) {
        return arr.map(item => ResultSetItem.fromObject(item))
    }
}

export default ResultSetItem