<template>
  <div class="context-menu-item">
    <a href="#" @click.prevent="onClick"><slot></slot></a>
  </div>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";
const props = defineProps({
  command: String
})
const emit = defineEmits(['clicked'])
function onClick() {
  emit('clicked', props.command)
}
</script>

<style scoped>
a {
  color: white !important;
  text-decoration: none;
  margin: 1rem;
}
</style>