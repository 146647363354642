export default {
    parseDate(str) {
        let dateVals = this.parseDateValues(str)
        return new Date(dateVals.year, dateVals.month-1, dateVals.day)
    },
    parseDateTime(str) {
        let s = str.split(' ')
        if (s.length !== 2)
            throw new Error('Invalid number of date/time values')
            
        let dateVals = this.parseDateValues(s[0])
        let timeVals = this.parseTimeValues(s[1])
        
        return new Date(dateVals.year, dateVals.month-1, dateVals.day, 
            timeVals.hour, timeVals.minute, timeVals.second)
    },
    parseDateValues(str) {
        let s = str.split('-')
        if (s.length !== 3)
            throw new Error('Invalid number of date values')
            
        return {
            year: Number.parseInt(s[0]),
            month: Number.parseInt(s[1]),
            day: Number.parseInt(s[2])
        }
    },
    parseTimeValues(str) {
        let s = str.split(':')
        if (s.length !== 3)
            throw new Error('Invalid number of time values')

        return {
            hour: Number.parseInt(s[0]),
            minute: Number.parseInt(s[1]),
            second: Number.parseInt(s[2])
        }
    }
}