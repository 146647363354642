import config from "@/sys/config";
import {getJsonAsync, postJsonAsync, updateHeaders} from "@/api/apiUtils";

/**
 * Creates a Unique User Download item and queues it for generation. On success, the unique id is returned.
 * @param {string} token
 * @param {string} refid
 * @param {string} fileName
 * @param {string[]} formats
 * @param {TimeHorizon} timeHorizon
 * @param {string|null} brandingInfoId
 */
async function createUniqueUserDownloadAsync(
    token,
    refid,
    fileName,
    formats,
    timeHorizon,
    brandingInfoId = null) {

    const body = {
        refId: refid,
        timeHorizon: timeHorizon.toString(),
        brandingInfoId: brandingInfoId,
        formats: formats.join('|')
    }

    const url = config.SERVICE_URL + 'data/export/unq-download'

    const resp = await postJsonAsync(url, body, token)
    if (resp.status !== 200) {
        throw new Error('Error creating unique user download')
    }

    const result = await resp.json()

    return result.uniqueId
}

/**
 * Returns the status of the download.
 * @param {string} token
 * @param {string} uniqueId
 */
async function getUniqueUserDownloadStatusAsync(
    token,
    uniqueId) {

    const url = config.SERVICE_URL + `data/export/unq-download/status?uniqueId=${uniqueId}`
    const headers = updateHeaders({}, token)

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200) {
        throw new Error('Failed to get status')
    }

    const result = await resp.json()

    return result.status
}

/**
 * @param {string} token
 * @param {string} uniqueId
 * @return Promise<string>
 */
function waitForCompleteStatus(token, uniqueId) {
    return new Promise(resolve => {

        function doCheck() {
            setTimeout(() => {
                getUniqueUserDownloadStatusAsync(token, uniqueId)
                    .then(status => {
                        switch (status) {
                            case 'error': {
                                resolve('error')
                                break;
                            }
                            case 'ready': {
                                resolve('ready')
                                break;
                            }
                            default:
                                doCheck()
                                break
                        }
                    })
                    .catch(error => {
                        resolve('error')
                    })

            }, 2000)
        }

        doCheck()
    })
}

async function waitForCompleteStatusAsync(token, uniqueId) {
    return await waitForCompleteStatus(token, uniqueId)
}

function formatUniqueDownloadUrl(uniqueId) {
    return config.SERVICE_URL + `data/export/unq-download?uniqueId=${uniqueId}`
}

export { createUniqueUserDownloadAsync, getUniqueUserDownloadStatusAsync, waitForCompleteStatusAsync, formatUniqueDownloadUrl }