<template>
  <div class="context-menu" ref="menu" @click="close" @contextmenu.prevent="() => {}">
    <div class="context-menu-view" ref="view">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

import {ref, defineExpose} from "vue";

const menu = ref()
const view = ref()

function close() {
  menu.value.classList.remove('visible')
}

function show(point) {
  view.value.style.left = `${point.x}px`
  view.value.style.top = `${point.y}px`
  menu.value.classList.add('visible')
}

defineExpose({
  show
})

</script>

<style scoped>
.context-menu {
  display: none;
  z-index: 2000;
  position: fixed;
  left: 0;
  top:  0;
  right: 0;
  bottom: 0;
}
.context-menu.visible {
  display: block;
}
.context-menu .context-menu-view {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: #38383c;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px black;
}

.context-menu-view hr {
  color: white !important;
}
</style>