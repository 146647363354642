import {getAsync, getJsonAsync} from "@/api/apiUtils";
import StrUtils from "@/lib/utils/StrUtils";
import config from "@/sys/config";
import FloodMapQueryResult from "@/lib/entities/FloodMapQueryResult";
import {formatSubscriptionError} from "@/lib/entities/Subscription";

async function getMultiFloodMapInfoAsync(token, latLngsString, projectId) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('getMultiFloodMapInfoAsync: token is null or empty')
    if (StrUtils.isNullOrEmpty(latLngsString)) throw new Error('getMultiFloodMapInfoAsync: latLngsString is null or empty')

    let url = `${config.SERVICE_URL}flood/multi?latLngs=${latLngsString}`
    if (projectId != null)
        url += `&projectId=${encodeURIComponent(projectId)}`

    const resp = await getJsonAsync(url, token)

    if (resp.status === 204)
        throw new Error('Flood map data is not available for this location')

    if (resp.status !== 200)
        throw new Error('Failed to perform query')

    return FloodMapQueryResult.fromArray(await resp.json())
}

async function handleErrorAsync(resp) {
    switch(resp.status) {
        case 200:
            return
        case 204:
            throw new Error('Flood map data is not available for this location')
        case 402:
            throw new Error(formatSubscriptionError(await resp.json()))
        default:
            throw new Error('Unknown subscription error')
    }
}

async function getMultiFloodMapInfoZipAsync(token, latLngsString, projectId) {
    if (StrUtils.isNullOrEmpty(token))
        throw new Error('getMultiFloodMapInfoZipAsync: token is null or empty')
    if (StrUtils.isNullOrEmpty(latLngsString))
        throw new Error('getMultiFloodMapInfoZipAsync: latLngsString is null or empty')

    let url = `${config.SERVICE_URL}flood/multi/zip?latLngs=${latLngsString}`
    if (projectId != null)
        url += `&projectId=${encodeURIComponent(projectId)}`

    // const resp = await getJsonAsync(url, token)

    const resp = await getAsync(url, {}, token)
    await handleErrorAsync(resp)
    return await resp.blob()
}

async function getFloodMapInfoAsync(token, lat, lng, projectId) {
    if (StrUtils.isNullOrEmpty(token)) throw new Error('getFloodMapInfo: token is null or empty')

    if (typeof lat !== 'number') lat = Number.parseFloat(lat)
    if (typeof lng !== 'number') lng = Number.parseFloat(lng)

    let url = `${config.SERVICE_URL}flood?lat=${lat}&lng=${lng}`
    if (projectId != null)
        url += `&projectId=${encodeURIComponent(projectId)}`

    const resp = await getJsonAsync(url, token)
    await handleErrorAsync(resp)
    return FloodMapQueryResult.fromObject(await resp.json())
}

export {
    getFloodMapInfoAsync,
    getMultiFloodMapInfoAsync,
    getMultiFloodMapInfoZipAsync
}