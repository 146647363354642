<template>
    <div class="extend-weather-forecast-varlist">
        <expand-toggle text="Forecast" @on-change="isOpen = !isOpen"></expand-toggle>
        <div v-if="isOpen" class="items">
            <extend-weather-forecast-var-item v-for="(varItem, index) in varInventory.forecastVars.items" :key="index" :var-item="varItem" :forecast="forecast"
                                              @month-clicked="month_Clicked"/>
        </div>    
    </div>
</template>

<script>
import ExpandToggle from "@/components/ExpandToggle";
import {Forecast, ForecastVarInventory} from "@/lib/extendWeather/ForecastObjects";
import ExtendWeatherForecastVarItem from "@/components/ExtendWeatherPage/ExtendWeatherForecastVarItem";

export default {
    name: "ExtendWeatherForecastVarlist",
    components: { ExtendWeatherForecastVarItem, ExpandToggle },
    data() { return {
        isOpen: false
    }},
    props: {
        forecast: Forecast,
        varInventory: ForecastVarInventory
    },
    methods: {
        month_Clicked(varItem, month, index) {
            this.$emit('month-clicked', varItem, month, index)
        }
    }
}
</script>

<style scoped>
.items {
    margin-left: 1.2rem;
}
</style>