<script setup>
import {defineEmits, defineProps, onMounted, onUnmounted} from "vue"
import {DatasetSourceItem} from "@/lib/entities/DatasetSourceItem";
import keyboard from "@/sys/keyboard";
const emit = defineEmits(['close'])
onMounted(() => keyboard.push('Escape', () => emit('close')))
onUnmounted(() => keyboard.pop('Escape'))
const props = defineProps({
  datasetSourceItem: {
    type: DatasetSourceItem,
    required: true
  }
})
</script>

<template>
  <div class="dialog">
    <div class="dialog-background" @click="emit('close')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('close')">&times;</a></div>

      <h3>Data Source Information</h3>

      <table class="table table-light">
        <tbody>
          <tr>
            <td>Region</td>
            <td>{{datasetSourceItem.regionName}}</td>
          </tr>
          <tr>
            <td>Variable</td>
            <td>{{datasetSourceItem.variable}}</td>
          </tr>
          <tr>
            <td>Source</td>
            <td>{{datasetSourceItem.source}}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{{datasetSourceItem.description}}</td>
          </tr>
          <tr>
            <td>Reference</td>
            <td>{{datasetSourceItem.reference}}</td>
          </tr>
          <tr>
            <td>Info source</td>
            <td>{{datasetSourceItem.infoSource}}</td>
          </tr>
        </tbody>
      </table>
      <div class="dialog-buttons">
        <button class="btn btn-primary" @click.prevent="emit('close')">Close</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.dialog-content {
  max-width: 80%;
}
</style>