const DefaultStrings =  {
    APP_TITLE: 'Climate Insights AR6',
    ERROR_TITLE: 'Error',
    ERROR_LOADING_APP_MESSAGE: 'There was an error loading Climate Insights. Please try again in a few minutes or contact support.',
    ERROR_NO_LAYER_TYPES_SELECTED:  'No Layer Types have been selected. Please select at least one layer type of one or more assets.',

    BTN_YES: 'Yes',
    BTN_NO: 'No',
    BTN_OK: 'OK',
    BTN_CANCEL: 'Cancel',
    BTN_REFRESH: 'Refresh',
    BTN_SELECT_ALL: 'Select all',
    BTN_DESELECT_ALL: 'Deselect all',
    BTN_ADD: 'Add',
    BTN_EDIT: 'Edit',
    BTN_DELETE: 'Delete',
    BTN_UPDATE: 'Update',
    BTN_IMPORT: 'Import',
    BTN_CLEAR: 'Clear',
    BTN_MY_ASSETS: 'My Assets',
    BTN_CONTINUE_ELPS: 'Continue...',
    BTN_SAVE: 'Save',
    BTN_LOAD: 'Load',
    BTN_CLOSE: 'Close',
    BTN_FORGOT_PASSWORD: 'Forgot password...',

    CURVE_LABEL: 'Scenario',
    CURVES_LABEL: 'Scenarios',
    CURVE_TYPE: 'SSP',
    PERCENTILE_LABEL: 'Ensemble Percentile',

    SETTINGS_AND_PROFILE: 'Settings and profile',

    HEADING_RESULTS: 'Results',
    HEADING_CREATE: 'Create',
    HEADING_SPECIFY_ASSETS: 'Specify Assets',

    CHECKBOX_ALT_NOT_CHECKED: 'Check box (not checked)',
    CHECKBOX_ALT_CHECKED: 'Check box (checked)',

    TOOLBAR_HOME: 'Home',
    TOOLBAR_CREATE_INSIGHTS: 'Climate Metrics',
    TOOLBAR_FLOOD_TOOL: 'Flood Maps',
    TOOLBAR_RISK: 'Risk',
    TOOLBAR_PROJECTS_INSIGHTS: 'Projects',
    TOOLBAR_SELPROJECT_INSIGHTS: 'Project: ',
    TOOLBAR_SAVED_INSIGHTS: 'Saved',
    TOOLBAR_TOOL_INSIGHTS: 'Tools',
    TOOLBAR_LEARN: 'Help',
    TOOLBAR_EXTEND_WEATHER: 'Seasonal Forecasts',
    TOOLBAR_SEA_WATER_TEMP: 'Sea Water Temperature',
    TOOLBAR_HELP: 'Help',

    TOOLBAR_LOGIN: 'Log in',
    TOOLBAR_SIGNUP: 'Sign-up',

    LOADING_PLEASE_WAIT: 'Loading, please wait...',

    STR_VALUE: 'Value',
    STR_REFERENCE: 'Reference',
    STR_DATE_CREATED: 'Date created',
    STR_STATUS: 'Status',
    STR_DATE: 'Date',
    STR_DELETE_ALL: 'Delete all',
    STR_NAME: 'Name',
    STR_COPY: 'Copy',

    STR_MONTH_JAN: 'Jan',
    STR_MONTH_FEB: 'Feb',
    STR_MONTH_MAR: 'Mar',
    STR_MONTH_APR: 'Apr',
    STR_MONTH_MAY: 'May',
    STR_MONTH_JUN: 'Jun',
    STR_MONTH_JUL: 'Jul',
    STR_MONTH_AUG: 'Aug',
    STR_MONTH_SEP: 'Sep',
    STR_MONTH_OCT: 'Oct',
    STR_MONTH_NOV: 'Nov',
    STR_MONTH_DEC: 'Dec',

    STR_TIME_HORIZON: 'Years',
    TIME_HORIZON_DIALOG_INTRO: `Choose the desired Years using the options below:`,
    STR_REPORT: 'Report',
    STR_LAYER_TYPES: 'Layer types',

    STR_AT: 'at',
    STR_LAT: 'Latitude',
    STR_LNG: 'Longitude',
    STR_TYPE: 'Type',
    STR_ASSET: 'Asset',

    STR_REFRESH: 'Refresh',
    STR_SORT: 'Sort',

    LOGIN_DIALOG_EMAIL_LABEL: 'E-mail address',
    LOGIN_DIALOG_EMAIL_PLACEHOLDER: 'your@email.com',
    LOGIN_DIALOG_PASSWORD_LABEL: 'Password',
    LOGIN_DIALOG_LOGIN_BUTTON: 'Log in',
    LOGIN_DIALOG_TITLE: 'Log in',

    SAVED_INSIGHTS_PAGE_ERROR_REFRESHING_EX: 'Error refreshing Save Insights information: ',
    SAVED_INSIGHTS_PAGE_HEADING: 'Saved insights',
    SAVED_INSIGHTS_PAGE_SELECT_ITEM_INSTRUCTION: 'Click an item to view and download results.',
    SAVED_INSIGHTS_PAGE_DELETE_ALL_PROMPT: 'Are you sure you want to delete all Saved Insights?',
    SAVED_INSIGHTS_PAGE_DELETE_ALL_PROMPT_TITLE: 'Delete all?',

    SAVED_RISK_RESULTS_HEADING: 'Saved Risk Results',

    SEL_DATA_LAYERS_COMP_NO_SET_NAME_ERROR: 'Please enter a set name.',
    SEL_DATA_LAYERS_COMP_NO_LAYERS_SELECTED_FOR_ASSETS: 'Please select at least one layer for the following assets: ',

    CREATE_INSIGHTS_PAGE_ADDING_INSIGHTS: 'Creating Insights, please wait...',
    CREATE_INSIGHTS_PAGE_ADD_ASSET_ERROR: 'Please add one or more assets and try again',
    CREATE_INSIGHTS_PAGE_ADD_ASSET_ERROR_TITLE: 'No assets have been added',

    CREATE_RISK_INSIGHTS_PAGE_ADD_ASSET_ERROR: 'Please add two or more assets and try again',

    ATTRIB_DIALOG_ENTER_NAME_ERROR: 'Please enter an attribute name.',
    ATTRIB_DIALOG_ENTER_VALUE_ERROR: 'Please enter an attribute value.',
    ATTRIB_DIALOG_ENTER_TYPE_ERROR: 'Please select an attribute data type (use String if you\'re not sure what to choose)',
    ATTRIB_DIALOG_VALIDATION_ERROR_TITLE: 'Attribute error',

    DATA_INDEX_LABEL_SELECT_RETURN_PERIODS: 'Select return periods:',
    DATA_INDEX_LABEL_SELECT_YEARS: 'Select years:',

    ASSET_EDITOR_NO_ASSET_IS_SELECTED: 'No asset has been selected',

    ADD_ASSET_DIALOG_ASSET_NAME_LABEL: 'Asset/location name:',
    ADD_ASSET_DIALOG_DATA_AVIL_TAB: 'Data availability',
    ADD_ASSET_DIALOG_ATTRIBUTES_TAB: 'Asset attributes',
    ADD_ASSET_DIALOG_ADD_ATTRIB_BTN: 'Add attribute...',
    ADD_ASSET_DIALOG_EDIT_ATTRIB_BTN: 'Edit attribute...',
    ADD_ASSET_DIALOG_DEL_ATTRIB_BTN: 'Delete attribute',
    ADD_ASSET_DIALOG_ADD_ASSET_BTN: 'Add asset',
    ADD_ASSET_DIALOG_EDIT_ASSET_BTN: 'Edit asset',

    SAVED_INSIGHTS_STATUS_PENDING: 'Pending',
    SAVED_INSIGHTS_STATUS_RUNNING: 'Running',
    SAVED_INSIGHTS_STATUS_PUBLISHING: 'Publishing',
    SAVED_INSIGHTS_STATUS_COMPLETE: 'Complete',
    SAVED_INSIGHTS_STATUS_ERROR: 'Error',
    SAVED_INSIGHTS_STATUS_UNKNOWN: 'Unknown',

    UNKNOWN_LAYER_TYPE_NAME: 'Unknown layer type',

    OLD_RESOURCE_LOGO_URL: '/images/logo@2x.png',

    RESOURCE_LOGO_URL: '/images/Logo2024.svg',
    RESOURCE_LOGO_ALT: 'Climate Insights',

    IMPORT_ASSET_DIALOG_INTRO_PARAGRAPH: 'Add multiple assets by pasting formatted comma or tab separated data below.',
    IMPORT_ASSET_DIALOG_INFO_PARAGRAPH: '"lat" and "lng" columns are required. "name" and "type" columns are optional. Attribute "name: datatype" columns are also optional.',
    IMPORT_ASSET_NONE_FOUND_ERROR: 'No assets were found in the specified data',

    ASSET_CSV_PARSER_ERR_MISSING_REQUIRED_COLUMNS: 'One or more required columns was not found',

    SAVED_INSIGHTS_RESULT_TABLES_RESULTS: 'Results',

    SAVED_INSIGHTS_RESULT_TABLE_INDEX_COL: 'Index',

    SAVED_INSIGHTS_DOWNLOADS_TABLE_VARIABLE_COL: 'Variable',
    SAVED_INSIGHTS_DOWNLOADS_TABLE_DOWNLOAD_LINKS_COL: 'Download links',
    SAVED_INSIGHTS_DOWNLOADS: 'Downloads',

    SAVED_INSIGHTS_ATTRIBUTES: 'Attributes',

    SAVED_INSIGHT_LIST_DELETE_TITLE: 'Delete?',
    SAVED_INSIGHT_LIST_DELETE_MESSAGE_START: 'Delete "',
    SAVED_INSIGHT_LIST_DELETE_MESSAGE_END: '"?',
    SAVED_INSIGHTS_LIST_NO_SAVED_INSIGHTS_CREATED: 'No Saved Insights have been created',

    SELECT_DATA_LAYERS_INPUT_ERROR_SET_NAME_REQUIRED: 'Please enter a name for the Saved Insights',
    SELECT_DATA_LAYERS_INPUT_ERROR_SELECT_LAYERS: 'Please select a layer for at least one asset',

    SELECT_DATA_LAYERS_DIALOG_TITLE: 'Step 3: Select data layers',
    SELECT_DATA_LAYERS_SET_NAME_LABEL: 'Result set name:',
    SELECT_DATA_LAYERS_ASSET_LIST_LABEL: 'Assets:',
    SELECT_DATA_LAYERS_SELECT_AN_ASSET_LABEL: 'Select an asset',
    SELECT_DATA_LAYERS_CONFIRM_BUTTON: 'Create Result Set',
    SELECT_DATA_LAYERS_SELECT_LAYERS_FOR_ASSET: 'Select layers for ',
    SELECT_DATA_LAYERS_SELECT_LAYERS: 'Select layer types:',

    SAVED_INSIGHTS_VIEW_STATUS_ERROR_TITLE: 'Error',
    SAVED_INSIGHTS_VIEW_STATUS_ERROR_INFO: 'There was an error processing results. Please contact support. Reference: ',
    SAVED_INSIGHTS_VIEW_STATUS_PUBLISHING_TITLE: 'Publishing',
    SAVED_INSIGHTS_VIEW_STATUS_PUBLISHING_INFO: 'Results are being published. Please Refresh results in a few moments to check again.',
    SAVED_INSIGHTS_VIEW_STATUS_PENDING_TITLE: 'Pending',
    SAVED_INSIGHTS_VIEW_STATUS_PENDING_INFO: 'Processing is pending. Please Refresh results in a few moments to check again.',
    SAVED_INSIGHTS_VIEW_STATUS_PROCESSING_TITLE: 'Processing',
    SAVED_INSIGHTS_VIEW_STATUS_PROCESSING_INFO: 'Results are Processing. Please Refresh results in a few moments to check again.',
    SAVED_INSIGHTS_VIEW_STATUS_NOTFOUND_TITLE: 'Nothing selected',
    SAVED_INSIGHTS_VIEW_STATUS_NOTFOUND_INFO: 'Select a Saved Insight to view results.',
    SAVED_INSIGHTS_VIEW_RESULTS_LABEL: 'Results for ',
    SAVED_INSIGHTS_VIEW_DOWNLOAD_ALL_RESULTS_PDF_HEADING: 'Report for all assets',
    SAVED_INSIGHTS_VIEW_DOWNLOAD_PDF: 'Download PDF report',
    SAVED_INSIGHTS_VIEW_DOWNLOAD_CSV: 'Download CSV of all results',
    SAVED_INSIGHTS_VIEW_DOWNLOAD_XLSX: 'Download Excel (XLSX) of all results',
    SAVED_INSIGHTS_DOWNLOAD_REPORT: 'Download report...',


    RESPERR_USER_NOT_FOUND: 'An account was not found with this email/password combination.',
    RESPERR_UNKNOWN_ERROR: 'An unknown error occured.',
    RESPERR_NO_ASSETS_SPECIFIED: 'No assets have been added.',
    RESPERR_RESULT_SET_NAME_MISSING: 'Result set name was not specified.',
    RESPERR_INVALID_TOKEN: 'User/login token has expired or is no longer valid.',
    RESPERR_USER_ALREADY_EXISTS: 'A user account already exists for this e-mail address.',
    RESPERR_USER_CREATE_ERROR: 'A server error occured while creating your account. Please contact support.',
    RESPERR_AVAILABLE_DATA_NOT_FOUND: 'Available data not found',
    RESPERR_NOT_PERMITTED: 'Not permitted',
    RESPERR_FORBIDDEN: 'Forbidden',
    RESPERR_SAVED_INSIGHT_NOT_FOUND: 'Saved Insights not found',
    RESPERR_INCORRECT_PASSWORD: 'Incorrect current password',
    RESPERR_INVALID_PASSWORD_FORMAT: 'Invalid password length',

    SELECT_LAYER_TYPES_DIALOG_TITLE: 'Create Insights',
    SELECT_LAYER_TYPES_DIALOG_BTN_OK: 'Confirm',
    SELECT_LAYER_TYPES_DIALOG_CHOOSE_PERCENTILES: 'Choose percentiles:',
    SELECT_LAYER_TYPES_DIALOG_NO_ASSET_SELECTED_TEXT: 'Select an asset to select layer types.',
    SELECT_LAYER_TYPES_DIALOG_LOADING_ASSET_INFO_TEXT: 'Please wait a moment, analysing assets...',
    SELECT_LAYER_TYPES_DIALOG_LOADING_ASSET_ERROR_TEXT: 'Error analysing assets',
    SELECT_LAYER_TYPES_DIALOG_50TH_PERCENTILE: '50th',
    SELECT_LAYER_TYPES_DIALOG_05TH_PERCENTILE: '5th',
    SELECT_LAYER_TYPES_DIALOG_95TH_PERCENTILE: '95th',
    SELECT_LAYER_TYPES_DIALOG_WAIT: 'Please wait...',
    SELECT_LAYER_TYPES_DIALOG_VALIDATION_ERROR_MISSING_SETNAME: 'Please enter a Result set name.',

    SETTINGS_PAGE_TITLE: 'Settings and profile',

    SETTINGS_TAB_SETTINGS: 'Settings',
    SETTINGS_TAB_PROFILE: 'Profile',
    SETTINGS_TAB_PASSWORD: 'Password',
    SETTINGS_TAB_GROUPS: 'User groups',
    SETTINGS_TAB_API: 'API access',

    SETTINGS_GROUPS_YOUR_A_MEMBER_OF: 'You are a member of the following groups:',
    SETTINGS_API_ACCESS_INFO: 'Specify Api Apps below:',
    SETTINGS_API_KEYS_INFO: 'Specify Api App Keys below:',

    SETTINGS_PASSWORD_CURRENT_PASSWORD_MISSING: 'Current password is missing',
    SETTINGS_PASSWORD_NEW_PASSWORD_MISSING: 'New password is missing',
    SETTINGS_PASSWORD_RETYPE_PASSWORD_MISSING: 'Retyped password is missing',
    SETTINGS_PASSWORD_INVALID_LENGTH: 'Passwords must be at least eight characters in length',
    SETTINGS_PASSWORD_RETYPE_NO_MATCH: 'New password and Retype password fields do not match',
    SETTINGS_PASSWORD_CHANGE_SUCCESS: 'Password has been changed successfully.',
    SETTINGS_PASSWORD_CHANGE_SUCCESS_TITLE: 'Password changed',

    SETTINGS_PASSWORD_INTRO: 'Enter your current and new password below to change your account password:',
    SETTINGS_PASSWORD_CURRENT_PASSWORD: 'Current password',
    SETTINGS_PASSWORD_NEW_PASSWORD: 'New password',
    SETTINGS_PASSWORD_RETYPE_PASSWORD: 'Retype password',
    SETTINGS_PASSWORD_CHANGE_PASSWORD_BUTTON: 'Change password',

    SETTINGS_PROFILE_INTRO: 'Update your user profile below:',

    DOWNLOADINSPDFDLG_TITLE: 'Download PDF report',
    DOWNLOADINSPDFDLG_GENERIC: 'Generic report',
    DOWNLOADINSPDFDLG_NZSME: 'Report for SME\'s',

    DOWNLOAD_REPORT_DIALOG_GENERATING_REPORT: 'Generating report',
    DOWNLOAD_REPORT_DIALOG_GENERATING_REPORT_EXTENDED: 'This may take a few minutes',
    DOWNLOAD_REPORT_DIALOG_UPLOADING_REPORT: 'Uploading report...',
    DOWNLOAD_REPORT_DIALOG_REPORT_READY: 'Report ready',
    DOWNLOAD_REPORT_DIALOG_CHOOSE_REPORT_TYPE: 'Choose a report type to download:',
    DOWNLOAD_REPORT_DIALOG_NO_REPORTS_AVAILABLE: 'There are no reports types available.',

    SAVE_LOAD_ASSETS_TITLE: 'My Assets',
    SAVE_LOAD_ASSETS_ASSET_LIST_INFO: 'Current saved asset sets:',
    SAVE_LOAD_ASSETS_ERROR_LOADING_SAVED_ASSETS_GROUPS_INDEX: 'Error loading Saved Assets index.',
    SAVE_LOAD_ASSETS_ERROR_SAVING_NO_ASSETS: 'No assets have been specified.',
    SAVE_LOAD_ASSETS_ERROR_SAVING_NO_ASSETS_TITLE: 'No assets',
    SAVE_LOAD_ASSETS_ERROR_SAVING_NO_NAME: 'Please enter a name for the Saved Asset Group.',
    SAVE_LOAD_ASSETS_ERROR_SAVING_NO_NAME_TITLE: 'Name missing',
    SAVE_LOAD_ASSETS_DELETE_SAVED_GROUP: 'Delete saved asset group?',
    SAVE_LOAD_ASSETS_DELETE_SAVED_GROUP_TITLE: 'Delete?',
    SAVE_LOAD_ASSETS_ERROR_SAVING_SAVED_ASSET_GROUP: 'Error saving asset group.',
    SAVE_LOAD_ASSETS_ERROR_SAVING_SAVED_ASSET_GROUP_TITLE: 'Error',
    SAVE_LOAD_ASSETS_ERROR_LOADING_SAVED_ASSET_GROUP: 'Error loading asset group.',
    SAVE_LOAD_ASSETS_ERROR_LOADING_SAVED_ASSET_GROUP_TITLE: 'Error',

    PROJECT_NO_PROJECT_DESCRIPTION_TEXT: 'No project description.',
    PROJECT_SELECT_A_PROJECT_TO_VIEW_OPTIONS: 'Select a project to view project options',
    PROJECT_OPEN_PROJECT_BUTTON: 'Open project',
    PROJECT_EDIT_PROJECT_BUTTON: 'Edit',
    PROJECT_DELETE_PROJECT_BUTTON: 'Delete',

    VARLISTDLG_TITLE: 'Layer types',

    TIME_HORIZON: 'Years',
    TIME_HORIZON_CHOOSE_YEARS: 'Choose years',
    TIME_HORIZON_SELECT_RANGE: 'Select range',
    TIME_HORIZON_SHOW_ALL: 'Show all',
    TIME_HORIZON_START_YEAR: 'Start year',
    TIME_HORIZON_END_YEAR: 'End year',
    TIME_HORIZON_ENTER_YEARS: 'Enter years'
}

export default DefaultStrings