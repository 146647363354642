import UserGroup from "@/lib/entities/UserGroup";
import result from "pako/lib/zlib/gzheader";
import o from "pako/lib/zlib/gzheader";

class RoleData {
    role = ''
    userId = ''
    name = ''
    email = ''

    static from(o) {
        const result = new RoleData()
        result.role = o.role
        result.userId = o.userId
        result.name = o.name
        result.email = o.email
        return result
    }

    static fromArray(arr) {
        return arr.map(o => RoleData.from(o))
    }
}

export class Member {
    userId = ''
    email = ''
    name = ''
    role = ''

    constructor(userId, email, name, role) {
        this.userId = userId
        this.email = email
        this.name = name
        this.role = role
    }

    static from(o) {
        return new Member(o.userId, o.email, o.name, o.role)
    }

    static fromArray(arr) {
        if (arr == null)
            return []

        return arr.map(o => Member.from(o))
    }
}

export default class ManageUserGroupData {
    /** @type RoleData[] */ roles = []
    /** @type UserGroup */ info = null
    /** @type Member[] */ members = []

    static from(o) {
        const result = new ManageUserGroupData()
        result.roles = RoleData.fromArray(o.roles)
        result.info = UserGroup.from(o)
        result.members = Member.fromArray(o.members)
        return result
    }
}