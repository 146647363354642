import BaseOpenSaveViewModel from "@/lib/viewModels/BaseOpenSaveViewModel";
import {useUserStore} from "@/stores/userStore";
import {useProjectStore} from "@/stores/projectStore";
import {getAssetSetAsync, getAssetSetsAsync, deleteAssetSetsAsync, saveAssetSetAsync} from "@/api/assetSetService";

class BaseAssetSetViewModel extends BaseOpenSaveViewModel {
    get canDelete() {
        return true
    }

    async getViewItemsAsync() {
        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        const assetSets = await getAssetSetsAsync(token, projectId)

        return assetSets
    }

    getItemText(item) {
        return item.name
    }

    async deleteAsync(viewItem) {
        if (viewItem == null)
            return

        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        await deleteAssetSetsAsync(token, projectId, viewItem.id)
    }
}

class OpenAssetSetViewModel extends BaseAssetSetViewModel {
    get canOpen() {
        return true
    }

    get title() {
        return "Load asset set"
    }

    async loadAsync(viewItem) {
        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        const assetSet = await getAssetSetAsync(token, projectId, viewItem.id)

        return assetSet
    }
}

class SaveAssetSetViewModel extends BaseAssetSetViewModel {
    get canSave() {
        return true
    }

    get title() {
        return 'Save asset set'
    }

    async saveAsync(name, viewItem, data) {
        if (name === '')
            throw new Error('name is empty')

        if (data == null)
            throw new Error('data is null')

        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        data.name = name
        if (viewItem != null && viewItem.name === name)
            data.id = viewItem.id

        await saveAssetSetAsync(token, projectId, data)
    }
}

export { OpenAssetSetViewModel, SaveAssetSetViewModel }