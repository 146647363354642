export default {
    VERSION: '4.1.1',

    // IS_DEBUG: true,
    // SERVICE_URL: 'https://localhost:7193/api/',

    IS_DEBUG: false,
    SERVICE_URL: '/api/',

    DEFAULT_LAYER_OPACITY: 0.8,

    BASEMAP_URL: 'https://api.mapbox.com/styles/v1/matthewcs/cjqwq8v5m4nrb2sntb0kyohok/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWF0dGhld2NzIiwiYSI6ImNqbzdueGtqZTB4YXAzcXFtMWR3bnZ4N3YifQ.dD0tbDLoIH81x3twMmjr6w',
    MAPBOX_BASEMAP_INFO: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoibWF0dGhld2NzIiwiYSI6ImNqbzdueGtqZTB4YXAzcXFtMWR3bnZ4N3YifQ.dD0tbDLoIH81x3twMmjr6w',
    DEFAULT_MAP_CENTER: [0,0],
    DEFAULT_MAP_ZOOM: 3
}
