import { formatCurveName } from "@/lib/utils/curveName";
import {TimeHorizon} from "@/lib/utils/TimeHorizon";

class LatLng {
    /** @type Number */ lat = 0
    /** @type Number */ lng = 0

    constructor(lat, lng) {
        if (typeof lat == 'number') this.lat = lat
        if (typeof lng == 'number') this.lng = lng
    }

    static fromObject(o) {
        let result = new LatLng()
        result.lat = Number.parseFloat(o.lat)
        result.lng = Number.parseFloat(o.lng)
        return result
    }

    /**
     * Returns a string representation of the LatLng, with each value separated by a comma
     * @param digits Number of decimal digits to display
     * @param formatForDisplay If true, includes a space between each value and the degrees symbol (e.g. "30.45°, 50.50°")
     * @return {string}
     */
    toString(digits = 3, formatForDisplay = true) {
        if (formatForDisplay)
            return `${this.lat.toFixed(digits)}°, ${this.lng.toFixed(digits)}°`

        return `${this.lat.toFixed(digits)},${this.lng.toFixed(digits)}`
    }

    toArray() {
        return [this.lat, this.lng]
    }
}

class AssetInfo {
    /** @type String */ assetId = ''
    /** @type String */ name = ''
    /** @type LatLng */ latLng
    /** @type Object */ attributes = {}

    static fromObject(o) {
        let result = new AssetInfo()
        result.assetId = o.assetId
        result.name = o.name
        result.latLng = LatLng.fromObject(o.latLng)
        result.attributes = o.attributes
        return result
    }

    get hasAttributes() {
        const numKeys = Object.keys(this.attributes).length
        return numKeys > 0
    }

    get attributeEntries() {
        return Object.entries(this.attributes)
    }
}

class ResultsInfo {
    /** @type String */ userId = ''
    /** @type String */ refId = ''
    /** @type String */ projectId = ''
    /** @type String */ name = ''
    /** @type String */ created = ''
    /** @type String[] */ variables = []
    /** @type String[] */ curves = []
    /** @type String[] */ percentiles = []
    /** @type AssetInfo[] */ assets = []
    /** @type Boolean */ deleted = false

    static fromObject(o) {
        let result = new ResultsInfo()
        result.userId = o.userId
        result.refId = o.refId
        result.projectId = o.projectId
        result.name = o.name
        result.created = o.created
        result.variables = o.variables
        result.curves = o.curves.map(c => c.toString())
        result.percentiles = o.percentiles.map(c => c.toString())
        result.assets = o.assets.map(a => AssetInfo.fromObject(a))
        result.deleted = o.deleted
        return result
    }

    findAsset(id) {
        return this.assets.find(a => a.assetId === id)
    }
}

class TableRow {
    /** @type Number */ year = 0
    /** @type Number[] */ values = []

    static fromObject(o) {
        let result = new TableRow()
        result.year = o.year
        result.values = o.values
        return result
    }

    formatValues(dp = 2) {
        return this.values.map(val => val.toFixed(dp))
    }
}

const indexedMonthlyArray = ['1','2','3','4','5','6','7','8','9','10','11','12']
const descriptiveMonthlyArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

/**
 * @param {Array} a1
 * @param {Array} a2
 * @returns Boolean
 */
function arrayEquals(a1, a2) {
    return a1.length === a2.length &&
           a1.every((val, index) => a2[index] == val)
}

function convertColumns(cols) {
    if (arrayEquals(cols, indexedMonthlyArray))
        return Array.from(descriptiveMonthlyArray)
    return cols
}

class Table {
    /** @type String[] */ columns = []
    /** @type TableRow[] */ rows = []
    /** @type TableInfo */ tableInfo = null

    static fromObject(o, tableInfo) {
        let result = new Table()
        result.columns = convertColumns(o.columns)
        result.rows = o.rows.map(r => TableRow.fromObject(r))
        result.tableInfo = tableInfo
        return result
    }

    /** @description: Returns a default table title based on provided table information */
    get tableTitle() {
        function unitsString(s) {
            if (s === '' || s == null)
                return ''
            return ` (${s})`
        }
        // const s = this.tableInfo.type + ': ' + this.tableInfo.varId + unitsString(this.tableInfo.units) + ' - ' + formatCurveName(this.tableInfo.curve) + ' - ' + this.tableInfo.percentile + 'th'
        // return s
        return `${this.tableInfo.type}: ${this.tableInfo.varId} ${unitsString(this.tableInfo.units)} - ${formatCurveName(this.tableInfo.curve)} - ${this.tableInfo.percentile}th`
    }

    toString(sep = '\t', inclHeader = true, inclTitle = true, timeHorizon = new TimeHorizon()) {
        let s = ''

        if (inclTitle)
            s += this.tableTitle + '\n\n'

        if (inclHeader)
            s += ['year', ...this.columns].join(sep) + '\n'

        this.rows.filter(row => timeHorizon.includes(row.year)).forEach(row => {
            s += [row.year, ...row.formatValues()].join(sep) + '\n'
        })

        return s
    }
}

class TableInfo {
    type
    units
    varId
    percentile
    curve

    constructor (type, units, varId, percentile, curve) {
        this.type = type
        this.units = units
        this.varId = varId
        this.percentile = percentile
        this.curve = curve
    }
}

class TableSet {
    /** @type String */ units = ''
    /** @type String */ changeUnits = ''
    /** @type String */ curve = ''
    /** @type String */ percentile = ''
    /** @type String */ jVarId = ''
    /** @type String */ varId = ''
    /** @type Table */ data
    /** @type Table */ changes
    /** @type Table */ scores

    // Added values for Dataset Sources feature
    /** @type String */ region
    /** @type String */ source
    /** @type String */ resolution

    static fromObject(o) {
        let result = new TableSet()
        result.units = o.units
        result.changeUnits = o.changeUnits
        result.curve = o.curve.toString()
        result.percentile = o.percentile.toString()
        result.jVarId = o.jVarId
        result.varId = o.varId
        result.data = Table.fromObject(o.data, new TableInfo('Data', result.units, result.varId, result.percentile, result.curve))
        result.changes = Table.fromObject(o.changes, new TableInfo('Changes', result.changeUnits, result.varId, result.percentile, result.curve))
        result.scores = Table.fromObject(o.scores, new TableInfo('Scores', '', result.varId, result.percentile, result.curve))

        result.region = o.region || null
        result.source = o.source || null
        result.resolution = o.resolution || null
        result.version = o.version || null
        result.updatedDate = o.updatedDate || null

        return result
    }

    toString(sep, timeHorizon) {
        let s = ''

        s += this.data.toString(sep, true, true, timeHorizon) + '\n'

        s += this.changes.toString(sep, true, true, timeHorizon) + '\n'

        s += this.scores.toString(sep, true, true, timeHorizon) + '\n'

        return s
    }
}

class AssetResults {
    /** @type String */ assetId = ''
    /** @type TableSet[] */ tables = []

    static fromObject(o) {
        let result = new AssetResults()
        result.assetId = o.assetId
        result.tables = o.tables.map(t => TableSet.fromObject(t))
        return result
    }

    toString(sep = '\t', timeHorizon = new TimeHorizon()) {
        let s = ''
        this.tables.forEach((t, index) => {
            if (index > 0)
                s += '\n-----------------------------------\n'
            s += t.toString(sep, timeHorizon)
        })
        return s
    }
}

class Results {
    /** @type ResultsInfo */ info
    /** @type AssetResults[] */ assetResults = []

    static fromObject(o) {
        let result = new Results()
        result.info = ResultsInfo.fromObject(o.info)
        result.assetResults = o.assetResults.map(r => AssetResults.fromObject(r))
        return result
    }

    getAssetResults(assetId) {
        return this.assetResults.find(a => a.assetId === assetId)
    }

    toString(sep, timeHorizon) {
        let s = ''
        this.assetResults.forEach(result => {
            const asset = this.info.findAsset(result.assetId)
            if (asset != null)
                s += `Asset: ${asset.name}\nLat: ${asset.latLng.lat} Lng: ${asset.latLng.lng}\n\n`
            s += result.toString(sep, timeHorizon)
        })
        return s
    }
}

export default Results
export { LatLng,
    AssetInfo,
    ResultsInfo,
    TableRow,
    Table,
    TableSet,
    AssetResults,
    Results,
}