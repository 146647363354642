import config from "@/sys/config";
import {postJsonAsync, getJsonAsync, statusCodes, deleteAsync} from "@/api/apiUtils";
import Strings from "@/lib/lang/Strings";
import LayerGroup from "@/lib/entities/LayerGroup";
import {AssetSet, AssetSetReference} from "@/lib/entities/AssetSet";

async function getLayerGroupsAsync(token, projectId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')

    const url = config.SERVICE_URL + `layergroups/${projectId}`

    const resp = await getJsonAsync(url, token)

    if (resp.status !== 200)
        throw new Error('Error loading LayerGroup')

    const arr = await resp.json()
    return LayerGroup.fromArray(arr)
}

async function deleteDeleteLayerGroupAsync(token, projectId, assetSetId) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')

    const url = config.SERVICE_URL + `layergroups/${projectId}/${assetSetId}`

    const resp = await deleteAsync(url, {}, token)

    if (resp.status !== 200)
        throw new Error('Error deleting LayerGroup')
}

async function saveLayerGroupAsync(token, projectId, layerGroup) {
    if (token == null) throw new Error('token is null')
    if (projectId == null) throw new Error('projectId is null')
    if (layerGroup === null) throw new Error('layerGroup is null')

    layerGroup = {
        name: layerGroup.name,
        id: layerGroup.id,
        curves: layerGroup.curves.map(curve => curve.toString()),
        percentiles: layerGroup.percentiles.map(p => p.toString()),
        layerIds: Array.from(layerGroup.layerIds)
    }

    const url = config.SERVICE_URL + `layergroups/${projectId}`

    const resp = await postJsonAsync(url, layerGroup, token)

    if (resp.status !== 200) {
        throw new Error('Error saving LayerGroup')
    }
}

export {
    getLayerGroupsAsync,
    deleteDeleteLayerGroupAsync,
    saveLayerGroupAsync
}