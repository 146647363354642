export default {
    getBoundsFromObject(obj) {
        if (typeof obj != 'object')
            return obj

        return [[obj.bottom, obj.left], [obj.top, obj.right]]
    },
    getCenterOfBounds(bounds) {
        bounds = this.getBoundsFromObject(bounds)

        let bottom = bounds[0][0]
        let top = bounds[1][0]
        let left = bounds[0][1]
        let right = bounds[1][1]

        return [top - ((top - bottom) / 2), left + ((right - left) / 2)]
    }    
}