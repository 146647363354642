import StrUtils from "@/lib/utils/StrUtils";
import {loginAsync} from "@/api/userApi";
import {loadBrandingAsync} from "@/lib/profile/brandingImageLoader";

class LoginActionResponse {
    userData = null
    brandingData = null
}

async function loginActionAsync(username, password, rememberMe) {
    if (StrUtils.isNullOrEmpty(username)) throw new Error('Invalid username value')
    if (StrUtils.isNullOrEmpty(password)) throw new Error('Invalid password value')

    const resp = new LoginActionResponse()

    resp.userData = await loginAsync(username, password, rememberMe)

    try {
        resp.brandingData = await loadBrandingAsync(resp.userData.token)
    }
    catch(error) {
        console.log('error loading branding', error)
        // no action required when failing to load branding
    }

    return resp
}

export { LoginActionResponse, loginActionAsync }