const keyboard = {
    push(key, handler) {
        let arr = this.handlers[key]
        if (!Array.isArray(arr))
            arr = []
        arr.push(handler)
        this.handlers[key] = arr
    },
    pop(key) {
        let arr = this.handlers[key]
        if (!Array.isArray(arr))
            return
        arr.pop()
    },
    handlers: {},
    hasHandler(key) {
        const h = this.handlers[key]
        return h !== undefined && Array.isArray(h) && h.length > 0
    },
    dispatch(key) {
        const hArr = this.handlers[key]
        if (!Array.isArray(hArr) || hArr.length === 0)
            return false
        const handler = hArr[hArr.length - 1]
        handler(key)
        return true
    }
}

document.addEventListener('keydown', event => {
    const key = event.key
    keyboard.dispatch(key)
})

export default keyboard