<template>
  <div id="welcome-page" class="flex-page">
    <template v-if="userStore.isLoggedIn">
      <welcome-dashboard/>
    </template>
    <template v-else>
      <welcome-login/>
    </template>
  </div>
</template>

<script setup>

import { useUserStore } from "@/stores/userStore";
import WelcomeDashboard from "@/components/WelcomePage/WelcomeDashboard";
import WelcomeLogin from "@/components/WelcomePage/WelcomeLogin";

const userStore = useUserStore()

</script>

<style scoped>

#welcome-page {

}

</style>