<template>
  <div class="data-dashboard">
    <div class="dashboard">
      <div id="current-results">
        <h3>Open a result set</h3>

        <div class="d-flex flex-row justify-content-between pt-1 pb-1">
          <div>
            Select a result set to open or click New to create a new one.
          </div>

          <div class="d-flex flex-row align-items-center">
            <span class="me-2">Filter: </span>
            <input type="text" v-model="filter" style="width: 150px"/>
          </div>
        </div>
        <div id="result-set-list" class="base-list-box">
          <div class="list-box-scroller">
            <loading-indicator v-if="isLoading"/>
            <template v-else-if="noResultSets">No result sets have been generated. Click <a href="#" @click.prevent="emit('new')">New</a> to get started.</template>
            <div v-else class="item" :class="{ selected: selected != null ? selected.refId === item.refId : false }" v-for="(item, index) in filteredItems" :key="index" @click="selected = item" @contextmenu.prevent="displayContextMenu(item, $event)">
              <div class="item-inner" @dblclick="item_DblClick(item)">
                <span class="item-title">{{ item.name === '' ? '(untitled)' : item.name }}</span>
                <div class="badged">
                  <span v-if="item.status === 'error'" class="badge bg-danger">error</span>
                  <span v-if="item.status === 'pending'" class="badge bg-secondary">pending</span>
                  <span v-if="item.status === 'processing'" class="badge bg-info">processing</span>
                  <span v-if="item.status === 'publishing'" class="badge bg-info">publishing</span>
                  <span v-if="item.status === 'done'" class="badge bg-success">ready</span>
                </div>
                <span class="item-date">{{ item.date }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" v-if="userStore.subscription != null" @click="emit('new')">New...</button>
          <button class="btn btn-primary" :disabled="selected == null" @click="emit('open', selected)">Open</button>
          <button class="btn btn-primary" v-if="userStore.subscription != null" :disabled="selected == null" @click="emit('duplicate', selected)">New from...</button>
          <button class="btn btn-primary" :disabled="selected == null" @click="delete_Click">Delete</button>
          <button class="btn btn-primary" @click="resultSetList.refreshAsync()">Refresh</button>
        </div>
      </div>
    </div>
  </div>
  <context-menu ref="itemContextMenu">
    <context-menu-item command="open" @clicked="emit('open', selected)">Open</context-menu-item>
    <hr/>
    <context-menu-item command="copy" @clicked="copyDetails_Click">Copy reference details</context-menu-item>
  </context-menu>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref, toRef} from "vue";
import ContextMenuItem from "@/components/ContextMenu/ContextMenuItem";
import ContextMenu from "@/components/ContextMenu/ContextMenu";
import LoadingIndicator from "@/components/LoadingIndicator";
import {ResultSetList} from "@/lib/ResultSetList";
import {useUserStore} from "@/stores/userStore";

const userStore = useUserStore()

const emit = defineEmits([ 'new', 'view', 'duplicate' ])
const props = defineProps({
  visible: {
    type: Boolean,
    default: true,
    required: false
  },
  resultSetList: {
    type: ResultSetList,
    required: true
  }
})

// const resultSets = ref([])
const resultSetList = toRef(props, 'resultSetList')

const selected = ref(null)
const noResultSets = computed(() => resultSetList.value.empty)
const itemContextMenu = ref()
const isLoading = ref(false)

const filter = ref('')

let preventAutoRefresh = false

const filteredItems = computed(() => {
  const filterStr = (filter.value || '').toLowerCase()
  const rs = resultSetList.value
  if (filterStr === '')
    return rs.items
    // return resultSets.value
  return rs.items.filter(item => item.name.toLowerCase().indexOf(filterStr) > -1)
})

async function delete_Click() {
  preventAutoRefresh = true

  try {
    const selResult = selected.value

    if (selResult == null)
      return

    if (!confirm('Delete selected item?'))
      return

    selected.value = null
    await resultSetList.value.deleteAsync(selResult)
  }
  finally {
    preventAutoRefresh = true
  }
}

function item_DblClick(item) {
  selected.value = item
  emit('open', item)
}

async function refreshDataUsingIndicatorAsync() {
  isLoading.value = true
  try {
    await resultSetList.value.refreshAsync()
  }
  finally {
    isLoading.value = false
  }
}

function displayContextMenu(item, e) {
  selected.value = item
  itemContextMenu.value.show({x: e.clientX, y: e.clientY})
}

function copyDetails_Click() {
  const item = selected.value
  if (item == null)
    return
  const text = `Project id: ${item.projectId}\nRef id: ${item.refId}`
  navigator.clipboard.writeText(text)
}

onMounted(async () => {
  if (resultSetList.value.count === 0)
    await refreshDataUsingIndicatorAsync()
  else
    await resultSetList.value.refreshAsync()
})
</script>

<style scoped>
.data-dashboard {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.dashboard {
  height: 100%;
  width: 80%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin-top: initial !important;
}

#result-set-list {
  flex: 1;
}

#current-results {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.badge {
  margin-left: 1.5rem;
}

.item-inner {
  display: flex;
}

.item-title {
  font-size: 12pt;
}

.item-date {
  flex: 1;
  text-align: right;
}
</style>