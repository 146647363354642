<template>
  <div class="dialog attributes-dialog">
    <div class="dialog-background" @click="emit('close')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('close')">&times;</a></div>
      <h4>Asset attributes:</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(entry, index) in Object.entries(attributes)" :key="index">
            <td>{{entry[0]}}</td>
            <td>{{entry[1]}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits} from "vue";

const props = defineProps({
  attributes: {
    type: Object,
    required: false,
    default: null
  }
})

const emit = defineEmits(['close'])
</script>

<style scoped>
.attributes-dialog .dialog-content {
  min-width: 400px;
}
</style>