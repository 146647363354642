import { LatLng } from "@/lib/entities/Results";

class AssetSetItem {
    /** @type String */ name = ''
    /** @type LatLng */ latLng = null
    /** @type Objectt */ attributes = {}

    static fromObject(o) {
        let r = new AssetSetItem()
        r.name = o.name
        r.latLng = LatLng.fromObject(o.latLng)
        r.attributes = {...o.attributes}
        return r
    }
}

class AssetSet {
    /** @type String */ id = ''
    /** @type String */ name = ''
    /** @type AssetSetItem[] */ items = []

    static fromObject(o) {
        let r = new AssetSet()
        r.id = o.id
        r.name = o.name
        r.items = o.items.map(i => AssetSetItem.fromObject(i))
        return r
    }
}

class AssetSetReference {
    /** @type String */ id = ''
    /** @type String */ name = ''

    static fromObject(o) {
        let r = new AssetSetReference()
        r.id = o.id
        r.name = o.name
        return r
    }
}

export { AssetSetItem, AssetSet, AssetSetReference }