<template>
    <div class="extend-weather-tree no-select">
        <extend-weather-forecast-list-item v-for="(listItem, index) in forecastList.items" :key="index" :list-item="listItem" :session="session" @forecast-month-clicked="forecastMonth_Clicked" @historical-month-clicked="historicalMonth_Clicked"/>
    </div>
</template>

<script>
import { ForecastList } from "@/lib/extendWeather/ForecastObjects";
import ExtendWeatherForecastListItem from "@/components/ExtendWeatherPage/ExtendWeatherForecastListItem";

export default {
    name: "ExtendWeatherTree",
    components: { ExtendWeatherForecastListItem },
    props: {
        forecastList: ForecastList,
        session: String
    },
    methods: {
        forecastMonth_Clicked(forecast, varItem, month, index) {
            this.$emit('forecast-month-clicked', forecast, varItem, month, index)
        },
        historicalMonth_Clicked(forecast, varItem, month, index) {
            this.$emit('historical-month-clicked', forecast, varItem, month, index)
        },
    }
}
</script>

<style scoped>
.extend-weather-tree {
    
}
</style>