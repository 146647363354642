<script setup>
import { defineProps, defineEmits } from 'vue'
import {Subscription, UsageStats} from "@/lib/entities/Subscription";

const props = defineProps({
  sub: {
    type: Subscription,
    required: false,
    default: null
  },
  usageStats: {
    type: UsageStats,
    required: false,
    default: null
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['refresh'])

</script>

<template>
  <div class="subscription-info">
    <h4>Subscription</h4>
    <template v-if="sub != null">
      <p>
        {{sub.product.name}} ({{sub.scopeText}} based).
        Expires: {{sub.currentTerm.endDate.toISOString().split('T')[0]}}<br/>
      </p>
      <div class="points-used-root" v-if="sub.isPrepay && usageStats != null">
        <span>Usage: {{usageStats.totalPointsUsed}} of {{sub.getAvailablePoints()}} <a href="#" @click="emit('refresh')">Refresh</a></span>
        <div class="points-bar">
          <div class="points-progress-bar" :style="{width: ((usageStats.totalPointsUsed/sub.getAvailablePoints()) * 100).toFixed(2) + '%'}"/>
        </div>
      </div>
    </template>
    <template v-else>
      <p>Your account does not have an active subscription. Please <a href="mailto:info@climateinsights.global">contact us</a> to help get you set up.</p>
    </template>
  </div>
</template>

<style lang="scss" scoped>

@import '@/assets/std-theme.scss';

.subscription-info {
  box-sizing: border-box;
  .points-used-root {
    display: flex;
    flex-direction: row;
    align-items: center;

    .points-bar {
      flex: 1;
      background-color: white;
      position: relative;
      height: 24px;
      overflow: hidden;
      border-radius: 1rem;
      border: 1px solid $dark-gray;
      margin: 0 1rem;

      .points-progress-bar {
        background-color: $dark-green;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}

</style>