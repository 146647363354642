import BaseOpenSaveViewModel from "@/lib/viewModels/BaseOpenSaveViewModel";
import {useUserStore} from "@/stores/userStore";
import {useProjectStore} from "@/stores/projectStore";
import {deleteDeleteLayerGroupAsync, getLayerGroupsAsync, saveLayerGroupAsync} from "@/api/layerGroupApi";
import LayerGroup from "@/lib/entities/LayerGroup";

class BaseLayerGroupViewModel extends BaseOpenSaveViewModel {
    get canDelete() {
        return true
    }

    async getViewItemsAsync() {
        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        const data = await getLayerGroupsAsync(token, projectId)

        return LayerGroup.fromArray(data)
    }

    /**
     * @param {LayerGroup} viewItem
     * */
    async deleteAsync(viewItem) {
        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        await deleteDeleteLayerGroupAsync(token, projectId, viewItem.id)
    }

    /**
     * @param {LayerGroup} viewItem
     * */
    getItemText(viewItem) {
        return viewItem.name
    }
}

class OpenLayerGroupViewModel extends BaseLayerGroupViewModel {
    get canOpen() {
        return true
    }

    get title() {
        return 'Load Layer Group'
    }

    async loadAsync(viewItem) {
        // no load operation needed as viewItem is the LayerGroup
        return viewItem
    }
}

class SaveLayerGroupViewModel extends BaseLayerGroupViewModel {
    get canSave() {
        return true
    }

    get title() {
        return 'Save Layer Group'
    }

    /**
     * @param {String} name
     * @param {LayerGroup} viewItem
     * @param {LayerGroup} data
     */
    async saveAsync(name, viewItem, data) {
        if (name === '')
            throw new Error('name is empty')

        if (data == null)
            throw new Error('data is null')

        const userStore = useUserStore()
        const projectStore = useProjectStore()

        const token = userStore.token
        const projectId = projectStore.current.projectId

        data.name = name

        if (viewItem != null && viewItem.name === name) {
            data.id = viewItem.id
        }

        await saveLayerGroupAsync(token, projectId, data)
    }
}

export{ OpenLayerGroupViewModel, SaveLayerGroupViewModel }