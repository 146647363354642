<script setup>
import {PeriodDate, PeriodDateRange} from "@/lib/entities/UsageData";
import {ref, defineProps, defineEmits, onMounted, onUnmounted, computed} from 'vue'
import keyboard from "@/sys/keyboard";
import GroupMemberSummaryTable from "@/components/SettingsPage/components/GroupMemberSummaryTable.vue";
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import saveAs from 'file-saver'

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  showBillableData: {
    type: Boolean,
    required: false,
    default: false
  },
  members: {
    type: Array,
    required: true
  }
})

const kMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const showData = ref(true)
const showFlood = ref(true)
const groupByProject = ref(false)
const memberFilter = ref({})
const periodRange = ref({min: new PeriodDate(0, 0), max: new PeriodDate(0, 0)})
const minDate = ref(new Date())
const maxDate = ref(new Date())


function dateInFilter(date) {
  return date >= minDate.value && date <= maxDate.value
}

const filteredDataEntries = computed(() => {
  return props.data
      .map(d => {
        return {
          period: d.period,
          dataEntries: d.dataEntries.filter(d => userInFilter(d.userId) && dateInFilter(d.date)) }
      })
      .filter(d => d.dataEntries.length > 0)
      .sort((a, b) => -a.period.compareTo(b.period))
})

const filteredFloodEntries = computed(() => {
  return props.data
      .map(d => {
        return {
          period: d.period,
          floodEntries: d.floodEntries.filter(d => userInFilter(d.userId) && dateInFilter(d.date)) }
      })
      .filter(d => d.floodEntries.length > 0)
      .sort((a, b) => -a.period.compareTo(b.period))
})

const dataEntriesSummary = computed(() => getSummaryFromData(filteredDataEntries.value, 'dataEntries'))
const floodEntriesSummary = computed(() => getSummaryFromData(filteredFloodEntries.value, 'floodEntries'))

const emit = defineEmits(['close'])

function getDefaultMemberFilter() {
  let result = {}
  props.members.forEach(member => result[member.userId] = true)
  return result
}

function getSummaryFromData(data, entriesPropName) {
  const entries = data.map(a => a[entriesPropName]).flat()
  const result = {
    entries: entries,
    assets: entries.reduce((acc, curr) => acc + curr.assets, 0),
    uniqueAssets: entries.reduce((acc, curr) => acc + curr.uniqueAssets, 0),
    userIds: Array.from(new Set(entries.map(entry => entry.userId)))
  }
  return result
}

function updateMinMaxPeriod() {
  const periods = props.data.map(item => item.period)
  const {min, max} = PeriodDate.minMaxOf(periods)
  periodRange.value = new PeriodDateRange(min, max)
  minDate.value = min.toJSDate(false)
  maxDate.value = max.toJSDate(true)
}

onMounted(() => {
  keyboard.push('Escape', () => emit('close'))

  memberFilter.value = getDefaultMemberFilter()
  updateMinMaxPeriod()
})

onUnmounted(() => {
  keyboard.pop('Escape')
})

function lookupUser(userId) {
  const member = props.members.find(member => member.userId === userId)
  return member == null ? 'Unknown' : member.name
}

function userInFilter(userId) {
  return memberFilter.value[userId] === true
}

function formatDate(date) {
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  let year = date.getFullYear();

  if (month.length < 2)
  month = '0' + month;
  if (day.length < 2)
  day = '0' + day;

  return [year, month, day].join('-');
}

function groupEntriesByProject(entries) {
  let result = {}
  entries.forEach(entry => {
    const indexer = entry.projectName || '(none)'
    if (result[indexer] == null)
      result[indexer] = { assets: 0, uniqueAssets: 0 }
    result[indexer].assets += entry.assets
    result[indexer].uniqueAssets += entry.uniqueAssets
  })
  return result
}

const dataEntriesByProject = computed(() => {
  const data = filteredDataEntries.value
  return data.map(d => {
    return {
      period: d.period,
      items: groupEntriesByProject(d.dataEntries)
    }
  })
})

const floodEntriesByGroup = computed(() => {
  const data = filteredFloodEntries.value
  return data.map(d => {
    return {
      period: d.period,
      items: groupEntriesByProject(d.floodEntries)
    }
  })
})

function generateExport(delim = '\t') {
  let s = ''
  function appendLine(line='') { s += line + '\n' }
  function appendValues(arr=[]) {
    if (!Array.isArray(arr))
      throw new Error(`array expected, but found ${typeof arr}`)
    appendLine(arr.join(delim))
  }

  if (groupByProject.value) {
    appendLine('Data Entries:')
    appendLine()
    appendValues(['period', 'project', 'assets', 'unique_assets'])

    dataEntriesByProject.value.forEach(d => {
      const keys = Object.keys(d.items)
      keys.forEach(key => {
        const item = d.items[key]
        appendValues([d.period.toString(), key, item.assets, item.uniqueAssets])
      })
    })

    appendLine()

    appendLine('Flood Entries:')
    appendLine()
    appendValues(['period', 'project', 'assets', 'unique_assets'])

    floodEntriesByGroup.value.forEach(d => {
      const keys = Object.keys(d.items)
      keys.forEach(key => {
        const item = d.items[key]
        appendValues([d.period.toString(), key, item.assets, item.uniqueAssets])
      })
    })

  }
  else {
    appendLine('Data Entries:')
    appendLine()
    appendValues(['date', 'project', 'user', 'assets', 'unique_assets', 'variables', 'scenarios', 'percentiles'])

    filteredDataEntries.value.forEach(d => {
      d.dataEntries.forEach(entry => {
        appendValues([formatDate(entry.date), entry.projectName, lookupUser(entry.userId), entry.assets, entry.uniqueAssets, entry.variables, entry.curves, entry.percentiles])
      })
    })

    appendLine()

    appendLine('Flood Entries:')
    appendValues(['date', 'project', 'user', 'assets', 'unique_assets'])
    filteredFloodEntries.value.forEach(d => {
      d.floodEntries.forEach(entry => {
        appendValues([formatDate(entry.date), entry.projectName, lookupUser(entry.userId), entry.assets, entry.uniqueAssets])
      })
    })
  }

  return s
}

function export_Click() {
  const s = generateExport('\t')
  const tsvBlob = new Blob([s], {type: 'text/tab-separated-values;charset=utf-8;'})
  saveAs(tsvBlob, 'Usage Export.tsv')
}

function print_Click() {
  window.print()
}

</script>

<template>
  <div class="dialog">
    <div class="dialog-background"/>
    <div id="group-usage-content" class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('close')">&times;</a></div>
      <h3>View Usage</h3>

      <div class="content d-flex flex-fill flex-row">
        <div class="filters no-print" style="padding-right: 1rem">
          <h5>Group by</h5>
          <label><input type="checkbox" v-model="groupByProject"/> Group by Project</label>

          <hr/>

          <h5>Range</h5>
          <vue-date-picker v-model="minDate" :enable-time-picker="false" :clearable="false" />
          <vue-date-picker v-model="maxDate" :enable-time-picker="false" :clearable="false" />

          <hr/>

          <h5>Show</h5>
          <label><input type="checkbox" v-model="showData"/> Data entries</label><br/>
          <label><input type="checkbox" v-model="showFlood"/> Flood entries</label>

          <hr/>

          <h5>Members</h5>
          <div class="scrollable-host filter-box">
            <div class="scrollable">
              <template v-for="(member, index) in members" :key="index">
                <label ><input type="checkbox" v-model="memberFilter[member.userId]"> {{member.name}}</label><br/>
              </template>
            </div>
          </div>
        </div>
        <div class="usage-view scrollable-host printable">
          <div v-if="groupByProject" class="scrollable">
            <div class="data-table" v-if="showData">
              <h3>Data entries (by project)</h3>

              <group-member-summary-table :members="members" :summary="dataEntriesSummary"/>

              <template v-for="(d, index) in dataEntriesByProject" :key="index">
                <h5>{{kMonths[d.period.month-1]}} {{d.period.year}}</h5>
                <table class="table table-sm table-light">
                  <thead>
                  <tr>
                    <th>Project</th>
                    <th>Assets</th>
                    <th>Unique Assets</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, project) in d.items" :key="project">
                    <td>{{project}}</td>
                    <td>{{item.assets}}</td>
                    <td>{{item.uniqueAssets}}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>

            <hr v-if="showFlood && showData"/>

            <div class="data-table" v-if="showFlood">
              <h3>Flood entries (by project)</h3>

              <group-member-summary-table :members="members" :summary="floodEntriesSummary"/>

              <template v-for="(d, index) in floodEntriesByGroup" :key="index">
                <h5>{{kMonths[d.period.month-1]}} {{d.period.year}}</h5>
                <table class="table table-sm table-light">
                  <thead>
                  <tr>
                    <th>Project</th>
                    <th>Assets</th>
                    <th>Unique Assets</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in d.items" :key="index">
                    <td>{{index}}</td>
                    <td>{{item.assets}}</td>
                    <td>{{item.uniqueAssets}}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>

          </div>
          <div v-else class="scrollable">
            <div class="data-table" v-if="showData">
              <h3>Data entries</h3>

              <group-member-summary-table :members="members" :summary="dataEntriesSummary"/>

              <template v-for="(d, index) in filteredDataEntries" :key="index">
                <h5>{{kMonths[d.period.month-1]}} {{d.period.year}}</h5>
                <table class="table table-sm table-light">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Project</th>
                    <th>User</th>
                    <th>Assets</th>
                    <th v-if="showBillableData">Unique assets</th>
                    <th>Variables</th>
                    <th>Scenarios</th>
                    <th>Percentiles</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(entry, index) in d.dataEntries" :key="index">
                    <td>{{formatDate(entry.date)}}</td>
                    <td>{{entry.projectName}}</td>
                    <td>{{lookupUser(entry.userId)}}</td>
                    <td>{{entry.assets}}</td>
                    <td v-if="showBillableData">{{entry.uniqueAssets}}</td>
                    <td>{{entry.variables}}</td>
                    <td>{{entry.curves}}</td>
                    <td>{{entry.percentiles}}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{d.dataEntries.reduce((p,c)=>p+c.assets, 0)}}</td>
                    <td v-if="showBillableData">{{d.dataEntries.reduce((p,c)=>p+c.uniqueAssets, 0)}}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>
            <hr v-if="showFlood && showData"/>
            <div class="flood-table" v-if="showFlood">
              <h4>Flood entries</h4>

              <group-member-summary-table :members="members" :summary="floodEntriesSummary"/>

              <template v-for="(d, index) in filteredFloodEntries" :key="index">
                <h5>{{kMonths[d.period.month-1]}} {{d.period.year}}</h5>
                <table class="table table-sm table-light">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Project</th>
                    <th>User</th>
                    <th>Assets</th>
                    <th v-if="showBillableData">Unique assets</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(entry, index) in d.floodEntries" :key="index">
                    <td>{{formatDate(entry.date)}}</td>
                    <td>{{entry.projectName}}</td>
                    <td>{{lookupUser(entry.userId)}}</td>
                    <td>{{entry.assets}}</td>
                    <td v-if="showBillableData">{{entry.uniqueAssets}}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{d.floodEntries.reduce((p,c)=>p+c.assets, 0)}}</td>
                    <td v-if="showBillableData">{{d.floodEntries.reduce((p,c)=>p+c.uniqueAssets, 0)}}</td>
                  </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="button-strip-container">
          <div class="button-strip"/>
          <div class="button-strip">
            <button class="btn btn-primary" @click="emit('close')">Close</button>
            <button class="btn btn-secondary" @click="export_Click">Export...</button>
            <button class="btn btn-secondary" @click="print_Click">Print</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@media print {
  #group-usage-content {
    display: block !important;
  }
}

@media screen {
  .dialog-content {
    width: 90%;
    height: 90%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;

    .filter-box {
      min-height: 200px;
      border: 1px solid #eee;
    }

    .content {

      .filters {
        width: 200px;
      }

      .usage-view {
        flex: 1;
      }

      table.table {
        td, th {
          background-color: initial !important;
        }
      }
    }
  }
}
</style>