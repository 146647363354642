import BaseResultSetExporter from "@/lib/dataExport/resultSet/BaseResultSetExporter";
import {saveTextToFile} from "@/lib/utils/FileUtils";
import strUtils from "@/lib/utils/StrUtils";
import {useProjectStore} from "@/stores/projectStore";
import config from "@/sys/config";
import {useViewSettingsStore} from "@/stores/settingsStore";

/**
 * @param sep
 * @param {ResultSetReader} reader
 * @param {TimeHorizon} timeHorizon
 */
async function produceCSVType2OutputAsync(sep, reader, timeHorizon) {
    const projectStore = useProjectStore()
    const settingsStore = useViewSettingsStore()

    const project = projectStore.all.find(p => p.projectId === reader.info.projectId).name

    let s = strUtils.csvCombine(['Client', 'Asset', 'Parameter', 'Unit', 'Scenario', 'Percentile', 'Year', 'Event', 'Value'], sep)

    for (let i=0; i < reader.info.assets.length; i++) {
        const asset = reader.info.assets[i]
        const ar = await reader.getAssetResultsAsync(asset.assetId)
        ar.tables.forEach(table => {
            const units = table.units
            const pcnt = table.percentile
            const curve = table.curve
            table.data.rows.filter(row => timeHorizon.includes(row.year)).forEach(row => {
                for(let colIndex = 0; colIndex < row.values.length; colIndex++) {
                    const arr = [
                        project,
                        asset.name,
                        table.varId.replace('.', '_'),
                        units,
                        curve,
                        pcnt,
                        row.year,
                        table.data.columns[colIndex],
                        row.values[colIndex].toFixed(settingsStore.dp)
                    ]
                    s += '\n'
                    s += strUtils.csvCombine(arr, sep)
                }
            })
        })
    }

    return s
}

export default class ResultSetCSVType2Exporter extends BaseResultSetExporter {
    async exportAsync(reader) {
        const s = await produceCSVType2OutputAsync(',', reader, this.timeHorizon)
        saveTextToFile(s, reader.info.name + ' results.csv')
    }
}

export { produceCSVType2OutputAsync }