<template>
  <select v-model="mapping" class="form-control">
    <option v-for="colMapping in colMappings" v-bind:key="colMapping.mapping" v-bind:value="colMapping.mapping">{{ colMapping.name }}</option>
  </select>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch} from "vue";
import {ColMappingNames, ImportDataSourceColumn} from "@/lib/importing/ImportDataSource";

const props = defineProps({
  initialMapping: String
})

const emit = defineEmits(['change'])
const mapping = ref(props.initialMapping)

watch(mapping, (newVal) => {
  emit('change', newVal)
})

const colMappings = [
  { name: 'Name', mapping: ColMappingNames.Name },
  { name: 'Latitude', mapping: ColMappingNames.Lat },
  { name: 'Longitude', mapping: ColMappingNames.Lng },
  { name: 'Attribute', mapping: ColMappingNames.Attr },
  { name: 'Ignore', mapping: ColMappingNames.Ignore }
]

</script>

<style scoped>

</style>