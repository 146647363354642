import config from "@/sys/config";
import {postJsonAsync, postAsync, statusCodes, getAsync} from "@/api/apiUtils";
import LoginResponseData from "@/lib/entities/LoginResponseData";
import Strings from "@/lib/lang/Strings";
import {UsageData} from "@/lib/entities/UsageData";
import {Subscription, UsageStats} from "@/lib/entities/Subscription";

/**
 * @param {string} username
 * @param {string} password
 * @param {Boolean} rememberMe
 * @return {Promise<LoginResponseData>}
 */
async function loginAsync(username, password, rememberMe) {
    if (username == null) throw new Error('username is null')
    if (password == null) throw new Error('password is null')

    const url = config.SERVICE_URL + 'users/login'
    const data = { username, password, rememberMe }
    const resp = await postJsonAsync(url, data)
    if (resp.status === 200)
    {
        const lrdData = await resp.json()
        return LoginResponseData.fromResponse(lrdData)
    }
    if (resp.status === statusCodes.notFound)
        throw new Error(Strings.RESPERR_USER_NOT_FOUND)
    throw new Error('Login error')
}

async function changePasswordAsync(token, currentPassword, newPassword) {
    if (token == null) throw new Error('token is null')
    if (currentPassword == null) throw new Error('currentPassword is null')
    if (newPassword == null) throw new Error('newPassword is null')

    const data = { currentPassword, newPassword }

    const url = config.SERVICE_URL + 'users/password'

    const resp = await postJsonAsync(url, data, token)

    if (resp.status !== 200) {
        const info = await resp.json()
        let errorInfo = 'Unknown error'
        switch(info.error) {
            case 'user_not_found': errorInfo = 'User not found'; break;
            case 'validation_error': errorInfo = 'Current password does not match'; break;
            case 'requirements_error': errorInfo = 'New password doesn\'t meet requirements'; break;
        }
        throw new Error(`There was an error changing password: ${errorInfo}`)
    }
}

async function logOutAsync(token) {
    if (token == null) throw new Error('token is null')

    const url = config.SERVICE_URL + 'users/logout'

    await postAsync(url, null, null, token)
}

async function signupAsync(data) {
    if (typeof data !== 'object')
        throw new Error('Invalid signup data object')

    const url = config.SERVICE_URL + 'users/create'
    const resp = await postJsonAsync(url, data)

    if (resp.status === 200)
        return

    switch(resp.status)
    {
        case 409:
            throw new Error('account_already_exists')
        default:
            throw new Error('signup_error')
    }
}

export async function getUsageAsync(token) {
    const url = config.SERVICE_URL + 'users/usage'
    const resp = await getAsync(url, null, token)
    if (resp.status !== 200) {
        throw new Error('Failed to get usage data')
    }
    return UsageData.fromArray(await resp.json())
}

export async function requestResetPasswordAsync(emailAddress) {
    const url = config.SERVICE_URL + 'users/request-password-reset'
    const data = { emailAddress }
    const resp = await postJsonAsync(url, data)
    if (resp.status !== 200) {
        throw new Error('Failed to make password reset request')
    }
}

export async function resetPasswordAsync(emailAddress, newPassword, token) {
    const url = config.SERVICE_URL + 'users/reset-password'
    const data = { emailAddress, newPassword, token }
    const resp = await postJsonAsync(url, data)
    if (resp.status !== 200) {
        throw new Error('Failed to reset password')
    }
}

export async function getSubscriptionInfoAsync(token) {
    const url = config.SERVICE_URL + 'users/subscription/info'
    const resp = await getAsync(url, null, token)
    if (resp.status !== 200) {
        throw new Error('Failed to get subscription info')
    }
    const data = await resp.json()
    return {
        info: new Subscription(data.info),
        stats: new UsageStats(data.stats)
    }
}

export {
    loginAsync,
    changePasswordAsync,
    logOutAsync,
    signupAsync
}