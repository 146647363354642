<template>
  <ul class="navbar-nav d-flex justify-content-end no-print">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle no-print" href="#" data-bs-toggle="dropdown" ref="userMenuToggle" @click.prevent="toggleUserMenu">
        <img style="width: 32px" src="@/assets/images/user.svg" alt="User management section"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right">
        <li v-if="config.IS_DEBUG">
          <a href="#" class="dropdown-item" @click.prevent="copyUserToken_Click">Copy User Token</a>
        </li>
        <router-link :to="{ name: 'settings' }" class="dropdown-item" :class="{ active: route.name === 'settings' }">{{ Strings.SETTINGS_AND_PROFILE }}</router-link>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" @click="userStore.logOut()">Log out</a>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { useUserStore } from "@/stores/userStore";
import {useRoute} from "vue-router";
import Strings from "@/lib/lang/Strings";
import {onMounted, ref} from "vue";
import {Dropdown} from "bootstrap";
import config from "@/sys/config";

const userStore = useUserStore()
const route = useRoute()

const userMenuToggle = ref()

function initDropdowns() {
  userMenuToggle.value.dropdownObj = new Dropdown(userMenuToggle.value)
}

function toggleUserMenu() {
  userMenuToggle.value.dropdownObj.toggle()
}

function copyUserToken_Click() {
  navigator.clipboard.writeText(userStore.token)
}

onMounted(() => {
  initDropdowns()
})

</script>

<style scoped>

</style>