<template>
  <div class="extend-weather-map">
    <leaflet-wrapper ref="leaflet" class="leaflet-wrapper"  @map-clicked="map_Clicked"/>
    <div class="title no-select">
      <span class="image-type-title">{{ imageType }}</span>
      <span class="var-name-title">{{ varName }}<template v-if="varUnits !== ''"> ({{ varUnits }})</template></span>
      <span class="month-name-title">{{ monthName }}</span>
      <div class="close-button"><a href="#" @click.prevent="close_Clicked">&times;</a></div>
    </div>
    <div class="legend no-select">
      <div class="color-class" v-for="(colorClass, index) in varItem.legend.colorClasses" :key="index">
        <div class="color" :style="{ backgroundColor: colorClass.color }"></div>
        <div class="label">{{ colorClass.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LeafletWrapper from "@/components/Mapping/LeafletWrapper"
import ExtendWeatherPointValues from "@/components/ExtendWeatherPage/ExtendWeatherPointValues";
import { Forecast, ForecastVarItem } from "@/lib/extendWeather/ForecastObjects";
import {useUserStore} from "@/stores/userStore";
import 'leaflet/dist/leaflet.css'

export default {
  name: "ExtendWeatherMap",
  components: { LeafletWrapper },
  data() {
    return {
      userStore: useUserStore()
    }
  },
  props: {
    mapId: Number,
    imageType: String,   // 'Forecast' or 'Historical'
    monthName: String,
    varName: String,
    varUnits: String,
    imageUrl: String,
    imageBounds: Object,
    forecast: Forecast,
    varItem: ForecastVarItem,
    fid: String,
    session: String,
  },
  methods: {
    close_Clicked() {
      this.$emit('on-close', this.mapId)
    },
    map_Clicked(args) {
      let marker = this.$refs.leaflet.addMarker(args.lat, args.lng)

      const rootEl = document.createElement('div')
      const comp = ExtendWeatherPointValues.create(this.userStore.token,
          this.session, this.forecast, this.fid, this.varItem, [args.lat, args.lng], rootEl)
      let popup = marker.bindPopup(rootEl)
      popup.openPopup()
    }
  },
  mounted() {
    let ib = this.imageBounds

    this.$refs.leaflet.setRasterLayer(this.imageUrl, ib)

    let lat = ib.bottom + (ib.top - ib.bottom) / 2
    let lng = ib.left + (ib.right - ib.left) / 2

    this.$refs.leaflet.panTo([lat, lng])
  }
}
</script>

<style scoped>
.extend-weather-map {
  display: flex;
  position: relative;
  border: 1px solid #999;
}

.extend-weather-map .leaflet-wrapper {
  flex: 1;
}

.extend-weather-map .title {
  z-index: 900;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.8));
  font-size: 10pt;
  color: #223548;
  padding: 0.3rem;
  padding-right: 1.5rem;
  pointer-events: none;
}

.extend-weather-map .title span {
  margin-right: 1rem;
}

.extend-weather-map .title .close-button {
  position: absolute;
  right: 0.5rem;
  top: -3px;
  pointer-events: initial;
}

.extend-weather-map .title .close-button a {
  color: black !important;
  text-decoration: none;
  font-weight: bold;
  font-size: 14pt;
}

.extend-weather-map .title .close-button a:hover {
  text-decoration: none;
}

.legend {
  z-index: 900;
  position: absolute;
  left: 50px;
  bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0.4rem;
  border-radius: 0.4rem;
  font-size: 8pt;
  pointer-events: none;
}

.legend .color-class {
  display: flex;
  padding: 0.1rem 0;
}

.legend .color-class .color {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.legend .color-class .label {
  flex: 1;
}
</style>