import strings from "@/lib/lang/Strings";

function formatCurveName(curveCode) {
    const s = curveCode.toString()
    if (s.length !== 3)
        return s

    return `${strings.CURVE_TYPE}${s[0]}-${s[1]}.${s[2]}`
}

export { formatCurveName }