import {defineStore} from "pinia/dist/pinia";
import {getVarCategoriesAsync, getVarIndexAsync, getVarsUsingSubAsync} from "@/api/resultSetApi";

export const useVarItemsStore = defineStore({
    id: 'varItems',
    state: () => {
        return {
            index: null,
            /** @type VarCategories */ categories: null,
            loadFailed: false
        }
    },
    getters: {
        isLoaded() {
            return this.index != null
        }
    },
    actions: {
        async updateAsync() {
            try {
                const varIndex = await getVarIndexAsync()
                const categories = await getVarCategoriesAsync()

                this.index = varIndex
                this.categories = categories
            }
            catch(error) {
                this.loadError = true
            }
        },

        async updateUsingSubAsync(token) {
            const varIndex = await getVarsUsingSubAsync(token)
            const categories = await getVarCategoriesAsync()

            console.log('updateUsingSubAsync', varIndex, categories)

            this.index = varIndex
            this.categories = categories
        }
    }
})