const kJsonHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

const kBinaryHeaders = {
    'Accept': 'application/octet-stream',
    'Content-Type': 'application/octet-stream'
}

export function updateHeaders(headers, authToken) {
    if (headers == null) headers = {}
    let newHeaders = Object.assign({}, headers)
    if (authToken !== undefined)
        newHeaders['Authorization'] = 'Bearer ' + authToken
    return newHeaders
}

export async function postAsync(url, body, headers, authToken = undefined) {
    headers = updateHeaders(headers, authToken)
    return await fetch(url, {
        method: 'post',
        body,
        headers: headers
    })
}

export async function patchAsync(url, body, headers, authToken = undefined) {
    headers = updateHeaders(headers, authToken)
    return await fetch(url, {
        method: 'patch',
        body,
        headers: headers
    })
}

export async function putAsync(url, body, headers, authToken = undefined) {
    headers = updateHeaders(headers, authToken)
    return await fetch(url, {
        method: 'put',
        body,
        headers: headers
    })
}

export async function deleteAsync(url, headers, authToken = undefined) {
    headers = updateHeaders(headers, authToken)
    return await fetch(url, {
        method: 'delete',
        headers: headers
    })
}

export async function getAsync(url, headers, authToken = undefined) {
    headers = updateHeaders(headers, authToken)
    return await fetch(url, {
        method: 'get',
        headers: headers
    })
}

export async function getBinaryAsync(url, authToken = undefined) {
    return await getAsync(url, kBinaryHeaders, authToken)
}

export async function postJsonAsync(url, data, authToken = undefined) {
    return await postAsync(url, JSON.stringify(data), kJsonHeaders, authToken)
}

export async function patchJsonAsync(url, data, authToken = undefined) {
    return await patchAsync(url, JSON.stringify(data), kJsonHeaders, authToken)
}

export async function putJsonAsync(url, data, authToken = undefined) {
    return await putAsync(url, JSON.stringify(data), kJsonHeaders, authToken)
}

export async function getJsonAsync(url, authToken = undefined) {
    return await getAsync(url, kJsonHeaders, authToken)
}

export const statusCodes = {
    ok: 200,
    notFound: 404
}