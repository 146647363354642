export default {
    padStr(padChar, desiredLen, str) {
        let result = str
        if (padChar.length > 0 && desiredLen > str.length) {
            while(result.length < desiredLen)
                result = padChar + result
        }
        return result
    },

    subStr(str, start, len) {
        if (typeof str != 'string' || start < 0 || len < 0)
            throw new Error('Invalid parameters in call to subStr')
        let end = start + len
        return str.substring(start, end)
    },

    isWhiteSpaceChar(c) {
        if (!c || c.length !== 1)
            return false

        return c === ' ' || c === '\t' || c === '\n' || c === '\r'
    },

    isNullOrEmpty(s) {
        return (!s || s === '')
    },

    isNullOrWhiteSpace(s) {
        if (this.isNullOrEmpty(s))
            return true

        for(var i=0; i < s.length; i++)
            if (!this.isWhiteSpaceChar(s.charAt(i)))
                return false

        return true
    },

    arraysEqual(a1, a2) {
        if (!Array.isArray(a1) || !Array.isArray(a2))
            return false

        if (a1.length !== a2.length)
            return false

        for(let i = 0; i < a1.length; i++) {
            if (a1[i] !== a2[i])
                return false
        }

        return true
    },

    ymdToDate(s) {
        let result = null
        let sp = s.split('-')

        if (sp.length === 3) {
            let y = Number.parseInt(sp[0])
            let m = Number.parseInt(sp[1])
            let d = Number.parseInt(sp[2])

            if (!Number.isNaN(y) &&
                !Number.isNaN(m) &&
                !Number.isNaN(d))
            {
                result = new Date(y, (m-1), d)
            }
        }
        return result
    },

    addMonths(date, count) {
        let year = date.getFullYear()
        let month = date.getMonth()
        let day = date.getDate()

        return new Date(year, month + Number(count), day)
    },

    getRange(items, startIndex, count) {
        if (!Array.isArray(items))
            return []

        if (count === undefined)
            count = items.length

        if (count <= 0)
            return []

        count = Math.min(count, items.length)
        let endIndex = count - startIndex

        let result = []
        for(let i=startIndex; i < endIndex; i++) {
            result.push(items[i])
        }

        return result
    }    
}