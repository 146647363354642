<template>
  <div class="options-selector">
    <h6>{{ Strings.CURVES_LABEL }}:</h6>
    <div class="curves">
      <div class="curve-item" v-for="(item, index) in curvesModel" :key="index">
        <label><input type="checkbox" v-model="item.checked"> {{ formatCurveName(item.text) }}</label>
      </div>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-secondary btn-sm" @click="selectAll(curvesModel, true)">Select all</button>
          <button class="btn btn-secondary btn-sm" @click="selectAll(curvesModel, false)">Select none</button>
        </div>
      </div>
    </div>
    <hr/>
    <h6>{{ Strings.PERCENTILE_LABEL }}s:</h6>
    <div class="curves">
      <div class="curve-item" v-for="(item, index) in percentileModel" :key="index">
        <label><input type="checkbox" v-model="item.checked"> {{ item.text }}</label>
      </div>
    </div>
    <div class="button-row">
      <div class="button-strip">
        <button class="btn btn-secondary btn-sm" @click="selectAll(percentileModel, true)">Select all</button>
        <button class="btn btn-secondary btn-sm" @click="selectAll(percentileModel, false)">Select none</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {reactive, defineEmits, defineExpose, defineProps, ref} from "vue";
import {useVarItemsStore} from "@/stores/varItemStore";
import Strings from "@/lib/lang/Strings";
import { formatCurveName } from "@/lib/utils/curveName";

const varItemStore = useVarItemsStore()

const emit = defineEmits(['selectPage'])
const props = defineProps({
  showSelectAssetsButton: {
    type: Boolean,
    default: true,
    required: false
  }
})

const curvesModel = reactive(varItemStore.index.sspList.map(ssp => {
  return {
    checked: false,
    value: ssp,
    text: ssp.toString()
  }
}))

const percentileModel = reactive([
  {
    checked: false,
    value: 5,
    text: '5th percentile'
  },
  {
    checked: true,
    value: 50,
    text: '50th percentile'
  },
  {
    checked: false,
    value: 95,
    text: '95th percentile'
  }
])

function selectAll(model, checked) {
  model.forEach(i => i.checked = checked)
}

function getData() {
  return {
    percentiles: percentileModel.filter(p => p.checked).map(p => p.value),
    curves: curvesModel.filter(c => c.checked).map(c => c.value)
  }
}

function setData(curves, percentiles) {
  curvesModel.forEach(item => {
    item.checked = curves.includes(item.value.toString())
  })

  percentileModel.forEach(item => {
    item.checked = percentiles.includes(item.value)
  })
}

function selectCurves(curves) {
  curvesModel.forEach(c => {
    c.checked = curves.includes(c.value.toString())
  })
}

function selectPercentiles(percentiles) {
  percentileModel.forEach(p => {
    p.checked = percentiles.includes(p.value.toString())
  })
}

defineExpose({
  getData,
  setData,
  selectCurves,
  selectPercentiles
})

</script>

<style scoped>

</style>