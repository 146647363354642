<template>
  <div class="project-list">
    <div v-if="showFilterBar" class="filter-bar">
      <div class="filter-bar-content">
        <slot name="filter-bar-content"/>
      </div>
      <div class="filter">
        <label for="filter">Filter:</label>
        <input type="text" v-model="filter" id="filter">
      </div>
    </div>

    <div class="base-list-box projects">
      <div class="list-box-scroller">
        <div class="item" :class="{ selected: project === selected }" v-for="(project, index) in filteredProjects" :key="index" @click="selected = project" @dblclick="open_Click">
          {{ project.name }}
        </div>
      </div>
    </div>

    <div class="button-row">
      <div class="button-strip-container">
        <div class="button-strip">
          <button class="btn btn-primary" @click="open_Click">Open Project</button>
        </div>

        <div class="button-strip">
          <button class="btn btn-primary" @click="showCreateProject = true">Create</button>
          <button :disabled="projectIsSelected === false" class="btn btn-secondary" @click="deleteProject_Click">Delete</button>
          <button :disabled="projectIsSelected === false" class="btn btn-secondary" @click="showUpdateProject = true">Update</button>
        </div>
      </div>
    </div>

    <create-project-dialog v-if="showCreateProject" @cancel="showCreateProject = false" @project-created="projectCreated" />
    <create-project-dialog v-if="showUpdateProject && selected != null" @cancel="showUpdateProject = false" @project-updated="projectUpdated" :project="selected" />
  </div>
</template>

<script setup>

import {ref, computed, onMounted, defineProps} from "vue";
import {deleteProjectAsync, getProjectsAsync} from "@/api/projectsApi";
import {useUserStore} from "@/stores/userStore";
import {useProjectStore} from "@/stores/projectStore";
import CreateProjectDialog from "@/components/ProjectsPage/CreateProjectDialog.vue";
import router from "@/sys/router";

const userStore = useUserStore()
const projectsStore = useProjectStore()

const props = defineProps({
  showFilterBar: {
    type: Boolean,
    default: true,
    required: false
  }
})

const filter = ref('')
const selected = ref(null)
const showCreateProject = ref(false)
const showUpdateProject = ref(false)

onMounted(async () => {
  await updateProjectListAsync()
  selected.value = projectsStore.current
})

function projectCreated() {
  showCreateProject.value = false
  updateProjectListAsync()
}

function projectUpdated() {
  showUpdateProject.value = false
  updateProjectListAsync()
}

async function updateProjectListAsync() {
  projectsStore.updateProjects(await getProjectsAsync(userStore.token), false)
}

function open_Click() {
  const project = selected.value
  if (project == null) return
  projectsStore.current = project
  router.push({name: 'data'})
}

async function deleteProject_Click() {
  const project = selected.value
  if (project == null) return
  if (!confirm(`Delete project "${project.name}"?`)) return
  await deleteProjectAsync(userStore.token, project.projectId)
  selected.value = null
  await updateProjectListAsync()
}

const filteredProjects = computed(() => {
  const filterStr = filter.value.toLowerCase()

  let arr = []
  if (filterStr === '')
    arr = Array.from(projectsStore.all)
  else
    arr = projectsStore.all.filter(p => p.name.toLowerCase().indexOf(filterStr) > -1)

  return arr.sort((a, b) => a.name.localeCompare(b.name))
})

const projectIsSelected = computed(() => filteredProjects.value.includes(selected.value))

</script>

<style scoped>
.project-list {
  display: flex;
  flex-direction: column;
}

.projects {
  flex: 1;
}

.filter-bar {
  display: flex;
  align-items: center;
}

.filter {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.filter label {
  margin-right: 1rem;
}

.filter input {
  max-width: 140px;
}
</style>