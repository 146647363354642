<script setup>
import {useUserStore} from "@/stores/userStore";
import { ref, computed } from 'vue'
import ManageGroupDialog from "@/components/SettingsPage/dialogs/ManageGroupDialog.vue";
const userStore = useUserStore()
const selected = ref(null)
const showManage = ref(false)

function manage_Click() {
  if (selected.value == null)
    return

  showManage.value = true
}

const selectedGroup = computed(() => {
  return userStore.groups.find(group => group.id === selected.value)
})

</script>

<template>
  <div class="groups-page flex-fill d-flex flex-column no-print">
    <h5>Groups</h5>

    <p>Your account is a member of the following groups:</p>

    <div class="base-list-box flex-fill">
      <div class="list-box-scroller">
        <div v-for="group in userStore.groups" :key="group.id" class="item" :class="{selected: selected === group.id}" @click="selected = group.id">
          {{group.name}}
        </div>
      </div>
    </div>

    <div class="button-row">
      <button class="btn btn-primary" @click.prevent="manage_Click">Manage</button>
    </div>
  </div>

  <manage-group-dialog v-if="showManage" :group="selectedGroup" @close="showManage = false" />
</template>

<style scoped>

</style>