import {formatVarName} from "@/lib/utils/varNameUtils";
import StrUtils from "@/lib/utils/StrUtils";

class Bounds {
    left = 0
    top = 0
    right = 0
    bottom = 0

    static fromObject(o) {
        let result = new Bounds()
        result.left = o[0]
        result.top = o[1]
        result.right = o[2]
        result.bottom = o[3]
        return result
    }
}

class Availability {
    region = ''
    bounds = null

    static fromObject(o) {
        let result = new Availability()
        result.region = o.region
        result.bounds = Bounds.fromObject(o.bounds)
        return result
    }
}

class VarIndexItem {
    name = ''
    id = ''
    jId = ''
    availability = []

    static fromObject(o) {
        let result = new VarIndexItem()
        result.id = o.id
        result.jId = o.jId
        result.availability = o.availability.map(a => Availability.fromObject(a))
        result.name = formatVarName(result.id)
        return result
    }
}

class VarIndex {
    updated = ''
    sspList = []
    variables = []

    static fromObject(o) {
        let result = new VarIndex()
        result.updated = o.updated || null
        result.sspList = Array.from(o.sspList || [])
        result.variables = (o.variables || []).map(v => VarIndexItem.fromObject(v)).sort((a, b) => {
            return StrUtils.compare(a.id, b.id)
        })
        return result
    }
}

export default VarIndex