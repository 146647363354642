<template>
  <div id="result-set-builder" class="flex-page">
    <div class="window-controls">
      <a href="#" @click.prevent="emit('close')">&times;</a>
    </div>
    <h4>Create Result Set</h4>
    <div class="result-set-name">
      <div class="label">
        <label for="resultSetName">Result set name:</label>
      </div>
      <div class="editor">
        <input id="resultSetName" type="text" v-model="resultSetName">
      </div>
    </div>
    <div class="result-set-tabs">
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a href="#" class="nav-link" :class="{active: page === 'options'}" @click.prevent="page = 'options'"><strong>Step 1:</strong> Data options</a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link" :class="{active: page === 'assets'}" @click.prevent="page = 'assets'"><strong>Step 2:</strong> Select assets</a>
        </li>
      </ul>
    </div>
    <div class="result-set-content">
      <div class="page options-page" v-show="page === 'options'">
        <var-selector class="var-selector" ref="varSelectorRef" @load-layer-group="showOpenLayerGroup = true" @save-layer-group="showSaveLayerGroup = true"/>
        <options-selector ref="optionsSelectorRef" class="options-selector" @select-page="doSelectPage" />
      </div>

      <div class="page asset-page" v-show="page === 'assets'">
        <asset-selector class="asset-selector"
                        :showing="page === 'assets'"
                        ref="assetSelectorRef"/>
      </div>
    </div>
    <div class="result-set-buttons d-flex flex-row justify-content-between">
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-secondary" @click.prevent="page = 'assets'" v-if="page !== 'assets'">Next</button>
          <button class="btn btn-secondary" @click.prevent="page = 'options'" v-if="page !== 'options'">Previous</button>
        </div>
      </div>

      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click="save_Clicked">Run</button>
          <button class="btn btn-secondary" @click="emit('close')">Cancel</button>
        </div>
      </div>

    </div>
    <open-save-dialog v-if="showOpenLayerGroup" :view-model="openLayerGroupViewModel" @cancel="showOpenLayerGroup = false" @loaded="openLayerGroup_Loaded"></open-save-dialog>
    <open-save-dialog v-if="showSaveLayerGroup" :view-model="saveLayerGroupViewModel" :save-data="createLayerGroup" @cancel="showSaveLayerGroup = false" @saved="showSaveLayerGroup = false"></open-save-dialog>
    <loading-indicator v-if="isLoading"/>
  </div>
</template>

<script setup>
import {defineEmits, ref, toRaw, defineExpose, defineProps, onMounted, computed} from "vue";
import OpenSaveDialog from "@/dialogs/OpenSaveDialog";
import VarSelector from "@/components/DataPage/Builder/VarSelector";
import OptionsSelector from "@/components/DataPage/Builder/OptionsSelector";
import AssetSelector from "@/components/DataPage/Builder/AssetSelector";
import {NewAsset, NewResultSet} from "@/lib/entities/NewResultSet";
import {useProjectStore} from "@/stores/projectStore";
import {LatLng} from "@/lib/entities/Results";
import {useUserStore} from "@/stores/userStore";
import {createResultSetAsync} from "@/api/resultSetApi";
import {OpenLayerGroupViewModel, SaveLayerGroupViewModel} from "@/lib/viewModels/layerGroupViewModel";
import LayerGroup from "@/lib/entities/LayerGroup";
import LoadingIndicator from "@/components/LoadingIndicator";

const projectStore = useProjectStore()
const userStore = useUserStore()

const props = defineProps({
  duplicateFrom: {
    required: false,
    type: NewResultSet,
    default: null
  }
})

onMounted(() => {
  const dupFrom = props.duplicateFrom
  if (dupFrom)
    setValues(dupFrom)
})

const showOpenLayerGroup = ref(false);
const showSaveLayerGroup = ref(false);

const openLayerGroupViewModel = ref(new OpenLayerGroupViewModel())
const saveLayerGroupViewModel = ref(new SaveLayerGroupViewModel())

const emit = defineEmits(['close'])
const page = ref('options')

const optionsSelectorRef = ref()
const assetSelectorRef = ref()
const varSelectorRef = ref()
const resultSetName = ref('')
const isLoading = ref(false)

function doSelectPage(newPage) {
  page.value = newPage
}

function buildNewResultSet() {
  const options = optionsSelectorRef.value.getData()
  const assets = assetSelectorRef.value.getData()
  const vars = varSelectorRef.value.getData()

  let newResSet = new NewResultSet()
  newResSet.curves = options.curves
  newResSet.percentiles = options.percentiles
  newResSet.name = toRaw(resultSetName.value)
  newResSet.variables = vars
  newResSet.projectId = projectStore.current.projectId
  newResSet.assets = assets.map(a => {
    let newAsset = new NewAsset()
    newAsset.name = a.name
    newAsset.latLng = LatLng.fromObject(a.latLng)
    newAsset.attributes = toRaw(a.attributes)
    return newAsset
  })

  return newResSet
}

/** @param {NewResultSet} values */
function setValues(values) {
  resultSetName.value = values.name + ' (duplicate)'
  optionsSelectorRef.value.setData(values.curves, values.percentiles)
  varSelectorRef.value.setData(values.variables)
  assetSelectorRef.value.setData(values.assets)
}

function validateResultSet(newResultSet) {
  if (newResultSet.name === '') {
    alert('Please enter a result set name')
    return false
  }

  if (newResultSet.variables.length === 0) {
    alert('Please select one or more variables')
    return false
  }

  if (newResultSet.assets.length === 0) {
    alert('Please create one or more assets')
    return false
  }

  if (newResultSet.curves.length === 0) {
    alert('Please select one or more curves')
    return false
  }

  if (newResultSet.percentiles.length === 0) {
    alert('Please select one or more percentiles')
    return false
  }

  return true
}

async function save_Clicked() {
  const newResultSet = buildNewResultSet()

  if (!validateResultSet(newResultSet))
    return

  try {
    isLoading.value = true
    try {
      await createResultSetAsync(userStore.token, newResultSet)
      emit('close')
    }
    finally {
      isLoading.value = false
    }
  }
  catch(error) {
      if (error != null && typeof error.message === 'string' && error.message.startsWith('payment.error:')) {
          const paymentErrorType = error.message.substring(error.message.indexOf(':')+1)
          switch(paymentErrorType) {
              case 'trial_expired': {
                  alert('Unable to process because your trial has expired')
                  return
              }
              case 'region_limit': {
                  alert('Unable to process because all selected points are outside the boundary of your trial')
                  return
              }
              case 'monthly_limit_exceeded': {
                  alert('Unable to process because the monthly asset limit of your trial has been reached')
                  return
              }
              case 'no_active_subscription': {
                alert('You do not have an active subscription. Please contact us to get your subscription set up.')
                return
              }
              case 'points_exceeded': {
                alert('This request would exceed your available prepaid points.')
                return
              }
          }
      }
      alert('There was an error creating the result set: ' + error.message)
  }
}

function openLayerGroup_Loaded(layerGroup) {
  optionsSelectorRef.value.selectCurves(layerGroup.curves)
  optionsSelectorRef.value.selectPercentiles(layerGroup.percentiles)
  varSelectorRef.value.selectVariables(layerGroup.layerIds)
  showOpenLayerGroup.value = false
}

function createLayerGroup() {
  const options = optionsSelectorRef.value.getData()
  const vars = varSelectorRef.value.getData()

  let r = new LayerGroup()
  r.curves = options.curves
  r.percentiles = options.percentiles
  r.layerIds = vars

  return r
}

</script>

<style scoped>
#result-set-builder {
  margin: 1rem 4rem;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: relative;
}

.result-set-name {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
}

.result-set-name .label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-set-name .editor {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.result-set-name .editor input {
  flex: 1;
}

.result-set-tabs {
}

.result-set-content {
  display: flex;
  flex: 1;
  padding: 1rem 0 0 0;
}

.result-set-buttons {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.nav-pills .nav-item a.active {
  color: white !important;
}

.page {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.var-selector {
  width: 500px;
  margin: 0 1rem;
}

.options-selector {
  margin: 0 1rem;
}

.asset-selector {
  flex: 1;
}

.options-page {
  justify-content: center;
}

</style>