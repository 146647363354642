<template>
  <div v-if="!userStore.isLoggedIn">
    Please log-in
  </div>
  <div v-else class="flood-page flex-page">
    <flood-map-selector @ondata="floodMapSelector_OnData"/>
    <flood-map-result-dialog v-if="showResult" :query-result="queryResult" @close="showResult = false"/>
  </div>
</template>

<script setup>
import FloodMapSelector from "@/components/FloodMap/FloodMapSelector";
import FloodMapResultDialog from "@/components/FloodMap/FloodMapResultDialog";
import {ref} from "vue";
import {useUserStore} from "@/stores/userStore";

const showResult = ref(false)
const queryResult = ref(null)
const userStore = useUserStore()

function floodMapSelector_OnData(data) {
  queryResult.value = data
  showResult.value = true
}
</script>

<style scoped>
.flood-page {
  justify-content: center;
  align-items: center;
}
</style>