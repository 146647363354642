class BaseOpenSaveViewModel {
    get canOpen() { return false }
    get canSave() { return false }
    get canDelete() { return false }
    get title() { return 'OpenSaveDialog' }
    async getViewItemsAsync() { return [] }
    async loadAsync(viewItem) { return null }
    async saveAsync(name, viewItem, data) { return false }
    getItemText(item) { return 'getItemText() not implemented' }
    async deleteAsync(viewItem) { }
}

export default BaseOpenSaveViewModel