export default class AddressSearchResult {
    text = ''
    lat = 0.0
    lng = 0.0

    static fromObject(o) {
        let r = new AddressSearchResult()
        r.text = o.text
        r.lat = o.lat
        r.lng = o.lng
        return r
    }

    static fromArray(arr) {
        return arr.map(a => AddressSearchResult.fromObject(a))
    }
}