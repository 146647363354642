<template>
  <div class="extend-weather-point-values no-select">
    <div v-if="isLoading" class="loading">
      <loading-indicator/>
    </div>
    <div v-else-if="data !== null" class="values">
      <table class="color-table">
        <tr v-for="(value, index) in data.values" :key="index">
          <td>{{ getMonth(index) }}</td>
          <td><div class="value-color" :style="{ backgroundColor: getColor(value) }"></div></td>
          <td>{{ getLabel(value) }}</td>
        </tr>
      </table>
    </div>
    <div v-else-if="error !== ''" class="error">
      <div class="alert alert-warning"><strong>Results could not be retrieved:</strong><br/>{{ error }}</div>
    </div>
  </div>
</template>

<script>
import {createApp} from "vue";
import api from '@/lib/extendWeather/ExtendWeatherApi'
import ExtendWeatherForecastVarItem from "@/components/ExtendWeatherPage/ExtendWeatherForecastVarItem";
import { Forecast, ForecastVarItem } from "@/lib/extendWeather/ForecastObjects";
import { MONTHS } from "@/lib/extendWeather/EWData";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
  name: "ExtendWeatherPointValues",
  components: {LoadingIndicator},
  create(token, session, forecast, fid, varItem, latlng, el) {

    const inst = createApp(this, {
      token, session, forecast, fid, varItem, latlng
    })

    inst.mount(el)

    return inst
  },
  data() {
    return {
      isLoading: true,
      /** @type ReadValues */
      data: null,
      /** @type string */
      error: ''
    }
  },
  props: {
    token: String,
    session: String,
    forecast: Forecast,
    fid: String,
    varItem: ForecastVarItem,
    latlng: Array,
  },
  mounted() {
    this.getData()
  },
  methods: {
    getColor(value) {
      let colorClass = this.varItem.legend.getColorClass(value)
      if (colorClass == null)
        return '#000'
      return colorClass.color
    },
    getMonth(index) {
      if (this.fid === 'historical') {
        return MONTHS[index]
      }
      else {
        let monthIndex = this.forecast.forecastID.getFirstForecastMonth()
        for(let i=0; i < index; i++) {
          monthIndex++
          if (monthIndex === 13)
            monthIndex = 1
        }
        return MONTHS[monthIndex-1]
      }
    },
    getLabel(value) {
      let colorClass = this.varItem.legend.getColorClass(value)
      if (colorClass == null) {
        return 'Unknown legend key'
      }
      return colorClass.text
    },
    getData() {
      this.error = ''
      this.data = null
      this.isLoading = true

      api.getReadVarValues(this.token, this.session, this.forecast.country.countryID, this.fid, this.varItem.varID, this.latlng[0], this.latlng[1],
          (data) => {
            this.data = data
            this.isLoading = false
          },
          (error) => {
            this.error = error.toString()
            this.isLoading = false
          }
      )
    }
  }
}
</script>

<style scoped>
.extend-weather-point-values {
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.extend-weather-point-values .loading {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}

.extend-weather-point-values .values {

}

.extend-weather-point-values .values .color-table {
}

.extend-weather-point-values .values .color-table tr {
  border-bottom: 1px solid #ddd;
}

.extend-weather-point-values .values .color-table td {
  padding: 0.3rem;
}

.extend-weather-point-values .values .color-table td .value-color {
  width: 14px;
  height: 14px;
  border: 1px solid black;
}

.extend-weather-point-values .error {

}

</style>