import FileSaver from 'file-saver'

function saveTextToFile(content, initialFileName) {
    const blob = new Blob([content])
    FileSaver.saveAs(blob, initialFileName)
}

function saveBlobToFile(blob, initialFileName) {
    if (blob == null) throw new Error('blob is null')

    FileSaver.saveAs(blob, initialFileName)
}

async function saveUrlToFileAsync(url, headers, fileName, method='get') {
    const resp = await fetch(url, {
        method: method,
        headers: headers
    })

    if (resp.status !== 200) throw new Error('Request Error')

    const arrBuff = await resp.arrayBuffer()
    const blob = new Blob([arrBuff])

    saveBlobToFile(blob, fileName)
}

const invalidChars = ['<', '>', ':', '"', '/', '\\', '|', '?', '*']

/**
 * @param {string} fn
 */
function cleanFileName(fn) {
    let s = '';
    [...fn].forEach(c => {
        if (invalidChars.includes(c)) c = '_'
        s = s + c
    })
    return s
}

export { saveTextToFile, saveBlobToFile, saveUrlToFileAsync, cleanFileName }