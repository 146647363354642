import { getAsync } from "@/api/apiUtils";
import config from "@/sys/config";
import ManageUserGroupData from "@/lib/entities/UserGroupInfo";
import {UsageData} from "@/lib/entities/UsageData";

export async function getManageGroupDataAsync(token, groupId) {
    const url = `${config.SERVICE_URL}manage/group/${groupId}`

    const resp = await getAsync(url, null, token)

    if (resp.status === 200) {
        const json = await resp.json()
        return ManageUserGroupData.from(json)
    }

    throw new Error('Failed to get group info')
}

export async function getUserUsageDataAsync(token, groupId, userId) {
    const url = `${config.SERVICE_URL}manage/group/${groupId}/usage/user/${userId}`
    const resp = await getAsync(url, null, token)
    if (resp.status === 200) {
        const json = await resp.json()
        return UsageData.fromArray(json)
    }

    throw new Error('Failed to get usage data')
}

export async function getFullUsageDataAsync(token, groupId) {
    const url = `${config.SERVICE_URL}manage/group/${groupId}/usage`
    const resp = await getAsync(url, null, token)
    if (resp.status === 200) {
        const json = await resp.json()
        return UsageData.fromArray(json)
    }

    throw new Error('Failed to get usage data')
}