<template>
  <div v-if="userStore.isLoggedIn" id="settings-page" class="flex-page">
    <div class="settings-root">
      <h4 class="no-print">Settings</h4>

      <div class="settings-content">
        <div class="settings-tabs no-print">
          <ul class="list-group">
<!--            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'profile'}" @click.prevent="page = 'profile'">Profile</a>-->
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'view'}" @click.prevent="page = 'view'">View settings</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'password'}" @click.prevent="page = 'password'">Password</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'branding'}" @click.prevent="page = 'branding'">Branding</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'theme'}" @click.prevent="page = 'theme'">Theme</a>
            <a v-if="userStore.isMemberOf('api-access')" href="#" class="list-group-item list-group-item-action" :class="{active: page === 'api'}" @click.prevent="page = 'api'">API</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'groups'}" @click.prevent="page = 'groups'">Groups</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'usage'}" @click.prevent="page = 'usage'">My Usage</a>
            <a href="#" class="list-group-item list-group-item-action" :class="{active: page === 'about'}" @click.prevent="page = 'about'">About</a>
          </ul>
        </div>
        <div class="settings-page d-flex flex-fill">
          <GroupsPage v-if="page === 'groups'"/>
          <PasswordPage v-if="page==='password'"/>
          <BrandingPage v-if="page==='branding'"/>
          <ThemePage v-if="page==='theme'"/>
          <ApiPage v-if="userStore.isMemberOf('api-access') && page==='api'"/>
          <ViewSettingsPage v-if="page==='view'"/>
          <MyUsagePage v-if="page==='usage'"/>
          <div class="page" v-if="page === 'about'">
            <h5>About</h5>

            <p>Version: {{config.VERSION}}</p>

            <p>Climate Insights is owned, managed, and run by ClimSystems Ltd.</p>

            <p><a href="https://climsystems.com/climate-insights/" target="_blank">https://climsystems.com/climate-insights/</a></p>
            <p><a href="https://climsystems.com/contact/" target="_blank">Contact us</a></p>

            <p>
              <img src="@/assets/images/clim-logo-color-2-rgb.svg" width="200"/>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import PasswordPage from "@/components/SettingsPage/PasswordPage";
import BrandingPage from "@/components/SettingsPage/BrandingPage";
import ThemePage from "@/components/SettingsPage/ThemePage.vue";
import ApiPage from "@/components/SettingsPage/ApiPage.vue";
import ViewSettingsPage from "@/components/SettingsPage/ViewSettingsPage.vue";
import config from "../sys/config";
import GroupsPage from "@/components/SettingsPage/GroupsPage.vue";
import MyUsagePage from "@/components/SettingsPage/MyUsagePage.vue";

const userStore = useUserStore()

const page = ref('view')
</script>

<style scoped>

@media screen {
  #settings-page {
    justify-content: center;
    align-items: center;
  }

  .settings-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: 90%;
    max-width: 800px;
    padding: 1rem;
  }

  .settings-content {
    flex: 1;
    display: flex;
    flex-direction: row;
  }

  .settings-tabs {
    width: 160px;
  }

  .settings-tabs a.active {
    color: white !important;
  }

  .settings-page {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
  }
}

</style>