<template>
  <div class="home-page">

    <div class="container">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-6">
          <div style="padding: 1rem">
            <h3>Log in to <span>{{ Strings.APP_TITLE }}</span></h3>

            <form>
              <div class="form">
                <div class="form-group">
                  <label for="login_userName">{{ Strings.LOGIN_DIALOG_EMAIL_LABEL }}</label>
                  <input class="form-control" :disabled="loggingIn" type="email" id="login_userName" :placeholder="Strings.LOGIN_DIALOG_EMAIL_PLACEHOLDER" v-model="username"/>
                </div>

                <div class="form-group">
                  <label for="login_password">{{ Strings.LOGIN_DIALOG_PASSWORD_LABEL }}</label>
                  <input class="form-control" :disabled="loggingIn" type="password" id="login_password" v-model="password"/>
                </div>
                <div class="form-group" style="margin-top: 1rem">
                  <button style="margin-right: 0.5rem" class="btn btn-primary" :disabled="loggingIn" @click.prevent="login_Click">{{ Strings.LOGIN_DIALOG_LOGIN_BUTTON }}</button>
                  <button class="btn btn-secondary" :disabled="loggingIn" @click="forgotPassword_Click">I forgot my password</button>
                </div>
              </div>
            </form>

            <hr/>

            <h5>Don't have an account?</h5>
            <p>
              To learn more about signing up for a Climate Insights subscription, please <a href="mailto:info@climateinsights.global">contact us</a>.
            </p>
<!--            <p>-->
<!--              Sign-up for an account.-->
<!--            </p>-->
<!--            <p>-->
<!--              <button class="btn btn-secondary" @click="signup_Click">{{ Strings.TOOLBAR_SIGNUP }}</button>-->
<!--            </p>-->

            <hr/>

            <div class="login-footer">
              <a target="_blank" href="https://www.climsystems.com/"><img alt="ClimSystems logo" src="@/assets/images/clim-logo-color-2-rgb.svg" height="32"/></a>
              <span>Copyright &copy; 2024, ClimSystems Ltd</span>
            </div>
          </div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>

<!--  <SignupDialog v-if="showSignup" @signup="showSignup = false" @cancel="showSignup = false"/>-->

  <loading-indicator v-if="isLoading"/>
</template>

<script setup>
import Strings from "@/lib/lang/Strings";
import {ref} from "vue";
// import SignupDialog from '@/components/WelcomePage/SignupDialog'
import {loginActionAsync} from "@/lib/actions/LoginAction";

import { useUserStore } from "@/stores/userStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {useVarItemsStore} from "@/stores/varItemStore";

const userStore = useUserStore()
const varItemStore = useVarItemsStore()

const username = ref('')
const password = ref('')
const rememberMe = ref(false)
const showSignup = ref(false)
const loggingIn = ref(false)

const isLoading = ref(false)

async function login_Click() {
  const userStore = useUserStore()
  loggingIn.value = true
  isLoading.value = true
  try {
    try {
      const resp = await loginActionAsync(username.value, password.value, rememberMe.value)
      userStore.updateUser(resp.userData)
      userStore.updateBranding(resp.brandingData)

      await varItemStore.updateUsingSubAsync(userStore.token)
    }
    catch(error) {
      console.error(error)
      alert(error.message)
    }
  }
  finally {
    loggingIn.value = false
    isLoading.value = false
  }
}

function forgotPassword_Click() {
  window.location = '/#/reset-password'
}

function signup_Click() {
  showSignup.value = true
}

</script>

<style scoped>
.home-page {
  flex: 1;
}

.home-page .form {
  font-size: large;
}

.login-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>