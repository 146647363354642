import {LatLng} from "@/lib/entities/Results";

class FloodZoneInfo {
    withinZone = false
    distanceInKm = null
    latLng = null
    colorCode = '#000000'

    static fromObject(o) {
        let result = new FloodZoneInfo()
        result.withinZone = o.withinZone
        result.distanceInKm = o.distanceInKm
        result.latLng = LatLng.fromObject(o.nearestLatLng)
        result.colorCode = o.colorCode
        return result
    }
}

export default class FloodMapQueryResult {
    /** @type Boolean */
    hasData = false

    /** @type String */
    error = null

    /** @type FloodZoneInfo */
    data100 = null

    /** @type FloodZoneInfo */
    data500 = null

    /** @type String */
    mapData = null

    dataSources = null

    getZoneInfo(zone) {
        switch(zone) {
            case 100:
                return this.data100
            case 500:
                return this.data500
            default:
                return null
        }
    }

    static fromObject(o) {
        let result = new FloodMapQueryResult()
        result.hasData = o.hasData
        if (!result.hasData)
            return result
        result.data100 = FloodZoneInfo.fromObject(o.data100)
        result.data500 = FloodZoneInfo.fromObject(o.data500)
        result.mapData = o.mapData
        result.dataSources = o.dataSources
        return result
    }

    static fromArray(arr) {
        return arr.map(o => FloodMapQueryResult.fromObject(o))
    }
}

export { FloodZoneInfo }