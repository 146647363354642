class KGClimateClassificationData {
    rating = 0
    metricInfo = []
    imperialInfo = []

    static fromObject(o) {
        let result = new KGClimateClassificationData()
        result.rating = o.rating
        result.metricInfo = o.metricInfo
        result.imperialInfo = o.imperialInfo
        return result
    }
}

class RatingData {
    rating = 0
    info = []

    static fromObject(o) {
        let result = new RatingData()
        result.rating = o.rating
        result.info = o.info
        return result
    }
}
class ClassificationDataItem {
    /** @type Number */ elevation
    /** @type KGClimateClassificationData */ kgClimateClassification
    /** @type RatingData */ earthquakeIntensity
    /** @type RatingData */ cycloneIntensity
    /** @type RatingData */ cycloneFrequency
    /** @type RatingData */ tsunamiMaximumInundationHeight

    static fromObject(o) {
        let result = new ClassificationDataItem()
        result.elevation = o.elevation
        result.kgClimateClassification = KGClimateClassificationData.fromObject(o.kgClimateClassification)
        result.earthquakeIntensity = RatingData.fromObject(o.earthquakeIntensity)
        result.cycloneIntensity = RatingData.fromObject(o.cycloneIntensity)
        result.cycloneFrequency = RatingData.fromObject(o.cycloneFrequency)
        result.tsunamiMaximumInundationHeight = RatingData.fromObject(o.tsunamiMaximumInundationHeight)
        return result
    }
}

export default class ClassificationData {
    /** @type Object.<String, ClassificationDataItem> */
    classifications = {}

    find(assetId) {
        return this.classifications[assetId]
    }

    static fromObject(o) {
        let result = new ClassificationData()
        const keys = Object.keys(o)
        keys.forEach(key => {
            result.classifications[key] = ClassificationDataItem.fromObject(o[key])
        })
        return result
    }
}