<template>
<div class="dialog">
  <div class="dialog-background"/>
  <div class="dialog-content general-panel">

    <div v-if="state === 'create'" class="create-key-form">
      <h3>Create API key:</h3>
      <form>
        <div class="form-group">
          <label>Name:</label>
          <input type="text" class="form-control" v-model="name"/>
        </div>

        <div class="form-group">
          <label>How many days should the key remain valid for (max 90):</label>
          <input type="number" class="form-control" v-model="lifetime"/>
        </div>

        <div class="button-row">
          <div class="button-strip">
            <button class="btn btn-primary" @click.prevent="createKey_Click">Create</button>
            <button class="btn btn-primary" @click="emit('close')">Cancel</button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="state === 'view'" class="created-key-success">
      <h3>API key created</h3>
      <p>Use this key to integrate with our API. Please copy it now and store is somewhere safe as you can not retrieve it later.</p>
      <input type="text" readonly :value="createdKey"/>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click="copy_Click">Copy</button>
          <button class="btn btn-primary" @click="emit('close')">Close</button>
        </div>
      </div>
    </div>

    <loading-indicator v-if="isLoading"/>

  </div>
</div>
</template>

<script setup>
import {ref, onMounted, defineEmits, watch} from "vue";
import {createApiKeyAsync} from "@/api/apiKeyService";
import {useUserStore} from "@/stores/userStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

const userStore = useUserStore()

const state = ref('')
const createdKey = ref('')
const lifetime = ref(0)
const name = ref('')
const emit = defineEmits(['close', 'key-created'])
const isLoading = ref(false)

watch(createdKey, (newVal) => {
  if (newVal === '') return
  emit('key-created')
})

onMounted(() => {
  state.value = 'create'
  lifetime.value = 30
  createdKey.value = 'No key has been generated'
  name.value = ''
})

async function createKeyAsync() {
  const key = await createApiKeyAsync(userStore.token, name.value, lifetime.value, ['all'], [])
  return key
}

async function createKey_Click() {
  isLoading.value = true
  try {
    const key = await createKeyAsync()
    createdKey.value = key.keyString
    state.value = 'view'
  }
  catch(error) {
    alert('There was an error creating key')
  }
  finally {
    isLoading.value = false
  }
}

async function copy_Click() {
  await navigator.clipboard.writeText(createdKey.value)
}

</script>

<style scoped>
.create-key-form,
.created-key-success {
  display: flex;
  flex-direction: column;
}

.dialog-content {
  min-width: 500px;
}
</style>