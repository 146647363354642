import EWUtils from '@/lib/extendWeather/EWUtils.js'

let ForecastUrlOffset = {
    UO_REL_TO_FORECASTID: 'rel',
    UO_ABSOLUTE: 'abs'
}

let ForecastVarType = {
    VT_FORECAST: 'forecast',
    VT_HISTORICAL: 'historical'
}

class ForecastID {
    constructor(fidOrE, y, m) {
        if (y === undefined)
            this.parse(fidOrE)
        else {
            this.E = fidOrE
            this.Y = y
            this.M  = m
        }
    }

    getMonth() {
        return this.M
    }

    getYear() {
        return this.Y
    }

    getEdition() {
        return this.E
    }

    getFirstForecastMonth() {
        let month = this.M + 1

        if (month === 13) {
            month = 1
        }

        return month
    }

    parse(str) {
        if (str.length !== 8 && str.length !== 9)
            throw new Error("Forecast ID string has an unexpected length")

        if (str.charAt(0) !== 'E')
            throw new Error("Forecast ID string does not start with the expected \"E\" character")

        if (str.length === 9 && str[2] !== '.')
            throw new Error("Forecast ID string does not have the required \".\" character")

        let substrOffset = 0
        if (str.length === 9)
            substrOffset = 1

        let e = 0, y = 0, m = 0

        try
        {
            e = parseInt(EWUtils.subStr(str, 1, 1))
            y = parseInt(EWUtils.subStr(str, 2 + substrOffset, 4))
            m = parseInt(EWUtils.subStr(str, 6 + substrOffset, 2))
        }
        catch
        {
            throw new Error("Forecast ID string contains one or more invalid characters")
        }

        if (e < 1 || e > 3)
            throw new Error("Forecast ID string has an invalid edition value")

        if (m < 1 || m > 12)
            throw new Error("Forecast ID string has an invalid month value")

        this.E = e
        this.Y = y
        this.M = m
    }

    toString(incDot = true) {
        let d = ''
        if (incDot)
            d = '.'
        return 'E' + this.E.toString() + d + EWUtils.padStr('0', 4, this.Y.toString()) + EWUtils.padStr('0', 2, this.M.toString())
    }

    getDate() {
        return new Date(this.Y, this.M-1, 1)
    }

    getFirstForecastDate() {
        return new Date(this.Y, this.M, 1)
    }

    getNext() {
        let e = this.E, y = this.Y, m = this.M

        e++;
        if (e > 3)
        {
            e = 1;
            m++;
            if (m > 12)
            {
                m = 1;
                y++;
            }
        }

        return new ForecastID(e, y, m);
    }

    getPrevious() {
        let e = this.E, y = this.Y, m = this.M;

        e--;
        if (e < 1)
        {
            e = 3;
            m--;
            if (m < 1)
            {
                m = 12;
                y--;
            }
        }

        return new ForecastID(e, y, m);
    }

    clone() {
        return new ForecastID(this.E, this.Y, this.M)
    }

    equals(o) {
        return o != null &&
            o.E === this.E &&
            o.Y === this.Y &&
            o.M === this.M
    }
}

class Bounds {
    constructor(bounds) {
        this.left = 0
        this.top = 0
        this.right = 0
        this.bottom = 0

        if (bounds !== undefined) {
            this.left = bounds.left
            this.top = bounds.top
            this.right = bounds.right
            this.bottom = bounds.bottom
        }
    }

    clone() {
        return new Bounds(this)
    }

    equals(o) {
        return o != null &&
            o.left === this.left &&
            o.top === this.top &&
            o.right === this.right &&
            o.bottom === this.bottom
    }

    toLeafletBounds() {
        return [[this.bottom, this.left], [this.top, this.right]]
    }
}

class ColorClassLegendItem {
    constructor(item) {
        this.text = ''
        this.minValue = 0
        this.maxValue = 0
        this.color = ''
        if (item != null) {
            this.text = item.text
            this.minValue = Number(item.minValue)
            this.maxValue = Number(item.maxValue)
            this.color = item.color
        }
    }

    valueInRange(value) {
        value = Number(value)
        return value >= this.minValue && value <= this.maxValue
    }
}

class ColorClassLegend {
    /** @type ColorClassLegendItem[] */
    colorClasses;

    constructor(legendArr) {
        this.colorClasses = []
        if (legendArr != null) {
            legendArr.forEach((item) => this.colorClasses.push(new ColorClassLegendItem(item)))
        }
    }

    /** @returns {(ColorClassLegendItem|null)} */
    getColorClass(value) {
        value = Number(value)
        return this.colorClasses.find((item) => item.valueInRange(value))
    }
}

class ForecastVarItem {
    constructor(source, varType, offset) {
        this.varType = null
        this.varID = null
        this.units = null
        this.name = null
        this.group = null
        this.layerBounds = null
        this.dataBounds = null
        this.urls = null
        this.legend = null
        this.offset = null

        if (source !== undefined) {
            this.varID = source.varID
            this.units = source.units
            this.name = source.name
            this.group = source.group
            this.layerBounds = new Bounds(source.layerBounds)
            this.dataBounds = new Bounds(source.dataBounds)
            this.urls = source.urls
            this.legend = new ColorClassLegend(source.legend)

            if (typeof varType == 'string')
                this.varType = varType
            else
                this.varType = source.varType

            if (typeof offset == 'string')
                this.offset = offset
            else
                this.offset = source.offset
        }
    }

    getUrl(index, forecastID) {
        switch(this.offset) {
            case ForecastUrlOffset.UO_ABSOLUTE:
                return this.urls[index]
            case ForecastUrlOffset.UO_REL_TO_FORECASTID: {
                let date = forecastID.getFirstForecastDate()
                let urlIndex = date.getMonth() + index
                if (urlIndex > (this.urls.length-1)) {
                    urlIndex = urlIndex - this.urls.length
                }
                return this.urls[urlIndex]
            }
            default:
                throw new Error('Invalid ForecastUrlOffset value')
        }
    }

    clone() {
        return new ForecastVarItem(this)
    }

    equals(o) {
        return o != null &&
            o.varID === this.varID &&
            o.units === this.units &&
            o.name === this.name &&
            o.varType === this.varType &&
            o.offset === this.offset &&
            o.layerBounds.equals(this.layerBounds) &&
            o.dataBounds.equals(this.dataBounds) &&
            o.urls.length === this.urls.length &&
            EWUtils.arraysEqual(this.urls, o.urls)
    }
}

class ForecastVarList {
    constructor(source, varType, offset) {
        this.items = []
        if (Array.isArray(source)) {
            let items = []
            source.forEach((value) => {
                items.push(new ForecastVarItem(value, varType, offset))
            })
            this.items = items
        }
    }
}

class ForecastVarInventory {
    constructor(source) {
        this.forecastVars = []
        this.historicalVars = []

        if (source != null) {
            this.forecastVars = new ForecastVarList(source.forecastVars, ForecastVarType.VT_FORECAST, ForecastUrlOffset.UO_ABSOLUTE)
            this.historicalVars = new ForecastVarList(source.historicalVars, ForecastVarType.VT_HISTORICAL, ForecastUrlOffset.UO_REL_TO_FORECASTID)
        }
    }

    find(varType, varID, useGroup) {
        let list = this.getList(varType)

        if (useGroup === undefined)
            useGroup = false

        if (list == null)
            return null

        return list.items.find(item => {
            if (useGroup)
                return item.group === varID
            else
                return item.varID === varID
        })
    }

    getList(varType) {
        if (varType === 'forecast')
            return this.forecastVars
        else if (varType === 'historical')
            return this.historicalVars
        else
            return null
    }
}

class Country {
    constructor(source) {
        this.centerX = null
        this.centerY = null
        this.countryID = null
        this.name = null
        this.parentCountryID = null
        if (source != undefined) {
            this.centerX = source.centerX
            this.centerY = source.centerY
            this.countryID = source.countryID
            this.name = source.name
            this.parentCountryID = source.parentCountryID
        }
    }

    clone() {
        return new Country(this)
    }

    equals(o) {
        return o != null &&
            o.centerX === this.centerX &&
            o.centerY === this.centerY &&
            o.countryID === this.countryID &&
            o.name === this.name &&
            o.parentCountryID === this.parentCountryID
    }
}

class LatestForecast {
    constructor(source) {
        this.countryID = null
        this.forecastID = null
        if (source !== undefined) {
            this.countryID = source.countryID
            this.forecastID = source.forecastID
        }
    }

    clone() {
        return new LatestForecast(this)
    }

    equals(o) {
        return o != null &&
            o.forecastID === this.forecastID &&
            o.countryID === this.countryID
    }
}

class Forecast {
    constructor(source) {
        this.country = null
        this.forecastID = null
        this.pubDate = null
        this.varInventory = null    // used by extendWeather component to load varInventory when required in the UI. Not provided on initial load
        
        if (source !== undefined) {
            this.country = new Country(source.country)
            this.forecastID = new ForecastID(source.forecastID)
            this.pubDate = EWUtils.ymdToDate(source.pubDate)
            this.varInventory = source.varInventory
        }
    }

    clone() {
        return new Forecast(this)
    }

    equals(o) {
        return o != null &&
            o.forecastID === this.forecastID &&
            o.pubDate === this.pubDate &&
            o.country.equals(this.country)
    }
}

class ForecastListItem {
    constructor(source) {
        this.country = null
        this.latestForecast = null
        this.forecasts = []
        if (source !== undefined) {
            this.country = new Country(source.country)
            this.latestForecast = new LatestForecast(source.latestForecast)
            let forecasts = []
            source.forecasts.forEach((item) => {
                forecasts.push(new Forecast(item))
            })
            this.forecasts = forecasts
        }
    }
}

class ForecastList {
    constructor(source) {
        this.items = []
        
        if (source !== undefined) {
            if (Array.isArray(source))
                this.loadArray(source)
            else if (Array.isArray(source.items))
                this.loadArray(source.items)
        }
    }
    
    loadArray(source) {
        let items = []
        source.forEach((value) => {
            items.push(new ForecastListItem(value))
        })
        this.items = items
    }
}

class LayerInventoryItem {
    constructor(source) {
        this.countryID = ''
        this.layerID = ''
        this.name = ''
        this.layerType = ''
        this.description = ''
        this.sourceType = ''
        this.sourceData = null
        this.styleInfo = null
        this.dateCreated = null
        this.dateUpdated = null

        if(source != null) {
            this.countryID = source.countryID
            this.layerID = source.layerID
            this.name = source.name
            this.layerType = source.layerType
            this.description = source.description
            this.sourceType = source.sourceType
            this.sourceData = source.sourceData
            this.styleInfo = source.styleInfo
            this.dateCreated = source.dateCreated
            this.dateUpdated = source.dateUpdated
        }
    }
}

class LayerInventory {
    constructor(source) {
        this.items = []
        this.countryID = ''

        this.countryID = source.countryID

        if (Array.isArray(source.layers)) {
            let items = []
            source.layers.forEach((value) => {
                let item = new LayerInventoryItem(value)
                items.push(item)
            })
            this.items = items
        }
    }

    find(layerID) {
        for(let i=0; i < this.items.length; i++)
            if (this.items[i].layerID === layerID)
                return this.items[i]
        return null
    }
}

class ReadValues
{
    constructor(source) {
        if (source != null) {
            this.values = source.values
            this.varid = source.varid
            this.units = source.units
            this.name = source.name
            this.cid = source.cid
            this.varGroup = source.varGroup        
        }
    }

}

export {
    ForecastID,
    Bounds,
    ForecastVarItem,
    ForecastListItem,
    ForecastList,
    ForecastVarInventory,
    ForecastVarType,
    Country,
    Forecast,
    ColorClassLegendItem,
    ColorClassLegend,
    LayerInventory,
    LayerInventoryItem,
    ReadValues
}