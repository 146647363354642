export default class HistoryItem {
    updated = ''
    indicator = ''
    region = ''
    units = ''
    resolution = ''
    description = ''
    version = ''

    static fromObject(o) {
        let result = new HistoryItem()
        result.updated = o.updated
        result.indicator = o.indicator
        result.region = o.region
        result.units = o.units
        result.resolution = o.resolution
        result.description = o.description
        result.version = o.version
        return result
    }

    /** @param {Array} arr */
    static fromArray(arr) {
        return arr.map((o) => HistoryItem.fromObject(o))
    }
}