import {defineStore} from "pinia/dist/pinia";

export const useViewSettingsStore = defineStore({
    id: 'viewSettings',
    state: () => {
        return {
            dp: 2,
            latLngDp: 4
        }
    }
})