import BaseResultSetExporter from "@/lib/dataExport/resultSet/BaseResultSetExporter";
import {updateHeaders} from "@/api/apiUtils";
import {useUserStore} from "@/stores/userStore";
import config from "@/sys/config";
import {saveUrlToFileAsync, cleanFileName} from "@/lib/utils/FileUtils";
import {createUniqueUserDownloadAsync, formatUniqueDownloadUrl, waitForCompleteStatusAsync} from "@/api/exportsApi";

export default class ResultSetUniqueUserDownloadExporter extends BaseResultSetExporter {
    /**
     * @param {string[]} formats Specifies file types to include in the export
     */
    constructor(formats) {
        super();
        this.formats = formats
    }

    async exportAsync(reader) {
        const userStore = useUserStore()
        const token = userStore.token
        const refId = reader.info.refId
        const headers = updateHeaders({}, token)
        const fileName = cleanFileName(reader.info.name + '.zip')
        const brandingProfileId = this.brandingProfile == null ? null : this.brandingProfile.brandingEntityId

        const uniqueId = await createUniqueUserDownloadAsync(token, refId, fileName, this.formats, this.timeHorizon, brandingProfileId)
        const status = await waitForCompleteStatusAsync(token, uniqueId)
        if (status !== 'ready') {
            throw new Error('Failed to create user download. Please contact support')
        }
        const url = formatUniqueDownloadUrl(uniqueId)
        await saveUrlToFileAsync(url, headers, fileName)
    }
}