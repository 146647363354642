import { createRouter, createWebHashHistory } from 'vue-router'
import WelcomePage from "@/pages/WelcomePage";
import ExtendWeatherPage from "@/pages/ExtendWeatherPage";
import DataPage from "@/pages/DataPage";
import SettingsPage from "@/pages/SettingsPage";
import AdminPage from "@/pages/AdminPage";
import HelpPage from "@/pages/HelpPage";
import FloodPage from '@/pages/FloodPage'
import ResetPasswordPage from "@/pages/ResetPasswordPage.vue";

const routes = [
    { name: 'welcome', path: '/', component: WelcomePage },
    { name: 'test', path: '/test', component: WelcomePage },
    { name: 'forecast', path: '/forecast', component: ExtendWeatherPage },
    { name: 'data', path: '/data', component: DataPage },
    { name: 'settings', path: '/settings', component: SettingsPage },
    { name: 'admin', path: '/admin', component: AdminPage },
    { name: 'help', path: '/help', component: HelpPage },
    { name: 'flood', path: '/flood', component: FloodPage },
    { name: 'reset-password', path: '/reset-password', component: ResetPasswordPage }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router