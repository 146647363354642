<script setup>
import GroupMemberUsageDialog from "@/components/SettingsPage/dialogs/GroupMemberUsageDialog.vue";
import {ref} from "vue";
import {useUserStore} from "@/stores/userStore";
import {getUsageAsync} from "@/api/userApi";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {Member} from "@/lib/entities/UserGroupInfo";

const userStore = useUserStore()

const showDialog = ref(false)
const members = ref([])
const data = ref(null)
const isLoading = ref(false)

async function loadUsageData_Click() {
  members.value = [new Member(userStore.userId, userStore.email, userStore.name, 'user')]

  isLoading.value = true
  try {
    const usageData = await getUsageAsync(userStore.token)
    data.value = usageData
    showDialog.value = true
  }
  finally {
    isLoading.value = false
  }
}

</script>

<template>
  <h5>My Usage</h5>

  <p>
    Click the Create Usage Report button to view your Climate Insights usage.
  </p>

  <p>
    <button class="btn btn-primary" @click.prevent="loadUsageData_Click">Create Usage Report</button>
  </p>

  <GroupMemberUsageDialog v-if="showDialog" :members="members" :data="data" :show-billable-data="true" @close="showDialog = false"/>
  <loading-indicator v-if="isLoading"/>
</template>

<style scoped>

</style>