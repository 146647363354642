<template>
  <div id="admin-page" class="flex-page">
    <h1>Admin</h1>
  </div>
</template>

<script>
export default {
  name: "AdminPage"
}
</script>

<style scoped>

</style>