import {getJsonAsync, postJsonAsync, deleteAsync, updateHeaders} from "@/api/apiUtils";
import config from "@/sys/config";
import {ApiKey, CreatedApiKey} from "@/lib/entities/ApiKey";
import DataTemplate from "@/lib/entities/DataTemplate";

async function getApiKeysAsync(token) {
    const url = config.SERVICE_URL + 'user/apikeys'
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200) throw new Error('Failed to get Api Keys')
    const json = await resp.json()
    return ApiKey.fromArray(json)
}

async function invalidateApiKeyAsync(token, lookup) {
    const url = config.SERVICE_URL + 'user/apikey/?lookup=' + lookup
    const resp = await deleteAsync(url, {}, token)
    if (resp.status !== 200) throw new Error('Failed to invalidate key')
}

async function createApiKeyAsync(token, name, lifetime, permissions, ipFilters) {
    const data = { lifetime, name, permissions, ipFilters }
    const url = config.SERVICE_URL + 'user/apikey'
    const resp = await postJsonAsync(url, data, token)
    if (resp.status !== 200) throw new Error('Failed to create key')
    const json = await resp.json()
    return CreatedApiKey.fromObject(json)
}

async function createDataTemplateAsync(token, name, variables, curves, percentiles) {
    const data = { name, curves, percentiles, variables }
    const url = config.SERVICE_URL + 'user/data-template'
    const resp = await postJsonAsync(url, data, token)
    if (resp.status !== 200) throw new Error('Failed to add Data Template')
}

async function getDataTemplatesAsync(token) {
    const url = config.SERVICE_URL + 'user/data-templates'
    const resp = await getJsonAsync(url, token)
    if (resp.status !== 200) throw new Error('Failed to get Data Templates')
    const json = await resp.json()
    return DataTemplate.fromArray(json)
}

async function deleteDataTemplateAsync(token, name) {
    const url = config.SERVICE_URL + 'user/data-template?name=' + name
    const resp = await deleteAsync(url, {}, token)
    if (resp.status !== 200) throw new Error('Failed to delete Data Template')
}

export {
    getApiKeysAsync,
    invalidateApiKeyAsync,
    createApiKeyAsync,
    createDataTemplateAsync,
    getDataTemplatesAsync,
    deleteDataTemplateAsync
}