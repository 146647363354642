<template>
  <canvas ref="canvas" width="500" height="370" class="canvas"></canvas>
</template>

<script setup>
import {ref, defineProps, onMounted, watch} from 'vue'

import indicatorImagePath from '@/assets/images/Classifications/Indicator.svg'
import noRiskImagePath from '@/assets/images/Classifications/NoRisk.svg'

const canvas = ref()

const props = defineProps({
  min: { type: Number, required: true },
  max: { type: Number, required: true },
  value: { type: Number, required: true },
  image: { type: String, required: true }
})

function degreeToRad(d) {
  return d*Math.PI/180
}

function draw(canvas, min, max, value, image, indicatorImage, noRiskImage, clear = false) {
  const ctx = canvas.getContext('2d')

  if (clear)
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

  ctx.drawImage(image, 0, 0, 500, 370)

  ctx.save()
  try {
    ctx.translate(500/2, 370/2)

    if (value === 0 && value < min) {
      ctx.drawImage(noRiskImage, -150, -150, 300, 300)
      return
    }

    if (value > max) return

    const segCount = (max - min) + 1
    const segSize = 360 / segCount

    ctx.rotate(degreeToRad(segSize / 2 + segSize * (value - min)))
    ctx.drawImage(indicatorImage, -150, -100, 300, 300)
  }
  finally {
    ctx.restore()
  }
}

function doDraw(clear = false) {
  draw(canvas.value, props.min, props.max, props.value, contentImage.value, indicatorImage.value, noRiskImage.value, clear)
}

const contentImage = ref(null)
const indicatorImage = ref(null)
const noRiskImage = ref(null)

onMounted(async () => {
  contentImage.value = await loadImageAsync(props.image)
  indicatorImage.value = await loadImageAsync(indicatorImagePath)
  noRiskImage.value = await loadImageAsync(noRiskImagePath)

  doDraw()
})

async function loadImageAsync(path) {
  return await new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.src = path
  })
}

watch(() => props.value, (newVal, oldVal) => {
  doDraw(true)
})
</script>

<style scoped>

</style>