<template>
  <div class="dialog create-project">
    <div class="dialog-background" @click="emit('cancel')"/>
    <div class="dialog-content general-panel">
      <div class="window-controls"><a href="#" @click.prevent="emit('cancel')">&times;</a></div>
      <h3>{{ dialogTitle }}</h3>
      <label>
        <span>Project name:</span>
        <input type="text" v-model="projectName"/>
      </label>
      <label class="description-label">
        <span>Description: (optional)</span>
        <textarea v-model="description"/>
      </label>
      <label>
        <span>Client reference number: (optional)</span>
        <input type="text" v-model="clientRefNo"/>
      </label>
      <div class="button-row">
        <div class="button-strip">
          <button v-if="isAdding" class="btn btn-primary" @click="createProject_Click">Create</button>
          <button v-if="isUpdating" class="btn btn-primary" @click="updateProject_Click">Update</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from "vue";
import {createProjectAsync, updateProjectAsync} from "@/api/projectsApi";
import {useUserStore} from "@/stores/userStore";
import ProjectItem from "@/lib/entities/ProjectItem";

const emit = defineEmits(['cancel', 'project-created', 'project-updated'])

const props = defineProps({
  project: {
    required: false,
    default: null,
    type: ProjectItem
  }
})

const projectName = ref('')
const description = ref('')
const clientRefNo = ref('')

const isAdding = computed(() => { return props.project == null })
const isUpdating = computed(() => { return props.project != null })
const dialogTitle = computed(() => { return isAdding.value ? "Create project" : "Update project" })

onMounted(() => {
  if (props.project != null) {
    projectName.value = props.project.name
    description.value = props.project.description
    clientRefNo.value = props.project.clientRefNo
  }
})

const userStore = useUserStore()

function checkInput() {
  if (projectName.value === '') {
    alert('Please enter a project name.')
    return false
  }
  return true
}

async function updateProject_Click() {
  if (!checkInput()) {
    return
  }

  try {
    const data = {
      projectId: props.project.projectId,
      name: projectName.value,
      description: description.value,
      clientRefNo: clientRefNo.value
    }
    await updateProjectAsync(userStore.token, data)
    emit('project-updated')
  }
  catch(error) {
    alert('Error updating project.')
  }
}

async function createProject_Click() {
  if (!checkInput()) {
    return
  }

  try {
    const data = {
      name: projectName.value,
      description: description.value,
      clientRefNo: clientRefNo.value
    }
    await createProjectAsync(userStore.token, data)
    emit('project-created')
  }
  catch(error) {
    alert('Error creating project.')
  }
}

</script>

<style scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  min-height: 300px;
}

input, textarea {
  width: 100%;
}

.description-label {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.description-label textarea {
  flex: 1;
}

.button-strip {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
</style>