<template>
    <div class="extend-weather-forecast-item">
        <expand-toggle :text="forecast.forecastID.toString()" @on-change="isOpen = !isOpen"></expand-toggle>
        <div v-if="isOpen">
          <loading-indicator v-if="varInventory === null"/>
            <div class="var-items">
                <extend-weather-forecast-varlist v-if="varInventory !== null" :forecast="forecast" :var-inventory="varInventory" @month-clicked="forecastMonth_Clicked" />
                <extend-weather-historical-varlist v-if="varInventory !== null" :forecast="forecast" :var-inventory="varInventory" @month-clicked="historicalMonth_Clicked" />
            </div>
        </div>
    </div>
</template>

<script>
import { Forecast } from "@/lib/extendWeather/ForecastObjects";
import ExpandToggle from "@/components/ExpandToggle";
import service from "@/lib/extendWeather/ExtendWeatherApi";
import ExtendWeatherForecastVarlist from "@/components/ExtendWeatherPage/ExtendWeatherForecastVarlist";
import ExtendWeatherHistoricalVarlist from "@/components/ExtendWeatherPage/ExtendWeatherHistoricalVarlist";
import {useUserStore} from "@/stores/userStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

export default {
    name: "ExtendWeatherForecastItem",
    components: {LoadingIndicator, ExpandToggle, ExtendWeatherForecastVarlist, ExtendWeatherHistoricalVarlist },
    props: { 
        forecast: Forecast,
        session: String
    },
    data() { return { 
        isOpen: false,
        loadCount: 0,
        varInventory: null,
        userStore: useUserStore()
    }},
    watch: {
        isOpen(val) {
            if (val === true) {
                this.loadInventory()
            }
        }
    },
    methods: {
        loadInventory() {
            if (this.loadCount++ !== 0) return

            let item = this.forecast
            let cid = item.country.countryID
            let fid = item.forecastID.toString()

            service.getForecastVarInventory(this.userStore.token, this.session, cid, fid,
                    (forecastVarInventory) => {
                        item.varInventory = forecastVarInventory
                        this.varInventory = forecastVarInventory
                    },
                    (error) => {
                    })
        },
        forecastMonth_Clicked(varItem, month, index) {
            this.$emit('forecast-month-clicked', this.forecast, varItem, month, index)
        },
        historicalMonth_Clicked(varItem, month, index) {
            this.$emit('historical-month-clicked', this.forecast, varItem, month, index)
        },
    },     
}
</script>

<style scoped>
    .extend-weather-forecast-item {
        
    }
    
    .var-items {
        margin-left: 1.2rem;
    }
</style>