<template>
  <div class="add-asset-dialog dialog">
    <div class="dialog-background" @click="emit('cancel')"></div>

    <div class="dialog-content general-panel">
      <div class="window-controls">
        <a href="#" @click.prevent="emit('cancel')">&times;</a>
      </div>

      <div class="form-group">
        <label for="add-name">Asset name</label>
        <input id="add-name" type="text" class="form-control" v-model="localAssetName">
      </div>

      <div class="lat-lat">
        <div class="form-group">
          <label for="add-lat">Latitude:</label>
          <input id="add-lat" type="text" class="form-control" v-model="localLat">
        </div>

        <div class="form-group">
          <label for="add-lng">Longitude:</label>
          <input id="add-lng" type="text" class="form-control" v-model="localLng">
        </div>
      </div>
      <p>Attributes:</p>

      <div class="item">
        <span>Name</span>
        <span>Value</span>
      </div>
      <div class="attributes-list-box base-list-box">
        <div class="list-box-scroller">
          <div class="item" v-for="(attrib, index) in localAttribs" :key="index">
            <input type="text" v-model="attrib.key"/>
            <input type="text" v-model="attrib.value"/>
            <a href="#" class="del-button" @click.prevent="deleteAttrib(attrib)">&times;</a>
          </div>
        </div>
      </div>
      <div class="button-row">
        <button class="btn btn-secondary btn-sm" @click="addAttribute_Click">Add attribute</button>
      </div>

      <div class="button-row dialog-buttons">
        <div class="button-strip">
          <button class="btn btn-primary" @click="ok_Clicked">OK</button>
          <button class="btn btn-secondary" @click="emit('cancel')">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, ref} from "vue";
import AddedAsset from "@/lib/entities/AddedAsset";

const emit = defineEmits(['add', 'edit', 'cancel', 'check-location'])

const props = defineProps({
  initialValues: Object,
  checkLocationCallback: {
    type: Function,
    required: false,
    default: null
  }
})

const localAssetName = ref(props.initialValues.assetName || '')
const localLat = ref(props.initialValues.lat || 0)
const localLng = ref(props.initialValues.lng || 0)
const localAttribs = ref(props.initialValues.attributes || [])
const localIsEditing = ref(props.initialValues.isEditing || false)

function addAttribute_Click() {
  localAttribs.value.push({key: 'Attribute name', value: 'Value'})
}
function deleteAttrib(attrib) {
  localAttribs.value = localAttribs.value.filter(a => a !== attrib)
}

function doCheckLocationCallback(lat, lng) {
  if (typeof(props.checkLocationCallback) !== 'function')
    return true

  return props.checkLocationCallback(lat, lng)
}

function ok_Clicked() {
  const lat = Number.parseFloat(localLat.value)
  const lng = Number.parseFloat(localLng.value)

  if (Number.isNaN(lat) || Number.isNaN(lng)) {
    alert('Latitude/longitude values are not valid.')
    return
  }

  if (!doCheckLocationCallback(lat, lng)) {
    alert(`Unable to add asset at the location: ${lat.toFixed(2)}, ${lng.toFixed(2)}`)
    return
  }

  const asset = new AddedAsset()

  asset.name = localAssetName.value
  asset.latLng.lat = lat
  asset.latLng.lng = lng

  let attribsObj = {}
  localAttribs.value.forEach((a) => {
    attribsObj[a.key] = a.value
  })
  asset.attributes = attribsObj
  if (localIsEditing.value)
    emit('edit', asset)
  else
    emit('add', asset)
}
</script>

<style scoped>
.add-asset-dialog {
}

.lat-lat {
  display: flex;
}

.attributes-list-box {
  min-height: 180px;
}

.item {
  display: flex;
}

.item span,
.item input {
  flex: 1;
}

.del-button {
  color: red !important;
  font-weight: bold;
  text-decoration: none;
  font-size: 14pt;
  margin-left: 0.2rem;
}
</style>