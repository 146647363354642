<template>
  <div class="api-page flex-fill">
    <h5>API Management</h5>

    <h6>Manage API keys</h6>
    <div class="section">
      <div class="base-list-box flex-fill">
        <div class="list-box-scroller">
          <div class="item" :class="{ selected: selKey === item }" v-for="(item, index) in keys" :key="index" @click="selKey = item">
            <strong>{{ item.name }}</strong> expires {{ item.expires.toDateString() }}
            <br/>
            <span class="disabled-text">({{item.lookup}})</span>
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click="showCreateApiKeyDialog = true">Create</button>
          <button class="btn btn-primary" @click="delete_Click">Delete</button>
        </div>
      </div>
    </div>


    <h6>Manage Data Templates</h6>
    <div class="section">
      <div class="base-list-box flex-fill">
        <div class="list-box-scroller">
          <div class="item" :class="{selected: template === selTemplate}" v-for="(template, index) in dataTemplates" :key="index" @click="selTemplate = template">
            {{ template.name }}
          </div>
        </div>
      </div>
      <div class="button-row">
        <div class="button-strip">
          <button class="btn btn-primary" @click="showCreateDataTemplateDialog = true">Add</button>
          <button class="btn btn-primary" @click="deleteDataTemplate_Click">Delete</button>
<!--          <button class="btn btn-primary">Edit</button>-->
        </div>
      </div>
    </div>
  </div>

  <CreateApiKeyDialog v-if="showCreateApiKeyDialog" @close="showCreateApiKeyDialog = false" @key-created="keyCreated"></CreateApiKeyDialog>
  <CreateDataTemplateDialog v-if="showCreateDataTemplateDialog" @close="showCreateDataTemplateDialog = false" @template-added="templateAdded"/>
</template>

<script setup>

import {onMounted, ref} from "vue";
import {useUserStore} from "@/stores/userStore";
import {
  deleteDataTemplateAsync,
  getApiKeysAsync,
  getDataTemplatesAsync,
  invalidateApiKeyAsync
} from "@/api/apiKeyService";
import CreateApiKeyDialog from "@/components/SettingsPage/dialogs/CreateApiKeyDialog.vue";
import CreateDataTemplateDialog from "@/components/SettingsPage/dialogs/CreateDataTemplateDialog.vue";

const userStore = useUserStore()

const keys = ref([])
const dataTemplates = ref([])

const selKey = ref(null)
const selTemplate = ref(null)

const showCreateApiKeyDialog = ref(false)
const showCreateDataTemplateDialog = ref(false)

async function doGetKeysAsync(token) {
  try {
    const keys = await getApiKeysAsync(token)
    return keys
  }
  catch (error) {
    alert('Error getting Api keys')
    return []
  }
}

async function doGetDataTemplatesAsync(token) {
  try {
    const templates = await getDataTemplatesAsync(token)
    return templates
  }
  catch (error) {
    alert('Error getting Data Templates')
    return []
  }
}

async function refreshKeysAsync() {
  const keysData = doGetKeysAsync(userStore.token)
  const templatesData = doGetDataTemplatesAsync(userStore.token)

  keys.value = await keysData
  dataTemplates.value = await templatesData
}

onMounted(async () => {
  await refreshKeysAsync()
})

async function keyCreated() {
  await refreshKeysAsync()
}

async function delete_Click() {
  if (selKey.value == null) return
  if (!confirm('Delete selected key?')) return

  const lookup = selKey.value.lookup

  await invalidateApiKeyAsync(userStore.token, lookup)

  await refreshKeysAsync()
}

async function deleteDataTemplate_Click() {
  if (selTemplate.value == null) return
  if (!confirm('Delete selected template?')) return
  const name = selTemplate.value.name
  await deleteDataTemplateAsync(userStore.token, name)
  await refreshKeysAsync()
}

async function templateAdded() {
  await refreshKeysAsync()
}

</script>

<style scoped>

.section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.api-page {
  display: flex;
  flex-direction: column;
}

.disabled-text {
  color: #999 !important;
}

</style>