<template>
  <div id="data-page" class="flex-page">
    <div v-if="!userStore.isLoggedIn">
      Please log-in
    </div>
    <template v-else>
      <data-dashboard class="data-list" :result-set-list="resultSetList" v-if="currentPage === Pages.dashboard" @new="new_Clicked" @open="open_Clicked" @duplicate="duplicate_Click" :visible="currentPage === Pages.dashboard"/>
      <result-set-viewer class="result-set-viewer" v-if="currentPage === Pages.viewer" :result-set="selectedResultSet" @close="close_Clicked"/>
      <result-set-builder v-if="currentPage === Pages.builder" @close="close_Clicked"/>
      <result-set-builder v-if="currentPage === Pages.duplicator" @close="close_Clicked" :duplicate-from="duplicateSource"/>
    </template>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from "vue";
import {useUserStore} from "@/stores/userStore";
import DataDashboard from "@/components/DataPage/DataDashboard";
import ResultSetViewer from "@/components/DataPage/ResultSetViewer";
import ResultSetBuilder from "@/components/DataPage/ResultSetBuilder";
import {getInfoAsync} from "@/api/resultSetApi";
import AddedAsset from "@/lib/entities/AddedAsset";
import {ResultSetList} from "@/lib/ResultSetList";
import logger from "@/sys/log";

const Pages = {
  dashboard: 'dash',
  builder: 'builder',
  viewer: 'viewer',
  duplicator: 'duplicator'
}

const userStore = useUserStore()
const resultSetList = ref(new ResultSetList())

const selectedResultSet = ref(null)
const duplicateSource = ref(null)

const currentPage = ref(Pages.dashboard)

function new_Clicked() {
  currentPage.value = Pages.builder
}

function open_Clicked(resultSet) {
  if (resultSet.status !== 'done') {
    alert('This result set is not ready to open.')
    return
  }
  selectedResultSet.value = resultSet
  currentPage.value = Pages.viewer
}

function close_Clicked() {
  currentPage.value = Pages.dashboard
}

async function duplicate_Click(item) {
  if (item == null)
    return
  duplicateSource.value = null
  try {
    const info = await getInfoAsync(userStore.token, item.refId)
    info.assets = info.assets.map(a => new AddedAsset(a))
    duplicateSource.value = info
    currentPage.value = Pages.duplicator
  }
  catch(error) {
    alert('Error duplicating data')
  }
}

onUnmounted(() => {
  logger.info('DataPage unmounted')
  resultSetList.value.close()
})

</script>

<style scoped>
#data-page {
  display: flex;
  justify-content: center;
}

.data-list {
  display: flex;
}

</style>