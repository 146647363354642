<template>
<div class="chart-view" @resize="onResize">
  <bar class="chart" :chart-data="chartData" :chart-options="{animations: false, maintainAspectRatio: false}" ref="chart"/>
</div>
</template>

<script setup>
import {computed, defineProps, defineExpose} from "vue";
import {Table} from "@/lib/entities/Results";
import {Bar} from 'vue-chartjs'
import {Chart, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import {TimeHorizon} from "@/lib/utils/TimeHorizon";

import { colors } from "@/lib/utils/colors";
import {ref, toRaw} from "vue";
import {elementCanvasToBlobAsync} from "@/lib/utils/canvasUtils";

const chart = ref()

Chart.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const props = defineProps({
  table: Table,
  timeHorizon: TimeHorizon
})

function onResize() {

}

const chartData = computed(() => {
  /** @type Table */ const t = props.table
  return {
    labels: t.columns,
    datasets: t.rows.filter(row => props.timeHorizon.contains(row.year)).map((row, index) => {
      return {
        label: row.year,
        data: row.values,
        backgroundColor: colors[index]
      }
    })
  }
})

async function getImageDataAsync() {
  return await elementCanvasToBlobAsync(chart.value)
}

defineExpose({getImageDataAsync})

</script>

<style scoped>

.chart-view {
  position: relative;
}

.chart {
  max-height: 600px;
  min-height: 100%;
}
</style>