<template>
  <div class="branding-page d-flex flex-column">
    <h5>Manage branding profiles</h5>

    <p>
      Manage branding profiles below. Branding profiles can be used to apply organization branding to reports and other
      tools in Climate Insights.
    </p>

    <div class="branding-profiles-list base-list-box flex-fill">
      <div class="list-box-scroller">
        <div v-for="(item, index) in items" :key="index" class="item" :class="{'selected': selectedId === item.brandingEntityId}" @click="selectedId = item.brandingEntityId">
          {{item.name }}
        </div>
      </div>
    </div>

    <div class="button-row">
      <div class="button-strip">
        <button class="btn btn-primary" @click="add_Click">Add</button>
        <button class="btn btn-primary" @click="edit_Click">Edit</button>
        <button class="btn btn-primary" @click="delete_Click">Delete</button>
      </div>
    </div>
  </div>

  <branding-editor-dialog v-if="showAddDialog" @save="doSave" @completed="save_Completed" @cancel="showAddDialog = false" />
  <branding-editor-dialog v-if="showEditDialog" @save="doUpdate" @completed="edit_Completed" @cancel="showEditDialog = false" :edit-source="editSource" />
</template>

<script setup>
import {ref, onMounted} from "vue";
import BrandingEditorDialog from "@/components/SettingsPage/dialogs/BrandingEditorDialog";
import {getBrandingProfilesAsync, deleteBrandingProfileAsync, updateBrandingDataAsync} from "@/api/brandingApi";
import {useUserStore} from "@/stores/userStore";
import { addBrandingDataAsync } from "@/api/brandingApi";

const userStore = useUserStore()

const selectedId = ref('')
const items = ref([])
const showAddDialog = ref(false)
const showEditDialog = ref(false)
const editSource = ref({})

async function doSave(data, editSource, onSuccess, onError) {
  try {
    await addBrandingDataAsync(userStore.token, data)
    onSuccess()
  }
  catch {
    onError()
  }
}

async function doUpdate(data, editSource, onSuccess, onError) {
  try {
    await updateBrandingDataAsync(userStore.token, editSource.brandingEntityId, data)
    onSuccess()
  }
  catch {
    onError()
  }
}

async function refreshItemsAsync() {
  items.value = await getBrandingProfilesAsync(userStore.token)
}

async function delete_Click() {
  const id = selectedId.value
  if (id == null || id === '') return
  if (!confirm('Delete selected branding profile?')) return

  try {
    await deleteBrandingProfileAsync(userStore.token, id)
  }
  catch(error) {
    alert(error.message)
  }

  await refreshItemsAsync()
}

function add_Click() {
  showAddDialog.value = true
}

async function edit_Click() {
  const id = selectedId.value
  if (id == null || id === '') return

  editSource.value = items.value.find(p => p.brandingEntityId === id)
  if (editSource.value == null) return
  showEditDialog.value = true
}

async function save_Completed() {
  showAddDialog.value = false
  await refreshItemsAsync()
}

async function edit_Completed() {
  showEditDialog.value = false
  await refreshItemsAsync()
}

onMounted(async () => {
  await refreshItemsAsync()
})

</script>

<style scoped>
.branding-page {
  flex: 1;
}

.branding-profiles-list {

}
</style>