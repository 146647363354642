export const SubscriptionScope = Object.freeze({
    User: 0,
    Group: 1
});

export const BillingType = Object.freeze({
    Prepay: 0,
    OnDemand: 1
})

export class Subscription {
    subscriptionId
    product
    terms
    currentTerm
    scope
    ownerId
    created
    isActive

    constructor(data = {}) {
        this.subscriptionId = data.subscriptionId || '';
        this.product = new Product(data.product || {});
        this.terms = Array.isArray(data.terms) ? data.terms.map(term => new SubscriptionTerm(term)) : [];
        this.currentTerm = this.terms.find(term => term.subTermId === data.currentTerm?.subTermId) || null;
        this.scope = data.scope;
        this.ownerId = data.ownerId || '';
        this.created = data.created ? new Date(data.created) : new Date();
        this.isActive = data.isActive || false;
    }

    toString() {
        return `${this.subscriptionId}, product=${this.product}, scope=${this.scope}, ownerId=${this.ownerId}`;
    }

    getAvailablePoints() {
        if (this.product.billingType === 'OnDemand' || !this.currentTerm) {
            return 0;
        }
        return this.product.basePoints + (this.currentTerm.topUps ? this.currentTerm.topUps.reduce((sum, t) => sum + t.numPoints, 0) : 0);
    }

    get scopeText() {
        switch(this.scope) {
            case SubscriptionScope.User: return 'User';
            case SubscriptionScope.Group: return 'Group';
        }
        return 'Unknown';
    }

    get isPrepay() {
        return this.product.billingType === BillingType.Prepay
    }

    get isOnDemand() {
        return this.product.billingType === BillingType.OnDemand
    }
}

export class UsageStats {
    subTermId
    dataPoints
    floodPoints

    constructor(data) {
        this.subTermId = data.subTermId || '';
        this.dataPoints = data.dataPoints || 0
        this.floodPoints = data.floodPoints || 0
    }

    get totalPointsUsed() {
        return this.dataPoints + this.floodPoints
    }
}

export class SubscriptionTerm {
    subTermId
    startDate
    endDate
    topUps
    isActive

    constructor(data = {}) {
        this.subTermId = data.subTermId || '';
        this.startDate = new Date(data.startDate);
        this.endDate = new Date(data.endDate);
        this.topUps = Array.isArray(data.topUps) ? data.topUps.map(topUp => new SubscriptionTopUp(topUp)) : [];
        this.isActive = data.isActive || false;
    }

    dateInRange(date) {
        const checkDate = new Date(date);
        return checkDate >= this.startDate && checkDate <= this.endDate;
    }

    toString() {
        return `${this.subTermId}, startDate=${this.startDate.toISOString().split('T')[0]}, endDate=${this.endDate.toISOString().split('T')[0]}, topUps=${this.topUps.length || "<null>"}`;
    }
}

export class Product {
    productId
    isCurrent
    name
    created
    updated
    billingType
    termLengthInMonths
    maxUsers
    pricePerTerm
    allowTopUps
    topUpPoints
    topUpPrice
    allowApi
    allowFlood
    variableSet
    variablePrice
    floodPrice
    basePoints

    constructor(data = {}) {
        this.productId = data.productId || `pr_${Product.generateGuid()}`;
        this.isCurrent = data.isCurrent || false;
        this.name = data.name || '';
        this.created = new Date(data.created);
        this.updated = new Date(data.updated);
        this.billingType = data.billingType;
        this.termLengthInMonths = data.termLengthInMonths || 0;
        this.maxUsers = data.maxUsers || 0;
        this.pricePerTerm = data.pricePerTerm || 0;
        this.allowTopUps = data.allowTopUps || false;
        this.topUpPoints = data.topUpPoints || 0;
        this.topUpPrice = data.topUpPrice || 0;
        this.allowApi = data.allowApi || false;
        this.allowFlood = data.allowFlood || false;
        this.variableSet = data.variableSet;
        this.variablePrice = data.variablePrice || 0;
        this.floodPrice = data.floodPrice || 0;
        this.basePoints = data.basePoints || 0;
    }

    static generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = Math.random() * 16 | 0;
            return c === 'x' ? r.toString(16) : (r & 0x3 | 0x8).toString(16);
        });
    }

    static none() {
        return new Product({productId: '00000000-0000-0000-0000-000000000000'});
    }

    toString() {
        return `${this.name} (${this.productId})`;
    }
}

export class SubscriptionTopUp {
    constructor(data = {}) {
        this.numPoints = data.numPoints || 0;
    }
}

export function formatSubscriptionError(error) {
    if (error == null || error.error == null) {
        return 'Unknown subscription error';
    }

    switch(error.error) {
        case 'no_active_subscription':
            return 'Your user account does not have an active subscription';
        case 'points_exceeded': {
            const count = error.count
            if (typeof count !== 'number')
                return 'Your subscription does not have enough available points to complete this request'
            return `This request would exceed the available points for your subscription by ${count}`
        }
    }
}