<template>
  <div id="extend-weather-page" class="extend-weather-page">
    <div v-if="!userStore.isLoggedIn">
      Please log-in
    </div>
    <template v-else-if="forecastList !== null">
      <div class="sidebar">
        <div class="sidebar-scroller scrollable no-select">
          <h6>Forecast browser</h6>
          <extend-weather-tree :forecast-list="forecastList" :session="session" @forecast-month-clicked="forecastMonth_Clicked" @historical-month-clicked="historicalMonth_Clicked"/>
        </div>
      </div>
      <div class="maps">
        <extend-weather-map class="map" v-for="(mapData, index) in mapDataItems" :key="index" @on-close="map_close_Clicked"
                            :map-id="mapData.mapId"
                            :imageType="mapData.imageType"
                            :monthName="mapData.monthName"
                            :varName="mapData.varName"
                            :varUnits="mapData.varUnits"
                            :imageUrl="mapData.imageUrl"
                            :imageBounds="mapData.imageBounds"
                            :forecast="mapData.forecast"
                            :varItem="mapData.varItem"
                            :fid="mapData.fid"
                            :session="mapData.session" />
      </div>
    </template>
    <div v-else class="loading-box">
      <loading-indicator/>
    </div>
  </div>
</template>

<script>
import service from "@/lib/extendWeather/ExtendWeatherApi"
import ExtendWeatherTree from "@/components/ExtendWeatherPage/ExtendWeatherTree";
import ExtendWeatherMap from "@/components/ExtendWeatherPage/ExtendWeatherMap";
import { MONTHS } from "@/lib/extendWeather/EWData";
import {useUserStore} from "@/stores/userStore";
import LoadingIndicator from "@/components/LoadingIndicator.vue";

let mapIdCounter = 0

export default {
  name: "ExtendWeatherPage",
  components: {LoadingIndicator, ExtendWeatherTree, ExtendWeatherMap },
  data() {
    return {
      session: null,
      initCount: 0,
      forecastList: null,
      errorMessage: null,
      isLoading: false,
      mapDataItems: [],
      userStore: useUserStore()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.initCount++ !== 0) return

      this.isLoading = true

      service.createSession(this.userStore.token,
          (session) => {
            //logger.log('ExtendWeather session created: ' + session)
            this.session = session
            this.initForecastInventory()
          },
          (error) => {
            //logger.log('ERROR: Failed to create ExtendWeather session')
            //logger.log(error)
            this.errorMessage = error.toString()
            this.isLoading = false
          }
      )
    },
    initForecastInventory() {
      service.getForecastInventory(this.userStore.token, this.session,
          (forecastInventory) => {
            //logger.log('ExtendWeather inventory loaded: ')
            //logger.log(forecastInventory)
            this.forecastList = forecastInventory
            this.isLoading = false
          },
          (error) => {
            //logger.log('ERROR: Failed to load Forecast Inventory')
            //logger.log(error)
            this.errorMessage = error.toString()
            this.isLoading = false
          }
      )
    },
    resetData() {
      this.forecastList = null
      this.errorMessage = null
      this.isLoading = false
      this.mapDataItems = []
      this.initCount = 0
    },
    forecastMonth_Clicked(forecast, varItem, month, index) {
      let mapData = {
        mapId: mapIdCounter++,
        imageType: 'Forecast',
        monthName: MONTHS[month],
        varName: varItem.name,
        varUnits: varItem.units,
        imageUrl: varItem.urls[index],
        imageBounds: varItem.layerBounds,
        session: this.session,
        fid: forecast.forecastID.toString(),
        forecast,
        varItem,
      }
      //logger.log('Adding forecast map data', mapData)
      this.mapDataItems.push(mapData)
    },
    historicalMonth_Clicked(forecast, varItem, month, index) {
      let mapData = {
        mapId: mapIdCounter++,
        imageType: 'Historical',
        monthName: MONTHS[month],
        varName: varItem.name,
        varUnits: varItem.units,
        imageUrl: varItem.urls[index],
        imageBounds: varItem.layerBounds,
        session: this.session,
        fid: 'historical',
        forecast,
        varItem,
      }
      //logger.log('Adding historical map data', mapData)
      this.mapDataItems.push(mapData)
    },
    map_close_Clicked(mapId) {
      this.mapDataItems = this.mapDataItems.filter(md => md.mapId !== mapId)
    }
  },
  computed: {
    formatErrorMessage() {
      return '' // todo
    }
  }
}
</script>

<style scoped>

#extend-weather-page {
  background-color: rgba(255, 255, 255, 0.8);
}

.extend-weather-page {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.loading-box {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar {
  position: relative;
  width: 20%;
  min-width: 200px;
  max-width: 250px;
  color: #282d3b;
}

.sidebar .sidebar-scroller {
  padding: 0.5rem;
}

.maps {
  display: flex;
  flex: 1;
}

.maps .map {
  flex: 1;
}
</style>